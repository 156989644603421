import { memo } from 'react';
import classes from './styles.module.scss';
import { NULL_COLOR } from 'configs/constant';

interface StatusProps {
  status: string;
  color: string;
  [key: string]: any;
}

export const Status: React.FC<StatusProps> = memo((props: StatusProps) => {
  const { status, color, ...rest } = props;

  return (
    <div className={classes.container} style={{ borderColor: `#${color ?? NULL_COLOR}` }} {...rest}>
      {status || 'Not started'}
    </div>
  );
});

export default Status;
