import { useLocation } from 'react-router-dom';
import { pathToRegexp, Key } from 'path-to-regexp';

const useRouteMatch = (route: string) => {
  const keys: Key[] = [];

  const { pathname } = useLocation();

  const pattern = pathToRegexp(route, keys);
  const match = pattern?.exec(pathname);

  if (!match) {
    return { params: {}, match: null };
  }

  const params: { [key: string]: string } | any = keys?.reduce((acc, key, index) => {
    acc[key?.name] = match?.[index + 1];
    return acc;
  }, {});

  return { params, match, isExact: !!match };
};

export default useRouteMatch;
