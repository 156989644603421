import { memo, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ICreateEditWorkspaceFormData, IWorkspace } from 'interfaces/workspace';
import SecondaryButton from 'components/Buttons/SecondaryButton';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import Input from 'components/Input';
import classes from './styles.module.scss';
import Regexes from 'configs/regexes';

interface ModalCreateEditWorkspaceProps {
  isOpen: boolean;
  data: IWorkspace;
  onClose: () => void;
  onSubmit: (data: ICreateEditWorkspaceFormData) => void;
}

const ModalCreateEditWorkspace = memo((props: ModalCreateEditWorkspaceProps) => {
  const { isOpen, data, onClose, onSubmit } = props;

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      workspaceName: Yup.string()
        .required('This field is required.')
        .matches(Regexes.workspaceName, 'Only alphanumeric characters, periods, underscores, dashes, and spaces are allowed.'),
    });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<ICreateEditWorkspaceFormData>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    if (!isOpen) {
      reset();
    } else {
      reset({ workspaceName: data?.name });
    }
  }, [isOpen]);

  return (
    <Dialog transitionDuration={0.3} open={isOpen} onClose={onClose} className={classes.container}>
      <DialogTitle>{data ? 'Edit' : 'Create'} workspace</DialogTitle>
      <DialogContent>
        <form id="workspace" onSubmit={handleSubmit(onSubmit)}>
          <Input
            label="Name"
            placeholder="Workspace name"
            inputRef={register('workspaceName')}
            autoComplete="off"
            autoFocus={true}
            errorMessage={errors?.workspaceName?.message}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
        <PrimaryButton type="submit" form="workspace">
          Submit
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
});

export default ModalCreateEditWorkspace;
