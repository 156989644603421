import { memo } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import SecondaryButton from 'components/Buttons/SecondaryButton';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import classes from './styles.module.scss';

interface ModalConfirmRevertPipelineProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  mainButtonText?: string;
  subButtonText?: string;
}

const ModalConfirmRevertPipeline = memo((props: ModalConfirmRevertPipelineProps) => {
  const { isOpen, onClose, onSubmit, mainButtonText, subButtonText } = props;

  return (
    <Dialog transitionDuration={0.3} open={isOpen} onClose={onClose} className={classes.container}>
      <DialogTitle>Revert pipeline</DialogTitle>
      <DialogContent>Are you sure you want to revert to this version?</DialogContent>
      <DialogActions>
        <SecondaryButton onClick={onClose}>{subButtonText ?? 'Cancel'}</SecondaryButton>
        <PrimaryButton onClick={onSubmit}>{mainButtonText ?? 'Submit'}</PrimaryButton>
      </DialogActions>
    </Dialog>
  );
});

export default ModalConfirmRevertPipeline;
