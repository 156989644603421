import { ETaskColumns } from 'configs/enums';

const getRecentProjectId = () => {
  return localStorage.getItem('recent_project_id');
};

const setRecentProjectId = (projectId: string) => {
  localStorage.setItem('recent_project_id', projectId);
};

const getTaskColumns = () => {
  return JSON.parse(localStorage.getItem('task_columns'));
};

const setTaskColumns = (columns: ETaskColumns[]) => {
  localStorage.setItem('task_columns', JSON.stringify(columns));
};

const ProjectService = {
  getRecentProjectId,
  setRecentProjectId,
  getTaskColumns,
  setTaskColumns,
};

export default ProjectService;
