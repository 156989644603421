import { Fragment, memo, useEffect, useMemo, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import SecondaryButton from 'components/Buttons/SecondaryButton';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import * as Yup from 'yup';
import classes from './styles.module.scss';
import { useForm } from 'react-hook-form';
import { IDeleteFormData } from 'interfaces/common';
import { yupResolver } from '@hookform/resolvers/yup';
import Input from 'components/Input';
import clsx from 'clsx';
import { IReducer } from 'redux/reducers';
import { useSelector } from 'react-redux';
import { IPipeline } from 'interfaces/pipeline';

interface ModalConfirmPublishRevertPipelineProps {
  isOpen: boolean;
  publishPipelineId: string;
  onClose: () => void;
  onSubmit: () => void;
  mainButtonText?: string;
  subButtonText?: string;
}

const ModalConfirmPublishRevertPipeline = memo((props: ModalConfirmPublishRevertPipelineProps) => {
  const { isOpen, publishPipelineId, onClose, onSubmit, mainButtonText, subButtonText } = props;

  const { pipelineList } = useSelector((state: IReducer) => state?.information);

  const [pipelineName, setPipelineName] = useState<string>('');

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      title: Yup.string().required('This field is required.').oneOf([pipelineName], 'Project name is not correct.'),
    });
  }, [pipelineName]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IDeleteFormData>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    if (publishPipelineId && pipelineList?.data?.length) {
      setPipelineName(pipelineList?.data?.find((pipeline: IPipeline) => pipeline?._id === publishPipelineId)?.name);
    }
  }, [publishPipelineId, pipelineList]);

  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen]);

  return (
    <Dialog transitionDuration={0.3} open={isOpen} onClose={onClose} className={classes.container}>
      <DialogTitle>{publishPipelineId ? 'Publish pipeline' : 'Revert pipeline'}</DialogTitle>
      <DialogContent className={clsx({ 'pb-0': publishPipelineId })}>
        {publishPipelineId ? (
          <Fragment>
            <p className={classes.description}>
              To confirm, please type <b>{pipelineName}</b> in the box below.
            </p>
            <form id="pipeline" onSubmit={handleSubmit(onSubmit)}>
              <Input placeholder="Pipeline name" inputRef={register('title')} autoComplete="off" autoFocus={true} errorMessage={errors?.title?.message} />
            </form>
          </Fragment>
        ) : (
          'Are you sure you want to revert to this version?'
        )}
      </DialogContent>
      <DialogActions>
        <SecondaryButton onClick={onClose}>{subButtonText ?? 'Cancel'}</SecondaryButton>
        {publishPipelineId ? (
          <PrimaryButton type="submit" form="pipeline">
            {mainButtonText ?? 'Submit'}
          </PrimaryButton>
        ) : (
          <PrimaryButton onClick={onSubmit}>{mainButtonText ?? 'Submit'}</PrimaryButton>
        )}
      </DialogActions>
    </Dialog>
  );
});

export default ModalConfirmPublishRevertPipeline;
