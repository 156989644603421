import { Fragment, memo } from 'react';
import { Controller } from 'react-hook-form';
import ReactSelect, { CSSObjectWithLabel, GroupBase, StylesConfig } from 'react-select';
import classes from './styles.module.scss';
import { StateManagerProps } from 'react-select/dist/declarations/src/stateManager';
import { ESelectTheme } from 'configs/enums';
import clsx from 'clsx';

const gradientStyles = (): StylesConfig<any, boolean, GroupBase<unknown>> => ({
  indicatorSeparator: () =>
    ({
      display: 'none',
    } as CSSObjectWithLabel),
  indicatorsContainer: (provided) =>
    ({
      ...provided,
      '> div': { color: 'var(--white) !important' },
    } as CSSObjectWithLabel),
  container: (provided) =>
    ({
      ...provided,
      margin: 0,
    } as CSSObjectWithLabel),
  control: (provided, state) =>
    ({
      ...provided,
      cursor: 'pointer',
      width: 206,
      border: 'none',
      boxShadow: 'none',
      background: 'var(--gradient)',
      filter: state?.menuIsOpen ? 'brightness(1.15)' : 'none',
      transition: 'all 0.25s',
      '&:hover': {
        filter: 'brightness(1.15)',
        transition: 'all 0.25s',
      },
    } as CSSObjectWithLabel),
  valueContainer: (provided) =>
    ({
      ...provided,
      paddingLeft: 16,
    } as CSSObjectWithLabel),
  placeholder: (provided) =>
    ({
      ...provided,
      color: 'var(--white)',
      fontSize: 16,
      fontWeight: 600,
      margin: 0,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    } as CSSObjectWithLabel),
  singleValue: (provided) =>
    ({
      ...provided,
      color: 'var(--white)',
      fontSize: 16,
      fontWeight: 600,
      margin: 0,
    } as CSSObjectWithLabel),
  menuPortal: (provided) =>
    ({
      ...provided,
      zIndex: 9999,
    } as CSSObjectWithLabel),
  menuList: (provided) =>
    ({
      ...provided,
      padding: 0,
    } as CSSObjectWithLabel),
  menu: (provided) =>
    ({
      ...provided,
      background: 'var(--backgroundDark)',
      boxShadow: 'var(--dropdownBoxShadow)',
      padding: '16px 8px',
      margin: 0,
      width: 250,
    } as CSSObjectWithLabel),
  option: (provided, state) =>
    ({
      ...provided,
      cursor: 'pointer',
      color: state?.isSelected ? 'var(--primary)' : 'var(--white)',
      background: 'var(--backgroundDark)',
      wordBreak: 'break-all',
      fontSize: 16,
      fontWeight: 600,
      borderRadius: 4,
      transition: 'all 0.2s',
      '&:hover': {
        background: 'var(--rowBackground)',
        transition: 'all 0.2s',
      },
    } as CSSObjectWithLabel),
  noOptionsMessage: (provided) =>
    ({
      ...provided,
      fontSize: 14,
    } as CSSObjectWithLabel),
});

const roundedGradientStyles = (): StylesConfig<any, boolean, GroupBase<unknown>> => ({
  indicatorSeparator: () =>
    ({
      display: 'none',
    } as CSSObjectWithLabel),
  indicatorsContainer: (provided) =>
    ({
      ...provided,
      '> div': { color: 'var(--white) !important' },
    } as CSSObjectWithLabel),
  container: (provided) =>
    ({
      ...provided,
      margin: 0,
    } as CSSObjectWithLabel),
  control: (provided, state) =>
    ({
      ...provided,
      cursor: 'pointer',
      border: 'none',
      boxShadow: 'none',
      background: 'var(--gradient)',
      filter: state?.menuIsOpen ? 'brightness(1.15)' : 'none',
      borderRadius: '16px',
      transition: 'all 0.25s',
      '&:hover': {
        filter: 'brightness(1.15)',
        transition: 'all 0.25s',
      },
    } as CSSObjectWithLabel),
  valueContainer: (provided) =>
    ({
      ...provided,
      paddingLeft: 16,
      paddingRight: 0,
    } as CSSObjectWithLabel),
  placeholder: (provided) =>
    ({
      ...provided,
      color: 'var(--white)',
      fontSize: 12,
      fontWeight: 600,
      margin: 0,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    } as CSSObjectWithLabel),
  singleValue: (provided) =>
    ({
      ...provided,
      color: 'var(--white)',
      fontSize: 12,
      fontWeight: 600,
      margin: 0,
    } as CSSObjectWithLabel),
  menuPortal: (provided) =>
    ({
      ...provided,
      zIndex: 9999,
    } as CSSObjectWithLabel),
  menuList: (provided) =>
    ({
      ...provided,
      padding: 0,
    } as CSSObjectWithLabel),
  menu: (provided) =>
    ({
      ...provided,
      background: 'var(--backgroundDark)',
      boxShadow: 'var(--dropdownBoxShadow)',
      padding: '16px 8px',
      margin: 0,
      width: 250,
      right: 0,
    } as CSSObjectWithLabel),
  option: (provided, state) =>
    ({
      ...provided,
      cursor: 'pointer',
      color: state?.isSelected ? 'var(--primary)' : 'var(--white)',
      background: 'var(--backgroundDark)',
      wordBreak: 'break-all',
      fontSize: 16,
      fontWeight: 600,
      borderRadius: 4,
      transition: 'all 0.2s',
      '&:hover': {
        background: 'var(--rowBackground)',
        transition: 'all 0.2s',
      },
    } as CSSObjectWithLabel),
  noOptionsMessage: (provided) =>
    ({
      ...provided,
      fontSize: 14,
    } as CSSObjectWithLabel),
});

const headerStyles = (): StylesConfig<any, boolean, GroupBase<unknown>> => ({
  indicatorSeparator: () =>
    ({
      display: 'none',
    } as CSSObjectWithLabel),
  indicatorsContainer: (provided) =>
    ({
      ...provided,
      '> div': { color: 'var(--white) !important' },
    } as CSSObjectWithLabel),
  container: (provided) =>
    ({
      ...provided,
      margin: 0,
    } as CSSObjectWithLabel),
  control: (provided) =>
    ({
      ...provided,
      cursor: 'pointer',
      width: 'fit-content',
      maxWidth: 350,
      minWidth: 'max-content',
      border: 'none',
      boxShadow: 'none',
      background: 'transparent',
    } as CSSObjectWithLabel),
  valueContainer: (provided) =>
    ({
      ...provided,
      paddingLeft: 0,
    } as CSSObjectWithLabel),
  placeholder: (provided) =>
    ({
      ...provided,
      color: 'var(--white)',
      fontSize: 24,
      fontWeight: 700,
      margin: 0,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    } as CSSObjectWithLabel),
  singleValue: (provided) =>
    ({
      ...provided,
      color: 'var(--white)',
      fontSize: 24,
      fontWeight: 700,
      margin: 0,
    } as CSSObjectWithLabel),
  menuPortal: (provided) =>
    ({
      ...provided,
      zIndex: 9999,
    } as CSSObjectWithLabel),
  menuList: (provided) =>
    ({
      ...provided,
      padding: 0,
    } as CSSObjectWithLabel),
  menu: (provided) =>
    ({
      ...provided,
      background: 'var(--backgroundDark)',
      boxShadow: 'var(--dropdownBoxShadow)',
      padding: '16px 8px',
      margin: 0,
      width: 250,
    } as CSSObjectWithLabel),
  option: (provided, state) =>
    ({
      ...provided,
      cursor: 'pointer',
      color: state?.isSelected ? 'var(--primary)' : 'var(--white)',
      background: 'var(--backgroundDark)',
      borderRadius: 4,
      wordBreak: 'break-all',
      fontSize: 16,
      fontWeight: 600,
      transition: 'all 0.2s',
      '&:hover': {
        background: 'var(--rowBackground)',
        transition: 'all 0.2s',
      },
    } as CSSObjectWithLabel),
  noOptionsMessage: (provided) =>
    ({
      ...provided,
      fontSize: 14,
    } as CSSObjectWithLabel),
});

const formStyles = (): StylesConfig<any, boolean, GroupBase<unknown>> => ({
  indicatorSeparator: () =>
    ({
      display: 'none',
    } as CSSObjectWithLabel),
  indicatorsContainer: (provided) =>
    ({
      ...provided,
      '> div': { color: 'var(--gray) !important' },
    } as CSSObjectWithLabel),
  container: (provided) =>
    ({
      ...provided,
      margin: 0,
    } as CSSObjectWithLabel),
  control: (provided) =>
    ({
      ...provided,
      cursor: 'pointer',
      width: '100%',
      borderRadius: 8,
      border: '1px solid var(--primary)',
      boxShadow: 'none',
      background: 'transparent',
      '&:hover': {
        border: '1px solid var(--primary)',
      },
    } as CSSObjectWithLabel),
  valueContainer: (provided) =>
    ({
      ...provided,
      paddingLeft: 16,
    } as CSSObjectWithLabel),
  placeholder: (provided) =>
    ({
      ...provided,
      color: 'var(--gray)',
      fontSize: 12,
      fontWeight: 300,
      margin: 0,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    } as CSSObjectWithLabel),
  singleValue: (provided) =>
    ({
      ...provided,
      color: 'var(--white)',
      fontSize: 12,
      fontWeight: 300,
      margin: 0,
    } as CSSObjectWithLabel),
  menuPortal: (provided) =>
    ({
      ...provided,
      zIndex: 9999,
    } as CSSObjectWithLabel),
  menuList: (provided) =>
    ({
      ...provided,
      padding: 0,
    } as CSSObjectWithLabel),
  menu: (provided) =>
    ({
      ...provided,
      background: 'var(--backgroundDark)',
      boxShadow: 'var(--dropdownBoxShadow)',
      padding: '16px 8px',
      margin: 0,
    } as CSSObjectWithLabel),
  option: (provided, state) =>
    ({
      ...provided,
      cursor: 'pointer',
      color: state?.isSelected ? 'var(--primary)' : 'var(--white)',
      background: 'var(--backgroundDark)',
      borderRadius: 4,
      wordBreak: 'break-all',
      fontSize: 12,
      fontWeight: 300,
      transition: 'all 0.2s',
      '&:hover': {
        background: 'var(--rowBackground)',
        transition: 'all 0.2s',
      },
    } as CSSObjectWithLabel),
  noOptionsMessage: (provided) =>
    ({
      ...provided,
      fontSize: 14,
    } as CSSObjectWithLabel),
});

const filterStyles = (): StylesConfig<any, boolean, GroupBase<unknown>> => ({
  indicatorSeparator: () =>
    ({
      display: 'none',
    } as CSSObjectWithLabel),
  indicatorsContainer: (provided) =>
    ({
      ...provided,
      '> div': { color: 'var(--gray) !important', padding: '8px 24px 8px 4px' },
    } as CSSObjectWithLabel),
  container: (provided) =>
    ({
      ...provided,
      margin: 0,
    } as CSSObjectWithLabel),
  control: (provided) =>
    ({
      ...provided,
      paddingLeft: 24,
      cursor: 'pointer',
      width: 156,
      // width: 'fit-content',
      // minWidth: 150,
      // maxWidth: 200,
      border: 'none',
      borderRadius: 16,
      boxShadow: 'none',
      background: 'var(--backgroundLight)',
    } as CSSObjectWithLabel),
  valueContainer: (provided) =>
    ({
      ...provided,
      paddingLeft: 0,
    } as CSSObjectWithLabel),
  placeholder: (provided) =>
    ({
      ...provided,
      color: 'var(--gray)',
      fontSize: 12,
      fontWeight: 600,
      margin: 0,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    } as CSSObjectWithLabel),
  singleValue: (provided) =>
    ({
      ...provided,
      color: 'var(--gray)',
      fontSize: 12,
      fontWeight: 600,
      margin: 0,
    } as CSSObjectWithLabel),
  menuPortal: (provided) =>
    ({
      ...provided,
      zIndex: 9999,
    } as CSSObjectWithLabel),
  menuList: (provided) =>
    ({
      ...provided,
      padding: 0,
    } as CSSObjectWithLabel),
  menu: (provided) =>
    ({
      ...provided,
      background: 'var(--backgroundDark)',
      boxShadow: 'var(--dropdownBoxShadow)',
      padding: '16px 8px',
      margin: 0,
      width: 250,
    } as CSSObjectWithLabel),
  option: (provided, state) =>
    ({
      ...provided,
      cursor: 'pointer',
      color: state?.isSelected ? 'var(--primary)' : 'var(--white)',
      background: 'var(--backgroundDark)',
      borderRadius: 4,
      wordBreak: 'break-all',
      fontSize: 12,
      fontWeight: 600,
      transition: 'all 0.2s',
      '&:hover': {
        background: 'var(--rowBackground)',
        transition: 'all 0.2s',
      },
    } as CSSObjectWithLabel),
  noOptionsMessage: (provided) =>
    ({
      ...provided,
      fontSize: 14,
    } as CSSObjectWithLabel),
});

const transparentStyles = (): StylesConfig<any, boolean, GroupBase<unknown>> => ({
  indicatorSeparator: () =>
    ({
      display: 'none',
    } as CSSObjectWithLabel),
  indicatorsContainer: (provided) =>
    ({
      ...provided,
      '> div': { color: 'var(--white) !important', padding: '8px 24px 8px 4px' },
    } as CSSObjectWithLabel),
  container: (provided) =>
    ({
      ...provided,
      margin: 0,
    } as CSSObjectWithLabel),
  control: (provided) =>
    ({
      ...provided,
      cursor: 'pointer',
      width: '100%',
      minHeight: 'unset',
      border: 'none',
      borderRadius: 16,
      boxShadow: 'none',
      background: 'transparent',
    } as CSSObjectWithLabel),
  valueContainer: (provided) =>
    ({
      ...provided,
      padding: 0,
    } as CSSObjectWithLabel),
  placeholder: (provided) =>
    ({
      ...provided,
      color: 'var(--white)',
      fontSize: 16,
      fontWeight: 500,
      margin: 0,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    } as CSSObjectWithLabel),
  singleValue: (provided) =>
    ({
      ...provided,
      color: 'var(--white)',
      fontSize: 16,
      fontWeight: 500,
      margin: 0,
    } as CSSObjectWithLabel),
  menuPortal: (provided) =>
    ({
      ...provided,
      zIndex: 9999,
    } as CSSObjectWithLabel),
  menuList: (provided) =>
    ({
      ...provided,
      padding: 0,
    } as CSSObjectWithLabel),
  menu: (provided) =>
    ({
      ...provided,
      background: 'var(--backgroundDark)',
      boxShadow: 'var(--dropdownBoxShadow)',
      padding: '16px 8px',
      margin: 0,
      width: 250,
    } as CSSObjectWithLabel),
  option: (provided, state) =>
    ({
      ...provided,
      cursor: 'pointer',
      color: state?.isSelected ? 'var(--primary)' : 'var(--white)',
      background: 'var(--backgroundDark)',
      borderRadius: 4,
      wordBreak: 'break-all',
      fontSize: 16,
      fontWeight: 500,
      transition: 'all 0.2s',
      '&:hover': {
        background: 'var(--rowBackground)',
        transition: 'all 0.2s',
      },
    } as CSSObjectWithLabel),
  noOptionsMessage: (provided) =>
    ({
      ...provided,
      fontSize: 14,
    } as CSSObjectWithLabel),
});

const roleStyles = (): StylesConfig<any, boolean, GroupBase<unknown>> => ({
  indicatorSeparator: () =>
    ({
      display: 'none',
    } as CSSObjectWithLabel),
  indicatorsContainer: (provided) =>
    ({
      ...provided,
      '> div': { color: 'var(--black) !important', padding: '4px' },
    } as CSSObjectWithLabel),
  container: (provided) =>
    ({
      ...provided,
      margin: 0,
    } as CSSObjectWithLabel),
  control: (provided) =>
    ({
      ...provided,
      cursor: 'pointer',
      width: '100%',
      minHeight: 'unset',
      border: 'none',
      borderRadius: 12,
      boxShadow: 'none',
      background: 'var(--grey)',
    } as CSSObjectWithLabel),
  valueContainer: (provided) =>
    ({
      ...provided,
    } as CSSObjectWithLabel),
  placeholder: (provided) =>
    ({
      ...provided,
      color: 'var(--black)',
      fontSize: 12,
      fontWeight: 500,
      margin: 0,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    } as CSSObjectWithLabel),
  singleValue: (provided) =>
    ({
      ...provided,
      color: 'var(--black)',
      fontSize: 12,
      fontWeight: 500,
      margin: 0,
    } as CSSObjectWithLabel),
  menuPortal: (provided) =>
    ({
      ...provided,
      zIndex: 9999,
    } as CSSObjectWithLabel),
  menuList: (provided) =>
    ({
      ...provided,
      padding: 0,
    } as CSSObjectWithLabel),
  menu: (provided) =>
    ({
      ...provided,
      background: 'var(--backgroundDark)',
      boxShadow: 'var(--dropdownBoxShadow)',
      padding: '16px 8px',
      margin: 0,
      width: 250,
    } as CSSObjectWithLabel),
  option: (provided, state) =>
    ({
      ...provided,
      cursor: 'pointer',
      color: state?.isSelected ? 'var(--primary)' : 'var(--white)',
      background: 'var(--backgroundDark)',
      borderRadius: 4,
      wordBreak: 'break-all',
      fontSize: 12,
      fontWeight: 500,
      transition: 'all 0.2s',
      '&:hover': {
        background: 'var(--rowBackground)',
        transition: 'all 0.2s',
      },
    } as CSSObjectWithLabel),
  noOptionsMessage: (provided) =>
    ({
      ...provided,
      fontSize: 14,
    } as CSSObjectWithLabel),
});

interface SelectProps extends StateManagerProps {
  className?: string;
  label?: string;
  errorMessage?: string;
  name?: string;
  control?: any;
  bindValue?: string;
  bindLabel?: string;
  customTheme?: ESelectTheme;
  [key: string]: any;
}

const Select = memo((props: SelectProps) => {
  const { className, label, errorMessage, name, control, bindValue, bindLabel, customTheme, ...rest } = props;

  const getTheme = () => {
    switch (customTheme) {
      case ESelectTheme.Form:
        return formStyles();
      case ESelectTheme.Gradient:
        return gradientStyles();
      case ESelectTheme.Header:
        return headerStyles();
      case ESelectTheme.Filter:
        return filterStyles();
      case ESelectTheme.Transparent:
        return transparentStyles();
      case ESelectTheme.Role:
        return roleStyles();
      case ESelectTheme.RoundedGradient:
        return roundedGradientStyles();
      default:
        return control ? formStyles() : transparentStyles();
    }
  };

  return (
    <Fragment>
      {control ? (
        <div className={clsx(classes.container, className)}>
          {label ? <label>{label}</label> : null}
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <ReactSelect
                {...field}
                isSearchable={false}
                styles={getTheme()}
                menuPortalTarget={document.querySelector('body')}
                getOptionValue={(option: any) => option[bindValue || 'value']}
                getOptionLabel={(option: any) => option[bindLabel || 'label']}
                noOptionsMessage={() => 'No results found'}
                {...rest}
              />
            )}
          />
          {errorMessage ? <p>{errorMessage}</p> : null}
        </div>
      ) : customTheme === ESelectTheme.Form ? (
        <div className={clsx(classes.container, className)}>
          {label ? <label>{label}</label> : null}
          <ReactSelect
            isSearchable={false}
            styles={getTheme()}
            menuPortalTarget={document.querySelector('body')}
            getOptionValue={(option: any) => option?.[bindValue || 'value']}
            getOptionLabel={(option: any) => option?.[bindLabel || 'label']}
            noOptionsMessage={() => 'No results found'}
            {...rest}
          />
          {errorMessage ? <p>{errorMessage}</p> : null}
        </div>
      ) : (
        <ReactSelect
          className={className}
          isSearchable={false}
          styles={getTheme()}
          menuPortalTarget={document.querySelector('body')}
          getOptionValue={(option: any) => option?.[bindValue || 'value']}
          getOptionLabel={(option: any) => option?.[bindLabel || 'label']}
          noOptionsMessage={() => 'No results found'}
          {...rest}
        />
      )}
    </Fragment>
  );
});

export default Select;
