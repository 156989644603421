import { memo, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import SecondaryButton from 'components/Buttons/SecondaryButton';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import classes from './styles.module.scss';
import { IResourceConfiguration, IUploadMediaFormData } from 'interfaces/pipeline';
import Select from 'components/Select';
import { ESelectTheme } from 'configs/enums';
import { NULL_OPTION } from 'configs/constant';
import { ISelectOption } from 'interfaces/common';
import { IReducer } from 'redux/reducers';
import { useSelector } from 'react-redux';

interface ModalUploadMediaProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: IUploadMediaFormData) => void;
}

const ModalUploadMedia = memo((props: ModalUploadMediaProps) => {
  const { isOpen, onClose, onSubmit } = props;

  const { resourceList } = useSelector((state: IReducer) => state?.information);

  const [resourceConfigurationOptions, setResourceConfigurationOptions] = useState<ISelectOption[]>([]);

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      selectedResourceConfiguration: Yup.object(),
    });
  }, []);

  const { handleSubmit, control } = useForm<IUploadMediaFormData>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    if (resourceList?.resourceConfigurations?.length) {
      setResourceConfigurationOptions([
        NULL_OPTION,
        /* eslint-disable-next-line no-unsafe-optional-chaining */
        ...resourceList?.resourceConfigurations?.map((item: IResourceConfiguration) => {
          return {
            value: item?._id,
            label: item?.name,
          };
        }),
      ]);
    }
  }, [resourceList]);

  return (
    <Dialog id="modal-select-stage" transitionDuration={0.3} open={isOpen} onClose={onClose} className={classes.container}>
      <DialogTitle>Assign media to stage</DialogTitle>

      <DialogContent>
        <form id="stage" onSubmit={handleSubmit(onSubmit)}>
          <Select
            name="selectedResourceConfiguration"
            customTheme={ESelectTheme.Form}
            label="Stage"
            defaultValue={NULL_OPTION}
            options={resourceConfigurationOptions}
            control={control}
            className="mb-0"
          />
        </form>
      </DialogContent>

      <DialogActions>
        <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
        <PrimaryButton type="submit" form="stage">
          Submit
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
});

export default ModalUploadMedia;
