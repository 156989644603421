import { memo } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import classes from './styles.module.scss';
import { NodeIcon } from 'assets';

interface CustomNodeProps extends NodeProps {}

const CustomNode: React.FC<CustomNodeProps> = memo((props: CustomNodeProps) => {
  const { data, isConnectable } = props;

  return (
    <div className={classes.container}>
      <Handle type="target" position={Position.Left} isConnectable={isConnectable} />
      <NodeIcon />
      <p>{data?.label ?? 'N/A'}</p>
      <Handle type="source" position={Position.Right} isConnectable={isConnectable} />
    </div>
  );
});

export default CustomNode;
