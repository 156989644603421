import { IWorkspace } from 'interfaces/workspace';
import { IPipeline, IResourceTableState, ISortTaskTableState } from 'interfaces/pipeline';
import { IProject } from 'interfaces/project';

export const SET_WORKSPACE_REDUCER = 'SET_WORKSPACE_REDUCER';

export const SET_PROJECT_REDUCER = 'SET_PROJECT_REDUCER';

export const SET_IS_ARCHIVED_REDUCER = 'SET_IS_ARCHIVED_REDUCER';

export const SET_PIPELINE_REDUCER = 'SET_PIPELINE_REDUCER';

export const SET_ALL_PROJECT_LIST_REDUCER = 'SET_ALL_PROJECT_LIST_REDUCER';

export const SET_RESOURCE_TABLE_REDUCER = 'SET_RESOURCE_TABLE_REDUCER';

export const SET_SORT_TASK_TABLE_REDUCER = 'SET_SORT_TASK_TABLE_REDUCER';

export const SET_LOGOUT_WORKSPACE_REDUCER = 'SET_LOGOUT_WORKSPACE_REDUCER';

export const setWorkspaceReducer = (data: IWorkspace) => {
  return {
    type: SET_WORKSPACE_REDUCER,
    data,
  };
};

export const setProjectReducer = (data: IProject) => {
  return {
    type: SET_PROJECT_REDUCER,
    data,
  };
};

export const setIsArchivedReducer = (isArchived: boolean) => {
  return {
    type: SET_IS_ARCHIVED_REDUCER,
    data: isArchived,
  };
};

export const setPipelineReducer = (pipeline: IPipeline) => {
  return {
    type: SET_PIPELINE_REDUCER,
    data: pipeline,
  };
};

export const setAllProjectListReducer = (allProjectList: IProject[]) => {
  return {
    type: SET_ALL_PROJECT_LIST_REDUCER,
    data: allProjectList,
  };
};

export const setResourceTableReducer = (data: IResourceTableState) => {
  return {
    type: SET_RESOURCE_TABLE_REDUCER,
    data,
  };
};

export const setSortTaskTableReducer = (data: ISortTaskTableState) => {
  return {
    type: SET_SORT_TASK_TABLE_REDUCER,
    data,
  };
};

export const setLogoutWorkspaceReducer = () => {
  return {
    type: SET_LOGOUT_WORKSPACE_REDUCER,
  };
};
