import produce from 'immer';
import { ICollabUser, IProjectCollabUserListState, ISortProjectUserTableState, ISortUserTableState, IUser } from 'interfaces/user';
import {
  SET_COLLAB_USER_LIST_REDUCER,
  SET_LOGOUT_USER_REDUCER,
  SET_PROJECT_COLLAB_USER_LIST_REDUCER,
  SET_SORT_PROJECT_USER_TABLE_REDUCER,
  SET_SORT_USER_TABLE_REDUCER,
  SET_USER_REDUCER,
} from './actionTypes';
import { EProjectUserColumns, ESortDirection, EUserColumns } from 'configs/enums';

export interface IUserState {
  user: IUser;
  projectCollabUserList: IProjectCollabUserListState;
  collabUserList: ICollabUser[];
  sortUserTable: ISortUserTableState;
  sortProjectUserTable: ISortProjectUserTableState;
}

const initialState: IUserState = {
  user: null,
  projectCollabUserList: null,
  collabUserList: null,
  sortUserTable: {
    column: EUserColumns.FirstName,
    direction: ESortDirection.Ascending,
  },
  sortProjectUserTable: {
    column: EProjectUserColumns.FirstName,
    direction: ESortDirection.Ascending,
  },
};

export const userReducer = (state: IUserState = initialState, action: { type: string; data: any }) =>
  produce(state, (draft) => {
    switch (action?.type) {
      case SET_USER_REDUCER:
        draft.user = action?.data;
        break;
      case SET_PROJECT_COLLAB_USER_LIST_REDUCER:
        draft.projectCollabUserList = action?.data;
        break;
      case SET_COLLAB_USER_LIST_REDUCER:
        draft.collabUserList = action?.data;
        break;
      case SET_SORT_USER_TABLE_REDUCER:
        draft.sortUserTable = action?.data;
        break;
      case SET_SORT_PROJECT_USER_TABLE_REDUCER:
        draft.sortProjectUserTable = action?.data;
        break;
      case SET_LOGOUT_USER_REDUCER:
        draft.user = null;
        draft.projectCollabUserList = null;
        draft.collabUserList = null;
        draft.sortUserTable = {
          column: EUserColumns.FirstName,
          direction: ESortDirection.Ascending,
        };
        draft.sortProjectUserTable = {
          column: EProjectUserColumns.FirstName,
          direction: ESortDirection.Ascending,
        };
        break;
      default:
        return state;
    }
  });
