import ApiRoutes from 'configs/apiRoutes';
import { EStatusCode } from 'configs/enums';
import Messages from 'configs/messages';
import { IPipeline } from 'interfaces/pipeline';
import { put, takeLatest, call } from 'redux-saga/effects';
import { GET_PIPELINE_LIST_REQUEST, setPipelineListReducer } from 'redux/reducers/Information/actionTypes';
import {} from 'redux/reducers/Workspace/actionTypes';
import ApiService from 'services/api_service';
import ToastService from 'services/toast_service';

function* requestGetPipelineList(data: { type: string; projectId: string }) {
  try {
    yield put(
      setPipelineListReducer({
        isLoading: true,
        projectId: data?.projectId,
        data: null,
      })
    );
    const response = yield call(ApiService.GET, ApiRoutes.pipeline.default, { filter_project_id: data?.projectId });
    yield put(
      setPipelineListReducer({
        isLoading: false,
        projectId: data?.projectId,
        data:
          response?.entities?.map((pipeline: IPipeline) => {
            return { ...pipeline, project_id: data?.projectId };
          }) ?? [],
      })
    );
  } catch (error: any) {
    if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
      yield ToastService.error(Messages.error.accessDenied);
    } else {
      yield console.log(error);
      yield ToastService.error(Messages.error.default);
    }
  }
}

function* getPipelineList() {
  yield takeLatest(GET_PIPELINE_LIST_REQUEST, requestGetPipelineList);
}

export default getPipelineList;
