import { Fragment, memo } from 'react';
import classes from './styles.module.scss';
import { FireDefaultIcon, FireGreenIcon, FireYellowIcon, FireRedIcon } from 'assets';
import { EPriority } from 'configs/enums';

interface PriorityProps {
  level: number;
  [key: string]: any;
}

export const Priority: React.FC<PriorityProps> = memo((props: PriorityProps) => {
  const { level, ...rest } = props;

  const renderContent = () => {
    switch (level) {
      case EPriority.Highest:
        return (
          <Fragment>
            <FireRedIcon />
            <p>Highest</p>
          </Fragment>
        );
      case EPriority.High:
        return (
          <Fragment>
            <FireYellowIcon />
            <p>High</p>
          </Fragment>
        );
      case EPriority.Medium:
        return (
          <Fragment>
            <FireGreenIcon />
            <p>Medium</p>
          </Fragment>
        );
      case EPriority.Low:
        return (
          <Fragment>
            <FireDefaultIcon />
            <p>Low</p>
          </Fragment>
        );
      default:
        return '-';
    }
  };

  return (
    <div className={classes.container} {...rest}>
      {renderContent()}
    </div>
  );
});

export default Priority;
