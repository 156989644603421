import { IUser } from 'interfaces/user';
import TokenService from './token_service';
import UserService from './user_service';
import ProjectService from './project_service';

interface LoginData {
  message: string;
  token: string;
  refresh_token: string;
  user: IUser;
}

const login = (data: LoginData) => {
  UserService.setUser(data?.user);
  TokenService.setToken(data?.token);
  TokenService.setRefreshToken(data?.refresh_token);
};

const logout = () => {
  const recentProjectId = ProjectService.getRecentProjectId(); // improve UX
  localStorage.clear();
  ProjectService.setRecentProjectId(recentProjectId); // improve UX
};

const AuthService = { login, logout };

export default AuthService;
