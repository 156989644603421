import { Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { IRoute, privateRoutes, publicRoutes } from './routers';
import Middleware from './routers/middleware';
import AuthorizedLayout from 'components/Layouts/AuthorizedLayout';
import NonAuthorizedLayout from 'components/Layouts/NonAuthorizedLayout';
import AppStatus from 'components/AppStatus';
import { History } from 'history';
import { AnyAction, Dispatch } from 'redux';
import { useEffect } from 'react';

interface AppProps {
  history: History;
  dispatch: Dispatch<AnyAction>;
}

const App: React.FC<AppProps> = (props: AppProps) => {
  const { history } = props;

  useEffect(() => {
    // remove the ResizeObserver error
    const errorHandler = (e: any) => {
      if (e?.message?.includes('ResizeObserver loop completed with undelivered notifications' || 'ResizeObserver loop limit exceeded')) {
        const resizeObserver = document.getElementById('webpack-dev-server-client-overlay');
        if (resizeObserver) {
          resizeObserver.style.display = 'none';
        }
      }
    };
    window.addEventListener('error', errorHandler);

    return () => {
      window.removeEventListener('error', errorHandler);
    };
  }, []);

  return (
    <ConnectedRouter history={history}>
      <AppStatus />

      <Switch>
        {publicRoutes?.map((route: IRoute) => (
          <Middleware key={route?.path} path={route?.path} layout={NonAuthorizedLayout} component={route?.component} isAuthProtected={false} exact />
        ))}

        {privateRoutes?.map((route: IRoute) => (
          <Middleware key={route?.path} path={route?.path} layout={AuthorizedLayout} component={route?.component} isAuthProtected={true} exact />
        ))}
      </Switch>
    </ConnectedRouter>
  );
};

export default App;
