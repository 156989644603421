import { Fragment, memo } from 'react';
import classes from './styles.module.scss';
import { Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import { routes } from 'routers/routes';

interface VerifyFailedPageProps {}

const VerifyFailedPage: React.FC<VerifyFailedPageProps> = memo((props: VerifyFailedPageProps) => {
  return (
    <Fragment>
      <p className={classes.title}>Failed verification</p>

      <p className={classes.content}>
        Sorry, the email verification has failed because the verification link has expired or invalid. Please try again.
      </p>

      <Divider className={classes.line} />

      <p>
        Already have an account? <Link to={routes.public.login}>Login</Link>
      </p>
    </Fragment>
  );
});

export default VerifyFailedPage;
