import CheckEmailPage from 'pages/Authentication/CheckEmail';
import HomePage from 'pages/Home';
import LoginPage from 'pages/Authentication/Login';
import RegisterPage from 'pages/Authentication/Register';
import VerifySuccessPage from 'pages/Authentication/VerifySuccess';
import AccountPage from 'pages/Account';
import VerifyFailedPage from 'pages/Authentication/VerifyFailed';
import UsersPage from 'pages/Users';
import ProjectPage from 'pages/Project';
import ForgotPasswordPage from 'pages/Authentication/ForgotPassword';
import CreateEditProjectPage from 'pages/CreateEditProject';
import { routes } from './routes';
import LinkedinSsoPage from 'pages/Callback/LinkedinSso';
import GetStartedPage from 'pages/Authentication/GetStarted';
import AcceptInvitationPage from 'pages/Callback/AcceptInvitation';
import FramePage from 'pages/Frame';
import SchedulerPage from 'pages/Scheduler';
// import SchedulerGanttPage from 'pages/SchedulerGantt';

// IMPORTANT: sort by descending route length

export interface IRoute {
  path: string;
  component: React.FC<any>;
}

const publicRoutes: IRoute[] = [
  { path: routes.public.getStarted, component: GetStartedPage },
  { path: routes.public.login, component: LoginPage },
  { path: routes.public.register, component: RegisterPage },
  { path: routes.public.forgotPassword, component: ForgotPasswordPage },
  { path: routes.public.checkEmail, component: CheckEmailPage },
  { path: routes.public.verifyFailed, component: VerifyFailedPage },
  { path: routes.public.verifySuccess, component: VerifySuccessPage },
  { path: routes.public.callback.linkedinSso, component: LinkedinSsoPage },
  { path: routes.public.callback.acceptInvitation, component: AcceptInvitationPage },
];

const privateRoutes: IRoute[] = [
  { path: routes.private.home, component: HomePage },
  { path: routes.private.users, component: UsersPage },
  { path: routes.private.account, component: AccountPage },
  { path: routes.private.project.frame, component: FramePage },
  { path: routes.private.project.edit, component: CreateEditProjectPage },
  { path: routes.private.project.create, component: CreateEditProjectPage },
  { path: routes.private.project.taskView, component: ProjectPage },
  { path: routes.private.project.default, component: ProjectPage },
  { path: routes.private.project.scheduler, component: SchedulerPage },
];

export { publicRoutes, privateRoutes };
