import { MarkerEndIcon } from 'assets';
import clsx from 'clsx';
import { Fragment, memo } from 'react';
import { BaseEdge, EdgeLabelRenderer, EdgeProps, getBezierPath } from 'reactflow';
import classes from './styles.module.scss';

interface CustomEdgeProps extends EdgeProps {}

const CustomEdge: React.FC<CustomEdgeProps> = memo((props: CustomEdgeProps) => {
  const { sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, selected } = props;

  const [edgePath] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <Fragment>
      <BaseEdge
        path={edgePath}
        style={{
          strokeWidth: 2,
          stroke: selected ? 'var(--danger)' : 'var(--primary)',
        }}
      />
      <EdgeLabelRenderer>
        <MarkerEndIcon
          className={clsx({ [classes.active]: selected })}
          style={{
            position: 'absolute',
            top: targetY,
            left: targetX,
            transform: 'translate(-50%, -50%)',
          }}
        />
      </EdgeLabelRenderer>
    </Fragment>
  );
});

export default CustomEdge;
