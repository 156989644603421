import { memo } from 'react';
import classes from './styles.module.scss';

interface ProjectPlaceholderImageProps {
  title: string;
}

const ProjectPlaceholderImage: React.FC<ProjectPlaceholderImageProps> = memo((props: ProjectPlaceholderImageProps) => {
  const { title } = props;

  const renderTitle = (title: string) => {
    const words = title.split(' ');
    const firstCharacters = words?.map((word) => word[0]);
    return firstCharacters.join('');
  };

  return <div className={classes.container}>{renderTitle(title)}</div>;
});

export default ProjectPlaceholderImage;
