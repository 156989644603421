export enum ECreateQueryString {
  Workspace = 'workspace',
  Resource = 'resource',
}

export enum ERegisterSteps {
  Information,
  Password,
}

export enum EForgotPasswordSteps {
  Email,
  NewPassword,
}

export enum EProjectSettings {
  General = 'general',
  Pipeline = 'pipeline',
  Users = 'users',
}

export enum ESidebarMenu {
  Home,
  Project,
  Scheduler,
  Users,
  Review,
}

export enum EToastStatus {
  Info,
  Error,
  Success,
  Warning,
}

export enum ESelectTheme {
  Form,
  Gradient,
  Header,
  Filter,
  Transparent,
  Role,
  RoundedGradient,
}

export enum EEmailStatus {
  Pending = 'PENDING',
  Accepted = 'ACCEPTED',
  Expired = 'EXPIRED',
}

export enum ESortDirection {
  Ascending = 'asc',
  Descending = 'desc',
}

export enum EUserSettingSort {
  FirstName,
  LastName,
  Email,
  InviteStatus,
}

export enum EResourceColumns {
  Name = 'name',
  Priority = 'priority',
  Complexity = 'complexity',
}

export enum ETaskColumns {
  Actions = "action",
  Stage = 'stage',
  Assigned = 'assigned',
  Status = 'status',
  StartDate = 'startDate',
  DueDate = 'endDate',
  Duration = 'duration',
  Budget = 'budget',
  // Complexity = 'complexity',
  // Dependencies = 'dependencies',
}

export enum EHistoryPopover {
  Revert,
  Copy,
}

export enum EUserColumns {
  FirstName = 'first_name',
  LastName = 'last_name',
  Email = 'email',
  Projects = 'project_ids',
}

export enum EProjectUserColumns {
  FirstName = 'first_name',
  LastName = 'last_name',
  Email = 'email',
  Role = 'project_roles',
  InviteStatus = 'invite_status',
}

export enum EStatusCode {
  MovedPermanently = 301,
  Unauthorized = 401,
  AccessDenied = 403,
  NotFound = 404,
  InternalServerError = 500,
  BadGateway = 502,
}

export enum EPriority {
  Highest = 1,
  High = 2,
  Medium = 3,
  Low = 4,
}

export enum EGanttToolbarItem { // Reference ej2 "ToolbarItem" type
  // Chart Tooles
  ZoomIn = 'zoomIn',
  ZoomOut = 'zoomOut',
  ZoomFit = 'zoomFit',
  ExpandAll = 'expandAll',
  CollapseAll = 'collapseAll',
  CsvExport = 'csvExport',
  PdfExport = 'pdfExport',
  CriticalPath = 'criticalPath',

  // ShowGridLines = 'showGridLines',
  // ShowDependencyLines = 'showDependencyLines',
  // RowHeight = 'rowHeight',
  // ChartOnly = 'chartOnly',
}

export enum EScheduleLayer {
  Project = 0,
  Resource,
  Task,
}
