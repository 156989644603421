import { Fragment, memo, useEffect, useMemo, useState } from 'react';
import classes from './styles.module.scss';
import Input from 'components/Input';
import * as Yup from 'yup';
import Regexes from 'configs/regexes';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { EForgotPasswordSteps } from 'configs/enums';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import ToastService from 'services/toast_service';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import SecondaryButton from 'components/Buttons/SecondaryButton';
import Messages from 'configs/messages';
import { setIsLoadingReducer } from 'redux/reducers/Status/actionTypes';
import ApiRoutes from 'configs/apiRoutes';
import { routes } from 'routers/routes';
import { Link } from 'react-router-dom';

interface IEmailFormData {
  email: string;
}

interface INewPasswordFormData {
  code: string;
  newPassword: string;
  confirmNewPassword: string;
}

interface IForgotPasswordFromData extends IEmailFormData, INewPasswordFormData {}

interface ForgotPasswordPageProps {}

const ForgotPasswordPage: React.FC<ForgotPasswordPageProps> = memo((props: ForgotPasswordPageProps) => {
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [step, setStep] = useState<EForgotPasswordSteps>(EForgotPasswordSteps.Email);
  const [information, setInformation] = useState<IEmailFormData>(null);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape(
        step === EForgotPasswordSteps.Email
          ? {
              email: Yup.string().required('Please enter your email.').matches(Regexes.email, 'Please enter a valid email.'),
            }
          : {
              code: Yup.string().required('Please enter your verification code.'),
              newPassword: Yup.string()
                .required('Please enter your new password.')
                .matches(
                  Regexes.password,
                  'Password must be least 8 characters including an upper case, an lower case, a number, and a special character.'
                ),
              confirmNewPassword: Yup.string()
                .required('Please confirm your new password.')
                .oneOf([Yup.ref('newPassword'), null], 'Confirm new password does not match.'),
            }
      ),
    [step]
  );

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<IForgotPasswordFromData>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const watchNewPassword = watch('newPassword');
  const watchConfirmNewPassword = watch('confirmNewPassword');

  useEffect(() => {
    if (information) {
      setStep(EForgotPasswordSteps.NewPassword);
    }
  }, [information]);

  useEffect(() => {
    if (step === EForgotPasswordSteps.Email && information) {
      reset({
        email: information?.email,
      });
    }
  }, [step, information]);

  useEffect(() => {
    if (watchNewPassword !== watchConfirmNewPassword && watchConfirmNewPassword !== '') {
      setError('confirmNewPassword', {
        type: 'match',
        message: 'Confirm new password does not match',
      });
    } else if (watchNewPassword === watchConfirmNewPassword && watchConfirmNewPassword !== '') {
      clearErrors('confirmNewPassword');
    }
  }, [watchConfirmNewPassword, watchNewPassword]);

  const onBack = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event?.preventDefault();
    setStep(EForgotPasswordSteps.Email);
  };

  const onSubmitEmail = (data: IEmailFormData) => {
    setInformation(data);
    // do something
  };

  const onSubmitPassword = async (data: INewPasswordFormData) => {
    if (!executeRecaptcha) {
      ToastService.error(Messages.error.recaptchaNotReady);
      return;
    }

    dispatch(setIsLoadingReducer(true));
    await executeRecaptcha(ApiRoutes.auth.forgotPassword)
      .then(async (token: string) => {
        console.log(token);
        // await ApiService.POST(ApiRoutes.auth.reCaptcha, { response_token: token })
        //   .then(async () => {
        //     await CommonService.sha256(data?.password)
        //       .then(async (hashedPassword) => {
        //         await ApiService.POST(ApiRoutes.auth.register, {
        //           first_name: information?.firstName,
        //           last_name: information?.lastName,
        //           email: information?.email,
        //           mobile: information?.phone,
        //           password: hashedPassword,
        //         })
        //           .then(() => {
        //             dispatch(setIsLoadingReducer(false));
        //             dispatch(
        //               push({
        //                 pathname: routes.public.checkEmail,
        //                 search: `&email=${encodeURIComponent(email?.email)}`,
        //               })
        //             );
        //           })
        //           .catch((error) => {
        //             console.log(error);
        //             ToastService.error(Messages.error.default);
        //             dispatch(setIsLoadingReducer(false));
        //           });
        //       })
        //       .catch((error) => {
        //         console.log(error);
        //         ToastService.error(Messages.error.default);
        //         dispatch(setIsLoadingReducer(false));
        //       });
        //   })
        //   .catch(() => {
        //     ToastService.error(Messages.error.recaptchaFailed);
        //     dispatch(setIsLoadingReducer(false));
        //   });
      })
      .catch((error) => {
        console.log(error);
        ToastService.error(Messages.error.default);
        dispatch(setIsLoadingReducer(false));
      });
  };

  return (
    <Fragment>
      {step === EForgotPasswordSteps.Email ? (
        <Fragment>
          <p className={classes.title}>Forgot Password</p>
          <form onSubmit={handleSubmit(onSubmitEmail)}>
            <p className="mb-4 text-center">You will receive an email with instructions on how to reset your password in a few minutes.</p>

            <Input
              label="Email"
              inputRef={register('email')}
              type="text"
              placeholder="Your email"
              autoComplete="email"
              errorMessage={errors?.email?.message}
            />

            <PrimaryButton className="mb-4" type="submit" fullWidth>
              Reset password
            </PrimaryButton>

            <div className="text-center">
              <Link to={routes.public.login}>Login</Link>
            </div>
          </form>
        </Fragment>
      ) : (
        <Fragment>
          <p className={classes.title}>New Password</p>
          <form onSubmit={handleSubmit(onSubmitPassword)}>
            <p className="mb-4 text-center">Please enter the verification code sent to your email.</p>

            <Input
              label="Verification code"
              inputRef={register('code')}
              type="text"
              placeholder="Your verification code"
              autoComplete="off"
              errorMessage={errors?.code?.message}
            />

            <Input
              label="New password"
              inputRef={register('newPassword')}
              type="password"
              placeholder="Your new password"
              errorMessage={errors?.newPassword?.message}
            />

            <Input
              className="mb-4"
              label="Confirm new password"
              inputRef={register('confirmNewPassword')}
              type="password"
              placeholder="Conform your new password"
              errorMessage={errors?.confirmNewPassword?.message}
            />

            <PrimaryButton className="mb-4" type="submit" fullWidth>
              Submit
            </PrimaryButton>

            <SecondaryButton className={classes.backButton} type="button" onClick={onBack}>
              Back
            </SecondaryButton>
          </form>
        </Fragment>
      )}
    </Fragment>
  );
});

export default ForgotPasswordPage;
