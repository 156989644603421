import ApiRoutes from 'configs/apiRoutes';
import { EStatusCode } from 'configs/enums';
import Messages from 'configs/messages';
import { put, takeLatest, call } from 'redux-saga/effects';
import { GET_PUBLISHED_PIPELINE_LIST_REQUEST, setPublishedPipelineListReducer } from 'redux/reducers/Information/actionTypes';
import {} from 'redux/reducers/Workspace/actionTypes';
import ApiService from 'services/api_service';
import ToastService from 'services/toast_service';

function* requestGetPublishedPipelineList(data: { type: string; pipelineId: string }) {
  try {
    yield put(
      setPublishedPipelineListReducer({
        isLoading: true,
        pipelineId: data?.pipelineId,
        data: null,
      })
    );
    const response = yield call(ApiService.GET, ApiRoutes.publishedPipeline.default, { filter_ref_pipeline_id: data?.pipelineId });
    yield put(
      setPublishedPipelineListReducer({
        isLoading: false,
        pipelineId: data?.pipelineId,
        data: response?.entities ?? [],
      })
    );
  } catch (error: any) {
    if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
      yield ToastService.error(Messages.error.accessDenied);
    } else {
      yield console.log(error);
      yield ToastService.error(Messages.error.default);
    }
  }
}

function* getPublishedPipelineList() {
  yield takeLatest(GET_PUBLISHED_PIPELINE_LIST_REQUEST, requestGetPublishedPipelineList);
}

export default getPublishedPipelineList;
