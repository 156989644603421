export const routes = {
  public: {
    getStarted: '/get-started',
    login: '/login',
    register: '/register',
    forgotPassword: '/forgot-password',
    checkEmail: '/check-email',
    verifySuccess: '/account-invite-success',
    verifyFailed: '/account-invite-failed',
    callback: {
      linkedinSso: '/linkedin-openid',
      acceptInvitation: '/invites/confirm-project-invite/:token',
    },
  },
  private: {
    home: '/',
    account: '/account',
    project: {
      default: '/project/:projectId/pipeline/:pipelineId/resource-view',
      taskView: '/project/:projectId/pipeline/:pipelineId/task-view',
      create: '/workspace/:workspaceId/project/create',
      edit: '/workspace/:workspaceId/project/:projectId/edit',
      frame: '/project/:projectId/pipeline/:pipelineId/resource/:resourceId/frame',
      scheduler: '/scheduler',
    },
    users: '/users',
  },
};
