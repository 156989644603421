import produce from 'immer';
import { IWorkspace } from 'interfaces/workspace';
import { IProject } from 'interfaces/project';
import {
  SET_IS_ARCHIVED_REDUCER,
  SET_LOGOUT_WORKSPACE_REDUCER,
  SET_WORKSPACE_REDUCER,
  SET_PROJECT_REDUCER,
  SET_PIPELINE_REDUCER,
  SET_ALL_PROJECT_LIST_REDUCER,
  SET_RESOURCE_TABLE_REDUCER,
  SET_SORT_TASK_TABLE_REDUCER,
} from './actionTypes';
import { IPipeline, IResourceTableState, ISortTaskTableState } from 'interfaces/pipeline';
import { EResourceColumns, ESortDirection, ETaskColumns } from 'configs/enums';
import { ALL_OPTION } from 'configs/constant';

export interface IWorkspaceState {
  workspace: IWorkspace;
  project: IProject;
  isArchived: boolean;
  pipeline: IPipeline;
  allProjectList: IProject[];
  resourceTable: IResourceTableState;
  sortTaskTable: ISortTaskTableState;
}

const initialState: IWorkspaceState = {
  workspace: null,
  project: null,
  isArchived: false,
  pipeline: null,
  allProjectList: [],
  resourceTable: {
    searchKey: '',
    priority: ALL_OPTION,
    users: [ALL_OPTION],
    status: ALL_OPTION,
    page: 1,
    limit: 50,
    totalPages: 1,
    column: EResourceColumns.Name,
    direction: ESortDirection.Ascending,
  },
  sortTaskTable: {
    column: ETaskColumns.Stage,
    direction: null,
  },
};

export const workspaceReducer = (state: IWorkspaceState = initialState, action: { type: string; data: any }) =>
  produce(state, (draft) => {
    switch (action?.type) {
      case SET_WORKSPACE_REDUCER:
        draft.workspace = action?.data;
        break;
      case SET_PROJECT_REDUCER:
        draft.project = action?.data;
        break;
      case SET_IS_ARCHIVED_REDUCER:
        draft.isArchived = action?.data;
        break;
      case SET_PIPELINE_REDUCER:
        draft.pipeline = action?.data;
        break;
      case SET_ALL_PROJECT_LIST_REDUCER:
        draft.allProjectList = action?.data;
        break;
      case SET_RESOURCE_TABLE_REDUCER:
        draft.resourceTable = action?.data;
        break;
      case SET_SORT_TASK_TABLE_REDUCER:
        draft.sortTaskTable = action?.data;
        break;
      case SET_LOGOUT_WORKSPACE_REDUCER:
        draft.workspace = null;
        draft.project = null;
        draft.isArchived = null;
        draft.pipeline = null;
        draft.allProjectList = [];
        draft.resourceTable = {
          searchKey: '',
          priority: ALL_OPTION,
          users: [ALL_OPTION],
          status: ALL_OPTION,
          page: 1,
          limit: 50,
          totalPages: 1,
          column: EResourceColumns.Name,
          direction: ESortDirection.Ascending,
        };
        draft.sortTaskTable = {
          column: ETaskColumns.Stage,
          direction: null,
        };
        break;
      default:
        return state;
    }
  });
