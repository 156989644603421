import { memo } from 'react';
import classes from './styles.module.scss';
import { COMPLEXITY_OPTIONS } from 'configs/constant';
import { IComplexityOption } from 'interfaces/common';

interface ComplexityProps {
  complexityLevel: number;
  [key: string]: any;
}

export const Complexity: React.FC<ComplexityProps> = memo((props: ComplexityProps) => {
  const { complexityLevel, ...rest } = props;

  const getBorderColor = () => {
    switch (complexityLevel) {
      case 1:
        return 'var(--green)';
      case 2:
        return 'var(--yellow)';
      case 3:
        return 'var(--warning)';
      case 4:
        return 'var(--red)';
      default:
        return 'var(--gray)';
    }
  };

  return (
    <div className={classes.container} style={{ borderColor: getBorderColor() }} {...rest}>
      {COMPLEXITY_OPTIONS?.find((item: IComplexityOption) => item?.value === complexityLevel)?.label || '-'}
    </div>
  );
});

export default Complexity;
