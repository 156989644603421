const exportCSV = (data: string, filename: string) => {
  const csvContent = 'data:text/csv;charset=utf-8,' + data;
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const formatNumber = (number: number | null) => {
  return number !== null ? Number(number)?.toLocaleString('en-US') : 'N/A';
};

const sha256 = async (value: string) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(value);
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashedString = hashArray.map((byte: number) => byte.toString(16).padStart(2, '0')).join('');
  return hashedString;
};

const createArrayFromRange = (n: number, m: number) => {
  const result = [];
  for (let i = n; i <= m; ++i) {
    result?.push(i);
  }
  return result;
};

const getFullName = (user: { first_name?: string; last_name?: string }, emptyValue?: string) => {
  return !user?.first_name && !user?.last_name ? emptyValue ?? 'N/A' : `${user?.first_name} ${user?.last_name}`;
};

const getImageThumbnailUrl = (url: string) => {
  const lastIndex = url?.lastIndexOf('.');
  if (lastIndex !== -1) {
    return url?.slice(0, lastIndex) + '_thumbnail' + url?.slice(lastIndex);
  } else {
    return url;
  }
};

const CommonService = { exportCSV, formatNumber, sha256, createArrayFromRange, getFullName, getImageThumbnailUrl };

export default CommonService;
