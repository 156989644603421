import { memo, useEffect, useMemo, useRef, useState } from 'react';
import classes from './styles.module.scss';
import clsx from 'clsx';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import ModalInviteUser from 'components/ModalInviteUser';
import ApiService from 'services/api_service';
import ApiRoutes from 'configs/apiRoutes';
import ToastService from 'services/toast_service';
import Messages from 'configs/messages';
import { IReducer } from 'redux/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { setIsLoadingReducer } from 'redux/reducers/Status/actionTypes';
import { EmailIcon, RemoveUserIcon, SortDownDisabledIcon, SortUpIcon, UserPlaceholderImage } from 'assets';
import { EEmailStatus, ESelectTheme, ESortDirection, EProjectUserColumns, EStatusCode } from 'configs/enums';
import Select from 'components/Select';
import { DEFAULT_ROLE, ROLE_OPTIONS } from 'configs/constant';
import { ICollabUser, IProjectRole } from 'interfaces/user';
import { ISelectOption } from 'interfaces/common';
import { IEditProjectUrlParams } from 'interfaces/project';
import { useParams } from 'react-router-dom';
import { Skeleton } from '@mui/material';
import {
  getCollabUserListRequest,
  getProjectCollabUserListRequest,
  setProjectCollabUserListReducer,
  setSortProjectUserTableReducer,
} from 'redux/reducers/User/actionTypes';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import UserService from 'services/user_service';

interface UsersSettingsProps {}

const UsersSettings = memo((props: UsersSettingsProps) => {
  const user = useMemo(() => UserService.getUser(), []);

  const dispatch = useDispatch();
  const { projectId } = useParams<IEditProjectUrlParams>();

  const { projectCollabUserList, sortProjectUserTable, sortUserTable } = useSelector((state: IReducer) => state?.user);
  const { project } = useSelector((state: IReducer) => state?.workspace);

  const [isOpenModalInviteUser, setIsOpenModalInviteUser] = useState<boolean>(false);
  const [userRoleOption, setUserRoleOption] = useState<any>()
  const tableHeadRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (projectCollabUserList?.projectId !== projectId) {
      dispatch(getProjectCollabUserListRequest(projectId, sortProjectUserTable?.column, sortProjectUserTable?.direction));
    }
  }, [projectId, projectCollabUserList]);

  const onCloseModals = () => {
    setIsOpenModalInviteUser(false);
  };

  const resendEmail = (email: string) => {
    dispatch(setIsLoadingReducer(true));
    ApiService.POST(ApiRoutes.invite.project, {
      to_emails: [email],
      project_id: project?._id,
    })
      .then(() => {
        ToastService.success(Messages.success.invited);
      })
      .catch((error) => {
        if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
          ToastService.error(Messages.error.accessDenied);
          return;
        }
        console.log(error);
        ToastService.error(Messages.error.default);
      })
      .finally(() => dispatch(setIsLoadingReducer(false)));
  };

  const removeUser = (userId: string) => {
    dispatch(setIsLoadingReducer(true));
    ApiService.DELETE(ApiRoutes.user.remove.replace(':userId', userId).replace(':projectId', projectId))
      .then(() => {
        dispatch(
          setProjectCollabUserListReducer({
            ...projectCollabUserList,
            data: projectCollabUserList?.data?.filter((user: ICollabUser) => user?._id !== userId),
          })
        );
        dispatch(getCollabUserListRequest(user?.project_ids?.join(','), sortUserTable?.column, sortUserTable?.direction));
        ToastService.success(Messages.success.removed);
      })
      .catch((error) => {
        if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
          ToastService.error(Messages.error.accessDenied);
          return;
        }
        console.log(error);
        ToastService.error(Messages.error.default);
      })
      .finally(() => dispatch(setIsLoadingReducer(false)));
  };

  const onInvite = (emailList: string[]) => {
    dispatch(setIsLoadingReducer(true));
    ApiService.POST(ApiRoutes.invite.project, {
      to_emails: [...emailList],
      project_id: project?._id,
    })
      .then(() => {
        dispatch(getProjectCollabUserListRequest(projectId, sortProjectUserTable?.column, sortProjectUserTable?.direction));
        dispatch(getCollabUserListRequest(user?.project_ids?.join(','), sortUserTable?.column, sortUserTable?.direction));
        onCloseModals();
        ToastService.success(Messages.success.invited);
      })
      .catch((error) => {
        if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
          ToastService.error(Messages.error.accessDenied);
          return;
        }
        console.log(error);
        ToastService.error(Messages.error.default);
      })
      .finally(() => dispatch(setIsLoadingReducer(false)));
  };

  const onChangeRole = (user: ICollabUser, option: ISelectOption) => {
    setUserRoleOption(option)
    dispatch(setIsLoadingReducer(true));
    ApiService.PATCH(ApiRoutes.user.role.replace(':entityType', 'project').replace(':entityId', projectId), {
      roles: [
        {
          user_id: user?._id,
          role: option?.value,
        },
      ],
    })
      .then((response) => {
        dispatch(
          setProjectCollabUserListReducer({
            ...projectCollabUserList,
            data: projectCollabUserList?.data?.map((item: ICollabUser) => {
              if (item?._id === user?._id) {
                return response?.entities?.[0];
              }
              return item;
            }),
          })
        );
      })
      .catch((error) => {
        if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
          setUserRoleOption(DEFAULT_ROLE)
          ToastService.error(Messages.error.accessDenied);
          return;
        }
        console.log(error);
        ToastService.error(Messages.error.default);
      })
      .finally(() => dispatch(setIsLoadingReducer(false)));
  };

  return (
    <div className={classes.container}>
      <p className={classes.description}>Invite collaborators and manage access in your project.</p>

      <div className={classes.toolbar}>
        {/* <input type="text" placeholder="Search" /> */}
        <div />
        <PrimaryButton onClick={() => setIsOpenModalInviteUser(true)}>Invite User</PrimaryButton>
      </div>

      <div className={classes.table}>
        <div ref={tableHeadRef} className={classes.head}>
          <div className={clsx(classes.column, classes.avatar)} />
          <div
            className={clsx(classes.column, classes.sort)}
            onClick={() => {
              const column = EProjectUserColumns.FirstName;
              const direction =
                sortProjectUserTable?.column === EProjectUserColumns.FirstName
                  ? sortProjectUserTable?.direction === ESortDirection.Ascending
                    ? ESortDirection.Descending
                    : ESortDirection.Ascending
                  : ESortDirection.Ascending;
              dispatch(
                setSortProjectUserTableReducer({
                  column,
                  direction,
                })
              );
              dispatch(getProjectCollabUserListRequest(projectId, column, direction));
            }}
          >
            <div>
              <p>First Name</p>
              {sortProjectUserTable?.column === EProjectUserColumns.FirstName ? (
                <SortUpIcon className={clsx({ [classes.revert]: sortProjectUserTable?.direction === ESortDirection.Ascending })} />
              ) : (
                <SortDownDisabledIcon />
              )}
            </div>
          </div>
          <div
            className={clsx(classes.column, classes.sort)}
            onClick={() => {
              const column = EProjectUserColumns.LastName;
              const direction =
                sortProjectUserTable?.column === EProjectUserColumns.LastName
                  ? sortProjectUserTable?.direction === ESortDirection.Ascending
                    ? ESortDirection.Descending
                    : ESortDirection.Ascending
                  : ESortDirection.Ascending;
              dispatch(
                setSortProjectUserTableReducer({
                  column,
                  direction,
                })
              );
              dispatch(getProjectCollabUserListRequest(projectId, column, direction));
            }}
          >
            <div>
              <p>Last Name</p>
              {sortProjectUserTable?.column === EProjectUserColumns.LastName ? (
                <SortUpIcon className={clsx({ [classes.revert]: sortProjectUserTable?.direction === ESortDirection.Ascending })} />
              ) : (
                <SortDownDisabledIcon />
              )}
            </div>
          </div>
          <div
            className={clsx(classes.column, classes.sort, classes.email)}
            onClick={() => {
              const column = EProjectUserColumns.Email;
              const direction =
                sortProjectUserTable?.column === EProjectUserColumns.Email
                  ? sortProjectUserTable?.direction === ESortDirection.Ascending
                    ? ESortDirection.Descending
                    : ESortDirection.Ascending
                  : ESortDirection.Ascending;
              dispatch(
                setSortProjectUserTableReducer({
                  column,
                  direction,
                })
              );
              dispatch(getProjectCollabUserListRequest(projectId, column, direction));
            }}
          >
            <div>
              <p>Email</p>
              {sortProjectUserTable?.column === EProjectUserColumns.Email ? (
                <SortUpIcon className={clsx({ [classes.revert]: sortProjectUserTable?.direction === ESortDirection.Ascending })} />
              ) : (
                <SortDownDisabledIcon />
              )}
            </div>
          </div>
          <div
            className={clsx(classes.column, classes.sort)}
            onClick={() => {
              const column = EProjectUserColumns.Role;
              const direction =
                sortProjectUserTable?.column === EProjectUserColumns.Role
                  ? sortProjectUserTable?.direction === ESortDirection.Ascending
                    ? ESortDirection.Descending
                    : ESortDirection.Ascending
                  : ESortDirection.Ascending;
              dispatch(
                setSortProjectUserTableReducer({
                  column,
                  direction,
                })
              );
              dispatch(getProjectCollabUserListRequest(projectId, column, direction));
            }}
          >
            <div>
              <p>Role</p>
              {sortProjectUserTable?.column === EProjectUserColumns.Role ? (
                <SortUpIcon className={clsx({ [classes.revert]: sortProjectUserTable?.direction === ESortDirection.Ascending })} />
              ) : (
                <SortDownDisabledIcon />
              )}
            </div>
          </div>
          <div
            className={clsx(classes.column, classes.sort)}
            onClick={() => {
              const column = EProjectUserColumns.InviteStatus;
              const direction =
                sortProjectUserTable?.column === EProjectUserColumns.InviteStatus
                  ? sortProjectUserTable?.direction === ESortDirection.Ascending
                    ? ESortDirection.Descending
                    : ESortDirection.Ascending
                  : ESortDirection.Ascending;
              dispatch(
                setSortProjectUserTableReducer({
                  column,
                  direction,
                })
              );
              dispatch(getProjectCollabUserListRequest(projectId, column, direction));
            }}
          >
            <div>
              <p>Invite Status</p>
              {sortProjectUserTable?.column === EProjectUserColumns.InviteStatus ? (
                <SortUpIcon className={clsx({ [classes.revert]: sortProjectUserTable?.direction === ESortDirection.Ascending })} />
              ) : (
                <SortDownDisabledIcon />
              )}
            </div>
          </div>
          <div className={clsx(classes.column, classes.actionButtons)}>Actions</div>
        </div>

        {projectCollabUserList?.projectId === projectId ? (
          <div className={classes.bodyContainer}>
            <div className={clsx(classes.body, { [classes.empty]: !projectCollabUserList?.data?.length })}>
              {projectCollabUserList?.data?.length ? (
                projectCollabUserList?.data?.map((user: ICollabUser, userIndex: number) => {
                  return (
                    <div className={classes.row} key={`table-row-${userIndex}`}>
                      <div className={clsx(classes.column, classes.avatar)}>
                        <img src={user?.profile_image_url ?? UserPlaceholderImage} alt="Avatar" />
                      </div>
                      <div className={classes.column}>{user?.first_name ?? 'N/A'}</div>
                      <div className={classes.column}>{user?.last_name ?? 'N/A'}</div>
                      <div className={clsx(classes.column, classes.email)}>{user?.email ?? 'N/A'}</div>
                      <div className={clsx(classes.column, classes.role)}>
                      {
                          (user?.invite_status !== EEmailStatus.Pending) ? (
                            <Select
                              customTheme={ESelectTheme.Role}
                              defaultValue={
                                ROLE_OPTIONS?.find(
                                  (role: ISelectOption) =>
                                    role?.value === user?.project_roles?.find((item: IProjectRole) => item?.project_id === projectId)?.role
                                ) ?? DEFAULT_ROLE
                              }
                              value={userRoleOption}
                              options={ROLE_OPTIONS}
                              onChange={(option: any) => onChangeRole(user, option)}
                              isDisabled={'OWNER' === user?.project_roles?.find((item: IProjectRole) => item?.project_id === projectId)?.role}
                            />
                          ) : (
                            <OverlayTrigger placement="bottom" overlay={<Tooltip>Cannot change role till user has accepted the invite</Tooltip>}>
                              {({ ref, ...triggerHandler }) => (
                                <div ref={ref} {...triggerHandler}>
                                  <Select
                                    customTheme={ESelectTheme.Role}
                                    defaultValue={
                                      ROLE_OPTIONS?.find(
                                        (role: ISelectOption) =>
                                          role?.value === user?.project_roles?.find((item: IProjectRole) => item?.project_id === projectId)?.role
                                      ) ?? DEFAULT_ROLE
                                    }
                                    value={userRoleOption}
                                    options={ROLE_OPTIONS}
                                    onChange={(option: any) => onChangeRole(user, option)}
                                    isDisabled={true}
                                  />
                                </div>
                                )}
                              </OverlayTrigger>
                          )
                        }
                      </div>
                      <div
                        className={clsx(classes.column, classes.status, {
                          [classes.pending]: user?.invite_status === EEmailStatus.Pending,
                          [classes.accepted]: user?.invite_status === EEmailStatus.Accepted || !user?.invite_status,
                        })}
                      >
                        {user?.invite_status ?? 'ACCEPTED'}
                      </div>
                      <div className={clsx(classes.column, classes.actionButtons)}>
                        <OverlayTrigger placement="auto" overlay={<Tooltip>Delete user</Tooltip>}>
                          {({ ref, ...triggerHandler }) => (
                            <div ref={ref} {...triggerHandler}>
                              <RemoveUserIcon onClick={() => removeUser(user?._id)} />
                            </div>
                          )}
                        </OverlayTrigger>
                        {user?.invite_status === EEmailStatus.Pending || user?.invite_status === EEmailStatus.Expired ? (
                          <OverlayTrigger placement="auto" overlay={<Tooltip>Resend invite</Tooltip>}>
                            {({ ref, ...triggerHandler }) => (
                              <div ref={ref} {...triggerHandler}>
                                <EmailIcon onClick={() => resendEmail(user?.email)} />
                              </div>
                            )}
                          </OverlayTrigger>
                        ) : null}
                      </div>
                    </div>
                  );
                })
              ) : (
                <p>No users found.</p>
              )}
            </div>
          </div>
        ) : (
          <Skeleton
            variant="rectangular"
            width={tableHeadRef?.current?.offsetWidth ?? '100%'}
            height="calc(100vh - 24px - 40px - 4px - 18px - 24px - 38px - 24px - 32px - 40px - 10px)"
            sx={{ minHeight: '500px', borderRadius: '8px', background: 'var(--backgroundLight)' }}
          />
        )}
      </div>

      <ModalInviteUser isOpen={isOpenModalInviteUser} onClose={onCloseModals} onSubmit={onInvite} />
    </div>
  );
});

export default UsersSettings;
