import { memo } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import SecondaryButton from 'components/Buttons/SecondaryButton';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import classes from './styles.module.scss';
import { IDeleteFormData } from 'interfaces/common';

interface ModalDeleteResourceProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: IDeleteFormData) => void;
}

const ModalDeleteResource = memo((props: ModalDeleteResourceProps) => {
  const { isOpen, onClose, onSubmit } = props;

  return (
    <Dialog transitionDuration={0.3} open={isOpen} onClose={onClose} className={classes.container}>
      <DialogTitle>Delete resource</DialogTitle>
      <DialogContent>
        <p className={classes.description}>Are you sure you want to delete this resource? You won't be able to restore it.</p>
      </DialogContent>
      <DialogActions>
        <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
        <PrimaryButton onClick={onSubmit}>Submit</PrimaryButton>
      </DialogActions>
    </Dialog>
  );
});

export default ModalDeleteResource;
