import { memo, useEffect, useState } from 'react';
import classes from './styles.module.scss';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import GalaxyBackground from 'components/GalaxyBackground';
import { routes } from 'routers/routes';

interface VerifySuccessPageProps {}

const VerifySuccessPage: React.FC<VerifySuccessPageProps> = memo((props: VerifySuccessPageProps) => {
  const dispatch = useDispatch();

  const [countDown, setCountDown] = useState<number>(5);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown((prev) => {
        if (prev - 1 === 0) {
          clearInterval(interval);
        }
        return prev - 1;
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (!countDown) {
      dispatch(push(routes.public.login)); // temp
    }
  }, [countDown]);

  return (
    <GalaxyBackground>
      <div className={classes.container}>
        <span>Welcome to Unipipe. You're all set!</span>
        <span className={classes.redirectContent}>
          Redirecting to the homepage in {countDown} (<Link to={routes.private.home}>Go now</Link>)
        </span>
      </div>
    </GalaxyBackground>
  );
});

export default VerifySuccessPage;
