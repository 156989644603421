import produce from 'immer';
import { SET_IS_LOADING_REDUCER } from './actionTypes';

export interface IStatusState {
  isLoading: boolean;
  progress?: number;
}

const initialState: IStatusState = {
  isLoading: false,
  progress: null,
};

export const statusReducer = (state: IStatusState = initialState, action: { type: string; data: boolean; progress?: number }) =>
  produce(state, (draft) => {
    switch (action?.type) {
      case SET_IS_LOADING_REDUCER:
        draft.isLoading = action?.data;
        draft.progress = action?.progress;
        break;
      default:
        return state;
    }
  });
