import { IUser } from 'interfaces/user';

const getUser = (): IUser => {
  return JSON.parse(localStorage.getItem('user'));
};

const setUser = (user: IUser) => {
  localStorage.setItem('user', JSON.stringify(user));
};

const UserService = {
  getUser,
  setUser,
};

export default UserService;
