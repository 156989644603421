import { memo, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import SecondaryButton from 'components/Buttons/SecondaryButton';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import Input from 'components/Input';
import classes from './styles.module.scss';
import { IDeleteFormData } from 'interfaces/common';

interface ModalDeleteWorkspaceProps {
  isOpen: boolean;
  name: string;
  onClose: () => void;
  onSubmit: (data: IDeleteFormData) => void;
}

const ModalDeleteWorkspace = memo((props: ModalDeleteWorkspaceProps) => {
  const { isOpen, name, onClose, onSubmit } = props;

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      title: Yup.string()
        .required('This field is required.')
        .oneOf([name as string], 'Workspace name is not correct.'),
    });
  }, [name]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IDeleteFormData>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen]);

  return (
    <Dialog transitionDuration={0.3} open={isOpen} onClose={onClose} className={classes.container}>
      <DialogTitle>Delete workspace</DialogTitle>
      <DialogContent>
        <p className={classes.description}>
          To confirm, please type <b>{name as string}</b> in the box below.
        </p>
        <form id="workspace" onSubmit={handleSubmit(onSubmit)}>
          <Input placeholder="Workspace name" inputRef={register('title')} autoComplete="off" autoFocus={true} errorMessage={errors?.title?.message} />
        </form>
      </DialogContent>
      <DialogActions>
        <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
        <PrimaryButton type="submit" form="workspace">
          Submit
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
});

export default ModalDeleteWorkspace;
