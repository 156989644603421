import ApiRoutes from 'configs/apiRoutes';
import { IGlobalAppConfiguration } from 'interfaces/pipeline';
import { put, takeLatest, call } from 'redux-saga/effects';
import { GET_GLOBAL_APP_CONFIGURATIONS_REQUEST, setGlobalAppConfigurationsReducer } from 'redux/reducers/Information/actionTypes';
import { setIsLoadingReducer } from 'redux/reducers/Status/actionTypes';
import {} from 'redux/reducers/Workspace/actionTypes';
import ApiService from 'services/api_service';

function* requestGetGlobalAppConfigurations() {
  try {
    yield put(setIsLoadingReducer(true));
    const response = yield call(ApiService.GET, ApiRoutes.project.appConfig.global);
    const fromObjectToArray: IGlobalAppConfiguration[] = Object.entries(response)?.map(
      ([appName, appData]: [appName: string, appData: { app_versions?: string[] }]) => {
        return {
          app_name: appName,
          app_versions: appData?.app_versions,
        };
      }
    );
    yield put(setGlobalAppConfigurationsReducer(fromObjectToArray));
  } catch (error) {
    // yield console.log(error);
    // yield ToastService.error(Messages.error.default);
    const defaultGlobalAppConfigurations: IGlobalAppConfiguration[] = [
      {
        app_name: 'blender',
        app_versions: ['2.8', '2.9', '3.0', '3.1', '3.2', '3.3', '3.4', '3.5', '3.6', '3.7', '3.8', '3.9', '4.0', '4.1'],
      },
      {
        app_name: 'natron',
        app_versions: ['2.4.4', '2.5.0'],
      },
      {
        app_name: 'krita',
        app_versions: ['4.0', '4.1', '4.2', '4.3', '4.4', '5.0', '5.1'],
      },
    ];
    yield put(setGlobalAppConfigurationsReducer(defaultGlobalAppConfigurations));
  } finally {
    yield put(setIsLoadingReducer(false));
  }
}

function* getGlobalAppConfigurations() {
  yield takeLatest(GET_GLOBAL_APP_CONFIGURATIONS_REQUEST, requestGetGlobalAppConfigurations);
}

export default getGlobalAppConfigurations;
