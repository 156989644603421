import { Fragment, memo, useCallback, useEffect, useState } from 'react';
import classes from './styles.module.scss';
import Select from 'components/Select';
import { MenuProps, OptionProps, components } from 'react-select';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { CollapseIcon, DeleteIcon, EditIcon, PlusBlackIcon, SettingIcon } from 'assets';
import { IReducer } from 'redux/reducers';
import { ICreateEditWorkspaceFormData, ICreateEditDeleteWorkspaceModal, IWorkspace } from 'interfaces/workspace';
import { IConfirmPublishRevertPipelineModal, IWorkspaceOption, ISelectOption } from 'interfaces/common';
import {
  getResourceSummaryRequest,
  setArchivedProjectListReducer,
  setWorkspaceListReducer,
  setPipelineListReducer,
  setProjectListReducer,
  setPublishedPipelineListReducer,
  setResourceListReducer,
} from 'redux/reducers/Information/actionTypes';
import { Skeleton } from '@mui/material';
import { IProject, IProjectSidebar } from 'interfaces/project';
import { useLocation } from 'react-router-dom';
import { setWorkspaceReducer, setResourceTableReducer } from 'redux/reducers/Workspace/actionTypes';
import ModalCreateEditWorkspace from './components/ModalCreateEditWorkspace';
import { setIsLoadingReducer } from 'redux/reducers/Status/actionTypes';
import ApiService from 'services/api_service';
import ApiRoutes from 'configs/apiRoutes';
import ToastService from 'services/toast_service';
import Messages from 'configs/messages';
import { routes } from 'routers/routes';
import useRouteMatch from 'hooks/useRouteMatch';
import clsx from 'clsx';
import { ECreateQueryString, EProjectSettings, ESelectTheme, EStatusCode } from 'configs/enums';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { IPipeline, IResource, IResourceSidebar } from 'interfaces/pipeline';
import ProjectService from 'services/project_service';
import QueryString from 'qs';
import { ALL_OPTION, NULL_VALUE } from 'configs/constant';
import ModalConfirmPublishRevertPipeline from './components/ModalConfirmPublishRevertPipeline';
import ModalDeleteWorkspace from './components/ModalDeleteWorkspace';

interface IQueryString {
  create?: ECreateQueryString;
}

interface HeaderProps {
  className?: string;
  isNewProject?: boolean;
  previewId?: string;
  activePipelineId?: string;
  setResourceSidebar?: React.Dispatch<React.SetStateAction<IResourceSidebar>>;
  isPipelineStages?: boolean;
  isUsers?: boolean;
  isDraftCopied?: React.MutableRefObject<boolean>;
  projectSidebar?: IProjectSidebar;
  setProjectSidebar?: React.Dispatch<React.SetStateAction<IProjectSidebar>>;
  isSaving?: boolean;
  isEmpty?: boolean;
}

const Header: React.FC<HeaderProps> = memo((props: HeaderProps) => {
  const {
    className,
    isNewProject,
    previewId,
    activePipelineId,
    setResourceSidebar,
    isPipelineStages,
    isDraftCopied,
    projectSidebar,
    setProjectSidebar,
    isSaving,
    isEmpty,
  } = props;

  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const {
    isExact: isProjectPage,
    params: { projectId, pipelineId },
  } = useRouteMatch(routes.private.project.default);
  const {
    isExact: isProjectTaskViewPage,
    params: { projectId: projectTaskViewId, pipelineId: pipelineTaskViewId },
  } = useRouteMatch(routes.private.project.taskView);
  const {
    isExact: isCreateProjectPage,
    params: { workspaceId: createProjectWorkspaceId },
  } = useRouteMatch(routes.private.project.create);
  const {
    isExact: isEditProjectPage,
    params: { workspaceId: editProjectWorkspaceId, projectId: editProjectId },
  } = useRouteMatch(routes.private.project.edit);

  const { create }: IQueryString = QueryString.parse(window?.location?.search);
  const queryParams = QueryString.parse(search, { ignoreQueryPrefix: true });

  const isHomePage = pathname === routes.private.home;
  const isUsersPage = pathname === routes.private.users;
  const isAccountPage = pathname === routes.private.account;

  const { workspace, project, pipeline, resourceTable } = useSelector((state: IReducer) => state?.workspace);
  const { workspaceList, projectList, publishedPipelineList, pipelineList, resourceList, resourceSummary } = useSelector(
    (state: IReducer) => state?.information
  );

  const [workspaceOptions, setWorkspaceOptions] = useState<IWorkspaceOption[]>([]);
  const [projectOptions, setProjectOptions] = useState<ISelectOption[]>([]);
  const [pipelineOptions, setPipelineOptions] = useState<ISelectOption[]>([]);
  const [modalCreateEditWorkspace, setModalCreateEditWorkspace] = useState<ICreateEditDeleteWorkspaceModal>({ isOpen: false, data: null });
  const [modalDeleteWorkspace, setModalDeleteWorkspace] = useState<ICreateEditDeleteWorkspaceModal>({ isOpen: false, data: null });
  const [modalConfirmPublishRevertPipeline, setModalConfirmPublishRevertPipeline] = useState<IConfirmPublishRevertPipelineModal>({
    isOpen: false,
    publishPipelineId: null,
  });

  useEffect(() => {
    if (create === ECreateQueryString.Workspace) {
      setModalCreateEditWorkspace({ isOpen: true, data: null });
    }
  }, [create]);

  useEffect(() => {
    if (workspaceList) {
      setWorkspaceOptions(
        workspaceList?.map((workspace: IWorkspace) => {
          return {
            value: workspace?._id,
            label: workspace?.name,
            isShared: workspace?.shared,
          };
        })
      );
    }
  }, [workspaceList]);

  useEffect(() => {
    if (projectList) {
      setProjectOptions(
        projectList?.map((project: IProject) => {
          return {
            value: project?._id,
            label: project?.name,
          };
        })
      );
    }
  }, [projectList]);

  useEffect(() => {
    if (pipelineList?.data) {
      setPipelineOptions(
        pipelineList?.data?.map((pipeline: IPipeline) => {
          return {
            value: pipeline?._id,
            label: pipeline?.name,
          };
        })
      );
    }
  }, [pipelineList]);

  const WorkspaceMenu = useCallback((props: MenuProps) => {
    return (
      <Fragment>
        <components.Menu {...props}>
          {props?.children}
          <div className={classes.addWorkspaceButton}>
            <button onClick={() => setModalCreateEditWorkspace({ isOpen: true, data: null })}>
              <PlusBlackIcon />
            </button>
          </div>
        </components.Menu>
      </Fragment>
    );
  }, []);

  const WorkspaceOption = (props: OptionProps) => {
    const workspace = workspaceList?.find((workspace: IWorkspace) => workspace?._id === (props?.data as IWorkspaceOption)?.value);
    return (
      <components.Option {...props} className={classes.workspaceOption}>
        <p>
          {props?.children}
          <span>{(props?.data as IWorkspaceOption)?.isShared ? '(Shared)' : ''}</span>
        </p>
        <div>
          <EditIcon
            onClick={(event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
              event?.stopPropagation();
              setModalCreateEditWorkspace({
                isOpen: true,
                data: workspace,
              });
            }}
          />
          <DeleteIcon
            onClick={(event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
              event?.stopPropagation();
              if (workspaceList?.length === 1) {
                ToastService.error(Messages.error.deleteWorkspace);
              } else {
                setModalDeleteWorkspace({
                  isOpen: true,
                  data: workspace,
                });
              }
            }}
          />
        </div>
      </components.Option>
    );
  };

  const handleRecentProject = (project: IProject) => {
    // save recent project id and update project list in redux
    ProjectService.setRecentProjectId(project?._id);
    const list: IProject[] = [...(projectList ?? [])];
    const index = list?.findIndex((item: IProject) => item?._id === project?._id);
    if (index !== -1) {
      const recentProject = list?.[index];
      list?.splice(index, 1);
      list?.unshift(recentProject);
    }
    dispatch(setProjectListReducer(list));
  };

  const handlePublishRevertPipeline = () => {
    dispatch(setIsLoadingReducer(true));
    ApiService.POST(ApiRoutes.publishedPipeline.default, {
      pipeline_id: previewId ?? activePipelineId,
      project_id: project?._id,
    })
      .then((response) => {
        const revertedPipeline: IPipeline = response?.entities?.[0];
        dispatch(setPublishedPipelineListReducer({ ...publishedPipelineList, data: [...publishedPipelineList.data, revertedPipeline] }));
        dispatch(setResourceListReducer({ ...resourceList, resourceConfigurations: revertedPipeline?.resource_configurations, isPublished: true }));
        if (!resourceSummary?.isLoading && resourceSummary?.data?._id && resourceSummary?.pipelineId) {
          dispatch(
            getResourceSummaryRequest({ _id: resourceSummary?.data?._id, pipeline_id: resourceSummary?.pipelineId } as IResource, { noSkeleton: true })
          );
        }
        ToastService.success(Messages.success.default);
        onCloseModals();
      })
      .catch((error) => {
        if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
          ToastService.error(Messages.error.accessDenied);
          return;
        }
        console.log(error);
        ToastService.error(Messages.error.default);
      })
      .finally(() => dispatch(setIsLoadingReducer(false)));
  };

  const handleCopyPipeline = () => {
    dispatch(setIsLoadingReducer(true));
    ApiService.PATCH(ApiRoutes.publishedPipeline.copy.replace(':publishedPipelineId', activePipelineId), {
      pipeline_id: previewId,
    })
      .then((response) => {
        const updatedPipeline: IPipeline = response?.entities?.[0];
        dispatch(
          setPipelineListReducer({
            ...pipelineList,
            data: pipelineList?.data?.map((pipeline: IPipeline) => {
              if (pipeline?._id === updatedPipeline?._id) {
                return updatedPipeline;
              }
              return pipeline;
            }),
          })
        );
        isDraftCopied.current = true;
        ToastService.success(Messages.success.copied);
      })
      .catch((error) => {
        if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
          ToastService.error(Messages.error.accessDenied);
          return;
        }
        console.log(error);
        ToastService.error(Messages.error.default);
      })
      .finally(() => dispatch(setIsLoadingReducer(false)));
  };

  const onCloseModals = () => {
    setModalCreateEditWorkspace({ isOpen: false, data: null });
    setModalDeleteWorkspace({ isOpen: false, data: null });
    setModalConfirmPublishRevertPipeline({
      isOpen: false,
      publishPipelineId: null,
    });
  };

  const onCreateEditWorkspace = (data: ICreateEditWorkspaceFormData) => {
    dispatch(setIsLoadingReducer(true));
    if (modalCreateEditWorkspace?.data) {
      ApiService.PATCH(ApiRoutes.workspace.info.replace(':workspaceId', modalCreateEditWorkspace?.data?._id), {
        name: data?.workspaceName,
      })
        .then((response) => {
          dispatch(
            setWorkspaceListReducer(
              workspaceList?.map((workspace: IWorkspace) => {
                if (workspace?._id === modalCreateEditWorkspace?.data?._id) {
                  return response?.entities?.[0];
                }
                return workspace;
              })
            )
          );
          onCloseModals();
          ToastService.success(Messages.success.default);
        })
        .catch((error) => {
          if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
            ToastService.error(Messages.error.accessDenied);
            return;
          }
          console.log(error);
          ToastService.error(Messages.error.default);
        })
        .finally(() => dispatch(setIsLoadingReducer(false)));
    } else {
      ApiService.POST(ApiRoutes.workspace.default, { name: data?.workspaceName })
        .then((response) => {
          const newWorkspace: IWorkspace = response?.entities?.[0];

          onCloseModals();
          ToastService.success(Messages.success.created);

          if (create === ECreateQueryString.Workspace) {
            // this is used to link from desktop app to website
            dispatch(push(routes.private.home));
          }
          dispatch(setProjectListReducer(null));
          dispatch(setArchivedProjectListReducer(null));
          dispatch(setWorkspaceListReducer([...workspaceList, newWorkspace]));
          dispatch(
            push({
              pathname:
                isCreateProjectPage || isEditProjectPage
                  ? pathname
                      .replace(isCreateProjectPage ? createProjectWorkspaceId : editProjectWorkspaceId, newWorkspace?._id ?? NULL_VALUE)
                      .replace(isEditProjectPage ? editProjectId : NULL_VALUE, NULL_VALUE)
                  : pathname,
              search: `&${Object.keys(queryParams)
                .map((key) => {
                  if (key === 'workspaceId') {
                    return `${key}=${newWorkspace?._id ?? NULL_VALUE}`;
                  }
                  return `${key}=${queryParams[key]}`;
                })
                .join('&')}`,
              // search: `&workspaceId=${newWorkspace?._id ?? NULL_VALUE}`,
            })
          );
        })
        .catch((error) => {
          if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
            ToastService.error(Messages.error.accessDenied);
            return;
          }
          console.log(error);
          ToastService.error(Messages.error.default);
        })
        .finally(() => dispatch(setIsLoadingReducer(false)));
    }
  };

  const onDeleteWorkspace = () => {
    dispatch(setIsLoadingReducer(true));
    ApiService.DELETE(ApiRoutes.workspace.info.replace(':workspaceId', modalDeleteWorkspace?.data?._id))
      .then(() => {
        const newWorkspaceList: IWorkspace[] = workspaceList?.filter((workspace: IWorkspace) => workspace?._id !== modalDeleteWorkspace?.data?._id);

        dispatch(setWorkspaceListReducer(newWorkspaceList));
        onCloseModals();
        ToastService.success(Messages.success.deleted);

        if (workspace?._id === modalDeleteWorkspace?.data?._id) {
          dispatch(setProjectListReducer(null));
          dispatch(setArchivedProjectListReducer(null));
          dispatch(
            push({
              pathname:
                isCreateProjectPage || isEditProjectPage
                  ? pathname
                      .replace(isCreateProjectPage ? createProjectWorkspaceId : editProjectWorkspaceId, newWorkspaceList?.[0]?._id ?? NULL_VALUE)
                      .replace(isEditProjectPage ? editProjectId : NULL_VALUE, NULL_VALUE)
                  : pathname,
              search: `&${Object.keys(queryParams)
                .map((key) => {
                  if (key === 'workspaceId') {
                    return `${key}=${newWorkspaceList?.[0]?._id ?? NULL_VALUE}`;
                  }
                  return `${key}=${queryParams[key]}`;
                })
                .join('&')}`,
              // search: `&workspaceId=${newWorkspaceList?.[0]?._id ?? NULL_VALUE}`,
            })
          );
        }
      })
      .catch((error) => {
        if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
          ToastService.error(Messages.error.accessDenied);
          return;
        }
        console.log(error);
        ToastService.error(Messages.error.default);
      })
      .finally(() => dispatch(setIsLoadingReducer(false)));
  };

  return (
    <div className={clsx(classes.container, className, { [classes.isPipelineStages]: isPipelineStages })}>
      <div className={classes.headerLeft}>
        {isHomePage || isCreateProjectPage || isEditProjectPage ? (
          workspaceList ? (
            workspace ? (
              <Select
                customTheme={isCreateProjectPage || isEditProjectPage ? ESelectTheme.Header : ESelectTheme.Gradient}
                value={{ value: workspace?._id, label: workspace?.name }}
                options={workspaceOptions}
                onChange={(option: any) => {
                  dispatch(setProjectListReducer(null));
                  dispatch(setArchivedProjectListReducer(null));
                  dispatch(setWorkspaceReducer(workspaceList?.find((workspace: IWorkspace) => workspace?._id === option?.value)));
                  dispatch(
                    push({
                      pathname:
                        isCreateProjectPage || isEditProjectPage
                          ? pathname
                              .replace(isCreateProjectPage ? createProjectWorkspaceId : editProjectWorkspaceId, option?.value ?? NULL_VALUE)
                              .replace(isEditProjectPage ? editProjectId : NULL_VALUE, NULL_VALUE)
                          : pathname,
                      search: `&${Object.keys(queryParams)
                        .map((key) => {
                          if (key === 'workspaceId') {
                            return `${key}=${option?.value ?? NULL_VALUE}`;
                          }
                          return `${key}=${queryParams[key]}`;
                        })
                        .join('&')}`,
                      // search: `&workspaceId=${option?.value ?? NULL_VALUE}`,
                    })
                  );
                }}
                components={{
                  Menu: WorkspaceMenu,
                  Option: WorkspaceOption,
                }}
              />
            ) : (
              <p className={classes.selectAlt}>No workspace found</p>
            )
          ) : (
            <Skeleton variant="rectangular" width={206} height={38} sx={{ borderRadius: '4px', background: 'var(--backgroundLight)' }} />
          )
        ) : null}

        {isProjectPage || isProjectTaskViewPage || isCreateProjectPage || isEditProjectPage ? (
          isNewProject ? (
            <p className={classes.selectAlt}>New project</p>
          ) : projectList ? (
            project ? (
              <Fragment>
                <Select
                  customTheme={ESelectTheme.Header}
                  value={{ value: project?._id, label: project?.name }}
                  options={projectOptions}
                  onChange={(option) => {
                    const selectedProject = projectList?.find((project: IProject) => project?._id === (option as ISelectOption)?.value);

                    handleRecentProject(selectedProject);

                    projectSidebar?.isOpen && setProjectSidebar({ isOpen: false, data: null });

                    dispatch(
                      setResourceTableReducer({
                        searchKey: '',
                        priority: ALL_OPTION,
                        users: [ALL_OPTION],
                        status: ALL_OPTION,
                        page: 1,
                        limit: resourceTable?.limit,
                        totalPages: resourceTable?.totalPages,
                        column: resourceTable?.column,
                        direction: resourceTable?.direction,
                      })
                    );

                    dispatch(
                      push({
                        pathname: pathname
                          ?.replace(isProjectPage ? projectId : isProjectTaskViewPage ? projectTaskViewId : editProjectId, selectedProject?._id)
                          ?.replace(
                            isProjectPage ? pipelineId : pipelineTaskViewId,
                            selectedProject?.pipeline_ids?.length ? selectedProject?.pipeline_ids?.[0] : null
                          ),
                        search,
                      })
                    );
                  }}
                />
                {isPipelineStages ? (
                  <SettingIcon
                    className={classes.settingIcon}
                    onClick={() => {
                      setProjectSidebar({ isOpen: true, data: null });
                    }}
                  />
                ) : null}
              </Fragment>
            ) : (
              <p className={classes.selectAlt}>No projects found</p>
            )
          ) : (
            <Skeleton variant="rectangular" width={206} height={40} sx={{ borderRadius: '8px', background: 'var(--backgroundLight)' }} />
          )
        ) : null}

        {isProjectPage || isProjectTaskViewPage ? (
          <Fragment>
            <div className={classes.verticalLine} />
            {pipelineList?.data ? (
              pipeline ? (
                <Select
                  customTheme={ESelectTheme.Header}
                  value={{ value: pipeline?._id, label: pipeline?.name }}
                  options={pipelineOptions}
                  onChange={(option) => {
                    const selectedPipeline = pipelineList?.data?.find((pipeline: IPipeline) => pipeline?._id === (option as ISelectOption)?.value);
                    dispatch(
                      push({
                        pathname: pathname?.replace(isProjectPage ? pipelineId : pipelineTaskViewId, selectedPipeline?._id),
                        search,
                      })
                    );
                  }}
                />
              ) : (
                <p className={classes.selectAlt}>No pipelines found</p>
              )
            ) : (
              <Skeleton variant="rectangular" width={206} height={40} sx={{ borderRadius: '8px', background: 'var(--backgroundLight)' }} />
            )}
            <SettingIcon
              className={classes.settingIcon}
              onClick={() =>
                dispatch(
                  push({
                    pathname: routes.private.project.edit.replace(':workspaceId', workspace?._id).replace(':projectId', project?._id),
                    search: `&name=${encodeURIComponent(project?.name)}&type=${EProjectSettings.Pipeline}&activePipelineId=${
                      pipeline?._id ?? NULL_VALUE
                    }&workspaceId=${workspace?._id ?? NULL_VALUE}`,
                  })
                )
              }
            />
          </Fragment>
        ) : null}

        {isUsersPage ? <p className={classes.title}>User Management</p> : null}

        {isAccountPage ? <p className={classes.title}>Your Profile</p> : null}
      </div>

      <div className={classes.headerRight}>
        {isProjectPage || isProjectTaskViewPage ? (
          <Fragment>
            {/* <div className={classes.promptInput}>
              <input type="text" placeholder="Assign all models to random modellers" />
              <MagicIcon />
            </div> */}
            {isProjectTaskViewPage ? (
              resourceList?.isPublished ? (
                <Fragment>
                  {project ? (
                    <PrimaryButton onClick={() => setResourceSidebar({ isOpen: true, data: null })}>Add Resource</PrimaryButton>
                  ) : (
                    <Skeleton variant="rectangular" width={101.7} height={37} sx={{ borderRadius: '4px', background: 'var(--backgroundLight)' }} />
                  )}
                  {pipeline || !pipelineList?.data?.length ? (
                    <div
                      className={classes.resourceViewButton}
                      onClick={() =>
                        dispatch(
                          push({
                            pathname: routes.private.project.default
                              .replace(':projectId', projectTaskViewId)
                              .replace(':pipelineId', !pipelineList?.data?.length ? null : pipeline?._id),
                            search: `&workspaceId=${workspace?._id ?? NULL_VALUE}`,
                          })
                        )
                      }
                    >
                      <p>Resource view</p>
                      <CollapseIcon />
                    </div>
                  ) : (
                    <Skeleton variant="rectangular" width={156.68} height={37} sx={{ borderRadius: '4px', background: 'var(--backgroundLight)' }} />
                  )}
                </Fragment>
              ) : null
            ) : null}
          </Fragment>
        ) : previewId ? (
          <div className={classes.pipelineButtons}>
            <div>
              {publishedPipelineList?.data?.slice(-1)?.[0]?._id === previewId ? (
                <OverlayTrigger placement="auto" overlay={<Tooltip>Selected pipeline is currently live already.</Tooltip>}>
                  {({ ref, ...triggerHandler }) => (
                    <div ref={ref} {...triggerHandler}>
                      <div className={classes.disabled}>Revert</div>
                    </div>
                  )}
                </OverlayTrigger>
              ) : (
                <div
                  className={classes.red}
                  onClick={() =>
                    setModalConfirmPublishRevertPipeline({
                      isOpen: true,
                      publishPipelineId: null,
                    })
                  }
                >
                  Revert
                </div>
              )}
              <div className={classes.gradient} onClick={handleCopyPipeline}>
                Copy
              </div>
            </div>
            <p>Copy to make changes</p>
          </div>
        ) : activePipelineId && activePipelineId !== NULL_VALUE && isPipelineStages && project?.pipeline_ids?.length ? (
          publishedPipelineList?.data ? (
            <div className={classes.pipelineButtons}>
              <div className="mb-0">
                <div
                  className={classes.red}
                  onClick={() => {
                    if (
                      !pipelineList?.data?.find((pipeline: IPipeline) => pipeline?._id === activePipelineId)?.resource_configurations?.length ||
                      isEmpty
                    ) {
                      ToastService.error(Messages.error.emptyPipeline);
                    } else if (isSaving) {
                      ToastService.info(Messages.info.savingPipeline);
                    } else {
                      setModalConfirmPublishRevertPipeline({
                        isOpen: true,
                        publishPipelineId: activePipelineId,
                      });
                    }
                  }}
                >
                  Publish changes
                </div>
              </div>
            </div>
          ) : (
            <Skeleton variant="rectangular" width={159.29} height={36} sx={{ borderRadius: '4px', background: 'var(--backgroundLight)' }} />
          )
        ) : null}
      </div>

      <ModalCreateEditWorkspace
        isOpen={modalCreateEditWorkspace?.isOpen}
        data={modalCreateEditWorkspace?.data}
        onClose={onCloseModals}
        onSubmit={onCreateEditWorkspace}
      />

      <ModalDeleteWorkspace
        isOpen={modalDeleteWorkspace?.isOpen}
        name={modalDeleteWorkspace?.data?.name}
        onClose={onCloseModals}
        onSubmit={onDeleteWorkspace}
      />

      <ModalConfirmPublishRevertPipeline
        isOpen={modalConfirmPublishRevertPipeline?.isOpen}
        publishPipelineId={modalConfirmPublishRevertPipeline?.publishPipelineId}
        onClose={onCloseModals}
        onSubmit={handlePublishRevertPipeline}
      />
    </div>
  );
});

export default Header;
