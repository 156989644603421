import ApiRoutes from 'configs/apiRoutes';
import { EStatusCode } from 'configs/enums';
import Messages from 'configs/messages';
import dayjs from 'dayjs';
import { IProject } from 'interfaces/project';
import { put, takeLatest, call } from 'redux-saga/effects';
import { GET_PROJECT_LIST_REQUEST, setProjectListReducer } from 'redux/reducers/Information/actionTypes';
import { setIsLoadingReducer } from 'redux/reducers/Status/actionTypes';
import ApiService from 'services/api_service';
import ProjectService from 'services/project_service';
import ToastService from 'services/toast_service';

function* requestGetProjectList(data: { type: string; workspaceId: string }) {
  try {
    yield put(setIsLoadingReducer(true));
    const recentProjectId = ProjectService.getRecentProjectId();
    const response = yield call(ApiService.GET, ApiRoutes.project.default, { filter_workspace_id: data?.workspaceId });
    const list: IProject[] = [
      ...(response?.entities?.sort((a: IProject, b: IProject) => {
        const dateA = dayjs(a?.updatedAt);
        const dateB = dayjs(b?.updatedAt);
        return dateB?.diff(dateA);
      }) ?? []),
    ];
    const index = list?.findIndex((item: IProject) => item?._id === recentProjectId);
    if (index !== -1) {
      const recentProject = list?.[index];
      list?.splice(index, 1);
      list?.unshift(recentProject);
    }
    yield put(setProjectListReducer(list ?? []));
  } catch (error: any) {
    if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
      yield ToastService.error(Messages.error.accessDenied);
    } else {
      yield console.log(error);
      yield ToastService.error(Messages.error.default);
    }
  } finally {
    yield put(setIsLoadingReducer(false));
  }
}

function* getProjectList() {
  yield takeLatest(GET_PROJECT_LIST_REQUEST, requestGetProjectList);
}

export default getProjectList;
