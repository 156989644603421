import { memo } from 'react';
import classes from './styles.module.scss';
import clsx from 'clsx';

interface GalaxyBackgroundProps {
  children: React.ReactNode;
  className?: string;
}

const GalaxyBackground: React.FC<GalaxyBackgroundProps> = memo((props: GalaxyBackgroundProps) => {
  const { children, className } = props;

  return (
    <div className={clsx(classes.container, className)}>
      <div className={classes.stars1} />
      <div className={classes.stars2} />
      <div className={classes.stars3} />
      <div className={classes.content}>{children}</div>
    </div>
  );
});

export default GalaxyBackground;
