import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import classes from './styles.module.scss';
import { memo, useEffect, useState } from 'react';
import { ICollabUser } from 'interfaces/user';
import { PlusBlackIcon } from 'assets';
import ModalInviteUser from 'components/ModalInviteUser';
import { useDispatch, useSelector } from 'react-redux';
import { setIsLoadingReducer } from 'redux/reducers/Status/actionTypes';
import ApiService from 'services/api_service';
import ApiRoutes from 'configs/apiRoutes';
import { IReducer } from 'redux/reducers';
import Messages from 'configs/messages';
import ToastService from 'services/toast_service';
import CommonService from 'services/common_service';
import { Skeleton } from '@mui/material';
import { IProjectUrlParams } from 'interfaces/project';
import { useParams } from 'react-router-dom';
import { EStatusCode } from 'configs/enums';

const userSkeletonCount = 5;

interface UserPopoverProps {
  anchorElement: HTMLElement;
  onClose: () => void;
  onSelect: (user: ICollabUser) => void;
}

const UserPopover = memo((props: UserPopoverProps) => {
  const { anchorElement, onClose, onSelect } = props;

  const dispatch = useDispatch();
  const { projectId } = useParams<IProjectUrlParams>();

  const { projectCollabUserList, user } = useSelector((state: IReducer) => state?.user);
  const { project } = useSelector((state: IReducer) => state?.workspace);

  const [userList, setUserList] = useState<ICollabUser[]>(null);
  const [isOpenModalInviteUser, setIsOpenModalInviteUser] = useState<boolean>(false);

  useEffect(() => {
    if (projectCollabUserList?.projectId === projectId && user) {
      setUserList([
        {
          email: user?.email,
          first_name: user?.first_name,
          last_name: user?.last_name,
          workspace_ids: user?.workspace_ids,
          project_ids: user?.project_ids,
          _id: user?._id,
          profile_image_url: user?.profile_image_url,
        },
        ...(projectCollabUserList?.data?.slice()?.sort((a: ICollabUser, b: ICollabUser) => {
          const nameA = a?.first_name?.toUpperCase();
          const nameB = b?.first_name?.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        }) ?? []),
      ]);
    } else {
      setUserList(null);
    }
  }, [projectCollabUserList, user]);

  const onSearchUser = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchKey = event?.target?.value?.trim().toLowerCase();
    if (searchKey) {
      setUserList(
        userList?.filter((user: ICollabUser) => {
          const fullName = `${user?.first_name ?? ''} ${user?.last_name ?? ''}`.toLowerCase();
          return fullName.includes(searchKey);
        })
      );
    } else {
      setUserList([
        {
          email: user?.email,
          first_name: user?.first_name,
          last_name: user?.last_name,
          workspace_ids: user?.workspace_ids,
          project_ids: user?.project_ids,
          _id: user?._id,
          profile_image_url: user?.profile_image_url,
        },
        ...(projectCollabUserList?.data ?? []),
      ]);
    }
  };

  const onAddUser = (emailList: string[]) => {
    dispatch(setIsLoadingReducer(true));
    ApiService.POST(ApiRoutes.invite.project, {
      to_emails: [...emailList],
      project_id: project?._id,
    })
      .then(() => {
        ToastService.success(Messages.success.invited);
        onCloseModals();
      })
      .catch((error) => {
        if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
          ToastService.error(Messages.error.accessDenied);
          return;
        }
        console.log(error);
        ToastService.error(Messages.error.default);
      })
      .finally(() => dispatch(setIsLoadingReducer(false)));
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorElement?.contains(event?.target as HTMLElement)) {
      return;
    }
    onClose();
  };

  const onCloseModals = () => {
    setIsOpenModalInviteUser(false);
  };

  return (
    <Popper
      open={!!anchorElement}
      anchorEl={anchorElement}
      className={classes.popover}
      role={undefined}
      transition
      placement="top"
      disablePortal
      modifiers={[
        {
          name: 'preventOverflow',
          enabled: true,
          options: {
            altAxis: true,
            altBoundary: true,
            tether: true,
            rootBoundary: 'html',
          },
        },
      ]}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper className={classes.paper}>
            <ClickAwayListener onClickAway={handleClose}>
              <div className={classes.menu}>
                <input className={classes.searchInput} type="text" placeholder="Search" onChange={onSearchUser} />

                <div className={classes.userList}>
                  {userList
                    ? userList?.map((user: ICollabUser) => {
                        return (
                          <div
                            key={`user-${user?._id}`}
                            className={classes.user}
                            onClick={() => {
                              onSelect(user);
                              onClose();
                            }}
                          >
                            <img src={user?.profile_image_url} alt="Avatar" />
                            <p>{CommonService.getFullName(user)}</p>
                          </div>
                        );
                      })
                    : [...Array(userSkeletonCount).keys()]?.map((index) => {
                        return (
                          <Skeleton
                            key={`user-skeleton-${index}`}
                            variant="rectangular"
                            width={195}
                            height={24}
                            sx={{ borderRadius: '8px', margin: '0 auto 20px', background: 'var(--backgroundLight)' }}
                          />
                        );
                      })}
                </div>

                <button className={classes.addUserButton} onClick={() => setIsOpenModalInviteUser(true)}>
                  <PlusBlackIcon />
                </button>

                <ModalInviteUser isOpen={isOpenModalInviteUser} onClose={onCloseModals} onSubmit={onAddUser} />
              </div>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
});

export default UserPopover;
