import { EResourceColumns, ESortDirection } from 'configs/enums';
import { ISelectOption } from 'interfaces/common';
import { IWorkspace } from 'interfaces/workspace';
import {
  IPipeline,
  IPublishedPipelineListState,
  IPipelineListState,
  IResourceListState,
  IResourceSummaryState,
  IResource,
  IResourceConfiguration,
  IGlobalAppConfiguration,
  IResourceMediaState,
  IResourceId
} from 'interfaces/pipeline';
import { IProject } from 'interfaces/project';

export const GET_WORKSPACE_LIST_REQUEST = 'GET_WORKSPACE_LIST_REQUEST';
export const SET_WORKSPACE_LIST_REDUCER = 'SET_WORKSPACE_LIST_REDUCER';

export const GET_PROJECT_LIST_REQUEST = 'GET_PROJECT_LIST_REQUEST';
export const SET_PROJECT_LIST_REDUCER = 'SET_PROJECT_LIST_REDUCER';

export const GET_ARCHIVED_PROJECT_LIST_REQUEST = 'GET_ARCHIVED_PROJECT_LIST_REQUEST';
export const SET_ARCHIVED_PROJECT_LIST_REDUCER = 'SET_ARCHIVED_PROJECT_LIST_REDUCER';

export const GET_PROJECT_TEMPLATES_REQUEST = 'GET_PROJECT_TEMPLATES_REQUEST';
export const SET_PROJECT_TEMPLATES_REDUCER = 'SET_PROJECT_TEMPLATES_REDUCER';

export const GET_PIPELINE_TEMPLATES_REQUEST = 'GET_PIPELINE_TEMPLATES_REQUEST';
export const SET_PIPELINE_TEMPLATES_REDUCER = 'SET_PIPELINE_TEMPLATES_REDUCER';

export const GET_PUBLISHED_PIPELINE_LIST_REQUEST = 'GET_PUBLISHED_PIPELINE_LIST_REQUEST';
export const SET_PUBLISHED_PIPELINE_LIST_REDUCER = 'SET_PUBLISHED_PIPELINE_LIST_REDUCER';

export const GET_PIPELINE_LIST_REQUEST = 'GET_PIPELINE_LIST_REQUEST';
export const SET_PIPELINE_LIST_REDUCER = 'SET_PIPELINE_LIST_REDUCER';

export const GET_RESOURCE_LIST_REQUEST = 'GET_RESOURCE_LIST_REQUEST';
export const SET_RESOURCE_LIST_REDUCER = 'SET_RESOURCE_LIST_REDUCER';

export const GET_RESOURCE_SUMMARY_REQUEST = 'GET_RESOURCE_SUMMARY_REQUEST';
export const SET_RESOURCE_SUMMARY_REDUCER = 'SET_RESOURCE_SUMMARY_REDUCER';

export const GET_RESOURCE_MEDIA_REQUEST = 'GET_RESOURCE_MEDIA_REQUEST';
export const SET_RESOURCE_MEDIA_REDUCER = 'SET_RESOURCE_MEDIA_REDUCER';

export const GET_GLOBAL_APP_CONFIGURATIONS_REQUEST = 'GET_GLOBAL_APP_CONFIGURATIONS_REQUEST';
export const SET_GLOBAL_APP_CONFIGURATIONS_REDUCER = 'SET_GLOBAL_APP_CONFIGURATIONS_REDUCER';

export const SET_LOGOUT_INFORMATION_REDUCER = 'SET_LOGOUT_INFORMATION_REDUCER';

export const SET_RESOURCE_REDUCER = 'SET_RESOURCE_REDUCER';

export const getWorkspaceListRequest = () => {
  return {
    type: GET_WORKSPACE_LIST_REQUEST,
  };
};
export const setWorkspaceListReducer = (data: IWorkspace[]) => {
  return {
    type: SET_WORKSPACE_LIST_REDUCER,
    data,
  };
};

export const getProjectListRequest = (workspaceId: string) => {
  return {
    type: GET_PROJECT_LIST_REQUEST,
    workspaceId,
  };
};
export const setProjectListReducer = (data: IProject[]) => {
  return {
    type: SET_PROJECT_LIST_REDUCER,
    data,
  };
};

export const getArchivedProjectListRequest = (workspaceId: string) => {
  return {
    type: GET_ARCHIVED_PROJECT_LIST_REQUEST,
    workspaceId,
  };
};
export const setArchivedProjectListReducer = (data: IProject[]) => {
  return {
    type: SET_ARCHIVED_PROJECT_LIST_REDUCER,
    data,
  };
};

export const getProjectTemplatesRequest = () => {
  return {
    type: GET_PROJECT_TEMPLATES_REQUEST,
  };
};
export const setProjectTemplatesReducer = (data: IProject[]) => {
  return {
    type: SET_PROJECT_TEMPLATES_REDUCER,
    data,
  };
};

export const getPipelineTemplatesRequest = () => {
  return {
    type: GET_PIPELINE_TEMPLATES_REQUEST,
  };
};
export const setPipelineTemplatesReducer = (data: IPipeline[]) => {
  return {
    type: SET_PIPELINE_TEMPLATES_REDUCER,
    data,
  };
};

export const getPublishedPipelineListRequest = (pipelineId: string) => {
  return {
    type: GET_PUBLISHED_PIPELINE_LIST_REQUEST,
    pipelineId,
  };
};
export const setPublishedPipelineListReducer = (data: IPublishedPipelineListState) => {
  return {
    type: SET_PUBLISHED_PIPELINE_LIST_REDUCER,
    data,
  };
};

export const getPipelineListRequest = (projectId: string) => {
  return {
    type: GET_PIPELINE_LIST_REQUEST,
    projectId,
  };
};
export const setPipelineListReducer = (data: IPipelineListState) => {
  return {
    type: SET_PIPELINE_LIST_REDUCER,
    data,
  };
};

export const getResourceListRequest = (
  projectId: string,
  pipelineId: string,
  searchKey: string,
  priority: ISelectOption,
  users: ISelectOption[],
  status: ISelectOption,
  page: number,
  limit: number,
  totalPages: number,
  sortColumn: EResourceColumns,
  sortDirection: ESortDirection,
  noSkeleton: boolean,
  resourceConfigurations?: IResourceConfiguration[],
  startDate?: string,
  endDate?: string
) => {
  return {
    type: GET_RESOURCE_LIST_REQUEST,
    projectId,
    pipelineId,
    searchKey,
    priority,
    users,
    status,
    page,
    limit,
    totalPages,
    sortColumn,
    sortDirection,
    noSkeleton,
    resourceConfigurations,
    startDate,
    endDate
  };
};
export const setResourceListReducer = (data: IResourceListState) => {
  return {
    type: SET_RESOURCE_LIST_REDUCER,
    data,
  };
};

export const getResourceSummaryRequest = (resource: IResource, options?: { noSkeleton?: boolean; loading?: boolean }) => {
  return {
    type: GET_RESOURCE_SUMMARY_REQUEST,
    resource,
    options,
  };
};
export const setResourceSummaryReducer = (resourceSummary: IResourceSummaryState) => {
  return {
    type: SET_RESOURCE_SUMMARY_REDUCER,
    data: resourceSummary,
  };
};

export const getResourceMediaRequest = (resourceId: string, options?: { noSkeleton?: boolean; loading?: boolean }) => {
  return {
    type: GET_RESOURCE_MEDIA_REQUEST,
    resourceId,
    options,
  };
};
export const setResourceMediaReducer = (resourceMedia: IResourceMediaState) => {
  return {
    type: SET_RESOURCE_MEDIA_REDUCER,
    data: resourceMedia,
  };
};

export const getGlobalAppConfigurationsRequest = () => {
  return {
    type: GET_GLOBAL_APP_CONFIGURATIONS_REQUEST,
  };
};
export const setGlobalAppConfigurationsReducer = (data: IGlobalAppConfiguration[]) => {
  return {
    type: SET_GLOBAL_APP_CONFIGURATIONS_REDUCER,
    data,
  };
};

export const setLogoutInformationReducer = () => {
  return {
    type: SET_LOGOUT_INFORMATION_REDUCER,
  };
};

export const setResourceReducer = (data: IResourceId) => {
  return {
    type: SET_RESOURCE_REDUCER,
    data,
  };
};
