import { memo, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import SecondaryButton from 'components/Buttons/SecondaryButton';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import classes from './styles.module.scss';
import { ICreatePipelineFormData, IPipeline } from 'interfaces/pipeline';
import Input from 'components/Input';
import Select from 'components/Select';
import { ESelectTheme } from 'configs/enums';
import { NULL_OPTION } from 'configs/constant';
import { ISelectOption } from 'interfaces/common';
import Regexes from 'configs/regexes';
import { IReducer } from 'redux/reducers';
import { useSelector } from 'react-redux';
import { ITaskConfigurationStatus } from 'interfaces/project';
import clsx from 'clsx';

interface ModalCreatePipelineProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: ICreatePipelineFormData) => void;
}

const ModalCreatePipeline = memo((props: ModalCreatePipelineProps) => {
  const { isOpen, onClose, onSubmit } = props;

  const { pipelineTemplates } = useSelector((state: IReducer) => state?.information);

  const [pipelineTemplateOptions, setPipelineTemplateOptions] = useState<ISelectOption[]>([]);
  const [pipelineTemplateDescription, setPipelineTemplateDescription] = useState<IPipeline>(null);

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      pipelineName: Yup.string()
        .required('This field is required.')
        .matches(Regexes.configurationName, 'Only alphanumeric characters, underscores, and dashes are allowed.'),
      selectedPipelineTemplate: Yup.object(),
    });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
  } = useForm<ICreatePipelineFormData>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const selectedPipelineTemplate = watch('selectedPipelineTemplate');

  useEffect(() => {
    if (!isOpen) {
      reset({ pipelineName: '' });
      setPipelineTemplateDescription(null);
    }
  }, [isOpen]);

  useEffect(() => {
    if (pipelineTemplates) {
      setPipelineTemplateOptions([
        NULL_OPTION,
        ...pipelineTemplates.map((item: IPipeline) => {
          return {
            value: item?._id,
            label: item?.name,
          };
        }),
      ]);
    }
  }, [pipelineTemplates]);

  useEffect(() => {
    if (selectedPipelineTemplate && pipelineTemplates?.length) {
      setPipelineTemplateDescription(pipelineTemplates?.find((template: IPipeline) => template?._id === selectedPipelineTemplate?.value) ?? null);
    }
  }, [selectedPipelineTemplate, pipelineTemplates]);

  return (
    <Dialog id="modal-create-pipeline" transitionDuration={0.3} open={isOpen} onClose={onClose} className={classes.container}>
      <DialogTitle>Create pipeline</DialogTitle>

      <DialogContent>
        <form id="pipeline" onSubmit={handleSubmit(onSubmit)}>
          <Input
            label="Pipeline name"
            placeholder="Pipeline name"
            inputRef={register('pipelineName')}
            autoComplete="off"
            autoFocus={true}
            errorMessage={errors?.pipelineName?.message}
          />

          <Select
            name="selectedPipelineTemplate"
            customTheme={ESelectTheme.Form}
            label="Pipeline template"
            options={pipelineTemplateOptions}
            defaultValue={NULL_OPTION}
            control={control}
            className={pipelineTemplateDescription ? 'mb-4' : 'mb-0'}
            classNamePrefix="selectPipelineTemplate"
          />

          {pipelineTemplateDescription ? (
            <div className={classes.pipelineTemplateDescriptionContainer}>
              <label>Template description</label>
              <div className={classes.pipelineTemplateDescription}>
                {pipelineTemplateDescription?.description ? (
                  <p className={clsx(classes.description, 'mb-3')}>{pipelineTemplateDescription?.description}</p>
                ) : null}

                {pipelineTemplateDescription?.inherit_parent_task_configuration ? (
                  <div className={classes.statuses}>
                    <label>Statuses</label>
                    <p className={clsx(classes.description, 'mb-0')}>Inherited from project.</p>
                  </div>
                ) : pipelineTemplateDescription?.task_configuration?.statuses?.length ? (
                  <div className={classes.statuses}>
                    <label>Statuses</label>
                    <div>
                      {pipelineTemplateDescription?.task_configuration?.statuses?.map((status: ITaskConfigurationStatus, statusIndex: number) => {
                        return status?.name ? (
                          <div key={`status-${statusIndex}`} style={{ borderColor: `#${status?.color}` }}>
                            {status?.name}
                          </div>
                        ) : null;
                      })}
                    </div>
                  </div>
                ) : null}

                {pipelineTemplateDescription?.inherit_parent_app_configuration ? (
                  <div className={classes.chip}>
                    <label>Apps</label>
                    <p className={clsx(classes.description, 'mb-0')}>Inherited from project.</p>
                  </div>
                ) : Object.keys(pipelineTemplateDescription?.app_configuration)?.length ? (
                  <div className={classes.chip}>
                    <label>Apps</label>
                    <div>
                      {Object.keys(pipelineTemplateDescription?.app_configuration)?.map((appName: string) => {
                        return <div key={appName}>{appName}</div>;
                      })}
                    </div>
                  </div>
                ) : null}

                {pipelineTemplateDescription?.tags?.length ? (
                  <div className={classes.chip}>
                    <label>Tags</label>
                    <div>
                      {pipelineTemplateDescription?.tags?.map((tag: string) => {
                        return <div key={tag}>{tag}</div>;
                      })}
                    </div>
                  </div>
                ) : null}

                <p className={clsx(classes.description, 'mb-0')}>You are able to completely customize any aspect of your project configuration.</p>
              </div>
            </div>
          ) : null}
        </form>
      </DialogContent>

      <DialogActions>
        <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
        <PrimaryButton type="submit" form="pipeline">
          Submit
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
});

export default ModalCreatePipeline;
