const Regexes = {
  email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  password: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/,
  phoneNumber: /(?:([+]\d{1,4})[-.\s]?)?(?:[(](\d{1,3})[)][-.\s]?)?(\d{1,4})[-.\s]?(\d{1,4})[-.\s]?(\d{1,9})/g,
  workspaceName: /^[a-zA-Z0-9._ -]*$/,
  configurationName: /^[a-zA-Z0-9_-]*$/,
  api: {
    latestPublishedPipeline: /\/published-pipeline-latest\/[0-9a-f]+/,
  },
};

export default Regexes;
