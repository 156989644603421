import ApiRoutes from 'configs/apiRoutes';
import { EProjectUserColumns, ESortDirection, EStatusCode } from 'configs/enums';
import Messages from 'configs/messages';
import { put, takeLatest, call } from 'redux-saga/effects';
import { setIsLoadingReducer } from 'redux/reducers/Status/actionTypes';
import { GET_PROJECT_COLLAB_USER_LIST_REQUEST, setProjectCollabUserListReducer } from 'redux/reducers/User/actionTypes';
import ApiService from 'services/api_service';
import ToastService from 'services/toast_service';

function* requestGetProjectCollabUserList(data: { type: string; projectId: string; sortBy: EProjectUserColumns; sortDirection: ESortDirection }) {
  try {
    yield put(setIsLoadingReducer(true));
    const response = yield call(ApiService.GET, ApiRoutes.user.management, {
      filter_project_id: data?.projectId,
      sortBy: data?.sortBy,
      sortDirection: data?.sortDirection,
    });
    yield put(setProjectCollabUserListReducer({ projectId: data?.projectId, data: response?.entities ?? [] }));
  } catch (error: any) {
    if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
      yield ToastService.error(Messages.error.accessDenied);
    } else {
      yield console.log(error);
      yield ToastService.error(Messages.error.default);
    }
  } finally {
    yield put(setIsLoadingReducer(false));
  }
}

function* getProjectCollabUserList() {
  yield takeLatest(GET_PROJECT_COLLAB_USER_LIST_REQUEST, requestGetProjectCollabUserList);
}

export default getProjectCollabUserList;
