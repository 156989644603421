const ApiRoutes = {
  auth: {
    login: '/login',
    registerCheck: '/register-check',
    register: '/register',
    reCaptcha: '/recaptcha-verify',
    changePassword: '/change-password',
    forgotPassword: '/forgot-password',
    sso: {
      googleLogin: '/google-sso-auth',
      linkedinLogin: 'linkedin-sso-auth',
    },
    refreshToken: '/refresh-token',
  },
  project: {
    default: '/projects',
    defaultArchived: '/archive-projects',
    info: '/projects/:projectId',
    titleImages: '/images/project/:projectId',
    archive: '/projects/:projectId/archive',
    unarchive: '/projects/:projectId/unarchive',
    delete: '/project-delete/:projectId?project_name=:projectName',
    templates: '/project-templates',
    appConfig: {
      default: '/app-configurations/project/:projectId',
      info: '/app-configurations/project/:projectId/:appName',
      global: '/app-configurations/global',
    },
    taskConfig: {
      default: '/task-configurations/project/:projectId',
    },
  },
  workspace: {
    default: '/workspaces',
    info: '/workspaces/:workspaceId',
  },
  invite: {
    project: '/invites/project',
    acceptInvitation: '/invites/confirm-project-invite/:token',
  },
  publishedPipeline: {
    default: '/published-pipelines',
    info: '/published-pipelines/:publishedPipelineId',
    copy: '/pipeline-copy/:publishedPipelineId',
    latest: '/published-pipeline-latest/:pipelineId',
  },
  pipeline: {
    default: '/pipelines',
    info: '/pipelines/:pipelineId',
    templates: '/pipeline-templates',
    appConfig: {
      default: '/app-configurations/pipeline/:pipelineId',
      info: '/app-configurations/pipeline/:pipelineId/:appName',
    },
    taskConfig: {
      default: '/task-configurations/pipeline/:pipelineId',
    },
  },
  resourceConfig: {
    bulk: '/bulk-resource-configs/:pipelineId',
  },
  resource: {
    default: '/resources',
    info: '/resources/:resourceId',
    list: '/resource-tasks-search/:projectId/:searchKey',
    taskInfo: '/resource-tasks/:resourceTaskId',
    summary: '/resource-summaries/:resourceId',
    media: {
      default: '/resource-media',
      list: '/resource-media?filter_resource_id=:resourceId',
      delete: '/resource-media/:resourceMediaId',
    },
    signedUrl: '/media'
  },
  user: {
    avatar: '/images/user/:userId',
    management: '/collab-users',
    role: '/entity-roles/:entityType/:entityId',
    remove: '/collab-users/:userId/project/:projectId',
  },
  desktopApp: {
    default: '/runnables/:os',
  },
  review: {
    default: '/reviews',
    info: '/reviews?filter_resource_media_id=:resourceMediaId&filter_is_resolved=:isResolved',
    update: '/reviews/:reviewId',
    message: '/reviews/:reviewId/messages',
  },
  task: {
    default: '/tasks',
    update: '/tasks/:taskId',
  },
};

export default ApiRoutes;
