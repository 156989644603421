const getToken = () => {
  return localStorage.getItem('token');
};

const setToken = (token: string) => {
  localStorage.setItem('token', token);
};

const getRefreshToken = () => {
  return localStorage.getItem('refresh_token');
};

const setRefreshToken = (refreshToken: string) => {
  localStorage.setItem('refresh_token', refreshToken);
};

const TokenService = {
  getToken,
  setToken,
  getRefreshToken,
  setRefreshToken,
};

export default TokenService;
