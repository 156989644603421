import { Fragment, memo, useEffect, useState } from 'react';
import { ClickAwayListener, Grow, Paper, Popper } from '@mui/material';
import { Color, ColorResult, SketchPicker } from 'react-color';
import classes from './styles.module.scss';

interface ColorFillProps {
  name?: string; // optional for now
  color: string;
  onChangeStatusColor?: (name: string, color: ColorResult) => void; // optional for now
}

const ColorFill = memo((props: ColorFillProps) => {
  const { name, color, onChangeStatusColor } = props;

  const [colorPicker, setColorPicker] = useState<ColorResult | Color>('var(--gray)');
  const [anchorElement, setAnchorElement] = useState<any>(null);

  useEffect(() => {
    if (color) {
      setColorPicker(color);
    }
  }, [color]);

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorElement?.contains(event?.target as HTMLElement)) {
      return;
    }
    setAnchorElement(null);
  };

  return (
    <Fragment>
      <svg
        onClick={(event: React.MouseEvent<SVGElement>) => {
          if (anchorElement) {
            setAnchorElement(null);
          } else {
            setAnchorElement(event?.currentTarget);
          }
        }}
        className={classes.container}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask id="mask0_939_2750" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
          <rect width="24" height="24" fill={color ?? 'var(--gray)'} />
        </mask>
        <g mask="url(#mask0_939_2750)">
          <path
            d="M8.3 0.699987L16.175 8.57499C16.5583 8.95832 16.75 9.43332 16.75 9.99999C16.75 10.5667 16.5583 11.0417 16.175 11.425L11.425 16.175C11.0417 16.5583 10.5667 16.75 10 16.75C9.43333 16.75 8.95833 16.5583 8.575 16.175L3.825 11.425C3.44167 11.0417 3.25 10.5667 3.25 9.99999C3.25 9.43332 3.44167 8.95832 3.825 8.57499L8.575 3.79999L6.875 2.09999C6.675 1.89999 6.57917 1.66665 6.5875 1.39999C6.59583 1.13332 6.7 0.899987 6.9 0.699987C7.1 0.516654 7.33333 0.420821 7.6 0.412487C7.86667 0.404154 8.1 0.499987 8.3 0.699987ZM10 5.22499L5.225 9.99999H14.775L10 5.22499ZM19 17C18.45 17 17.9792 16.8042 17.5875 16.4125C17.1958 16.0208 17 15.55 17 15C17 14.65 17.1042 14.275 17.3125 13.875C17.5208 13.475 17.75 13.1 18 12.75C18.15 12.55 18.3083 12.3417 18.475 12.125C18.6417 11.9083 18.8167 11.7 19 11.5C19.1833 11.7 19.3583 11.9083 19.525 12.125C19.6917 12.3417 19.85 12.55 20 12.75C20.25 13.1 20.4792 13.475 20.6875 13.875C20.8958 14.275 21 14.65 21 15C21 15.55 20.8042 16.0208 20.4125 16.4125C20.0208 16.8042 19.55 17 19 17ZM4 24C3.45 24 2.97917 23.8042 2.5875 23.4125C2.19583 23.0208 2 22.55 2 22C2 21.45 2.19583 20.9792 2.5875 20.5875C2.97917 20.1958 3.45 20 4 20H20C20.55 20 21.0208 20.1958 21.4125 20.5875C21.8042 20.9792 22 21.45 22 22C22 22.55 21.8042 23.0208 21.4125 23.4125C21.0208 23.8042 20.55 24 20 24H4Z"
            fill={color ?? 'var(--gray)'}
          />
        </g>
      </svg>

      <Popper
        open={!!anchorElement}
        anchorEl={anchorElement}
        className={classes.popover}
        role={undefined}
        transition
        placement="bottom"
        disablePortal
        modifiers={[
          {
            name: 'preventOverflow',
            enabled: true,
            options: {
              altAxis: true,
              altBoundary: true,
              tether: true,
              rootBoundary: 'html',
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper className={classes.paper}>
              <ClickAwayListener onClickAway={handleClose}>
                <div>
                  <SketchPicker
                    color={colorPicker as Color}
                    onChange={(color: ColorResult) => {
                      setColorPicker(color);
                    }}
                    onChangeComplete={(color: ColorResult) => {
                      onChangeStatusColor(name, color);
                    }}
                  />
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
});

export default ColorFill;
