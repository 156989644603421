import { memo } from 'react';
import classes from './styles.module.scss';
import { Box, LinearProgress, LinearProgressProps } from '@mui/material';

interface LoadingOverlayProps {
  progress?: number;
}

const LinearProgressWithLabel = (props: LinearProgressProps & { value: number }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', maxWidth: '530px' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress
          variant="determinate"
          sx={{
            height: '12px',
            borderRadius: '8px',
            backgroundColor: 'var(--white)',
            '& .MuiLinearProgress-bar': {
              backgroundColor: 'var(--primary)',
            },
          }}
          {...props}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <p>{`${Math.round(props.value)}%`}</p>
      </Box>
    </Box>
  );
};

const LoadingOverlay: React.FC<LoadingOverlayProps> = memo((props: LoadingOverlayProps) => {
  const { progress } = props;

  return (
    <div className={classes.root}>
      {progress != null ? (
        <LinearProgressWithLabel value={progress} />
      ) : (
        <div className={classes.loading}>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
      )}
    </div>
  );
});

export default LoadingOverlay;
