import { memo, ReactNode, MouseEvent } from 'react';
import classes from './styles.module.scss';
import clsx from 'clsx';
import { ErrorIcon, LoadingIcon } from 'assets';

interface LoadingProps {
  isLoading?: boolean;
  errorText?: string;
  loadingText?: string;
  className?: string;
  errorButtonText?: string;
  errorButtonOnClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  children?: ReactNode;
}

const Loading: React.FC<LoadingProps> = memo((props: LoadingProps) => {
  const { isLoading = true, errorText, loadingText, className, errorButtonText, errorButtonOnClick, ...rest } = props;

  const message = {
    loading: loadingText || 'Loading, please wait...',
    error: errorText || 'An error occurred, please try again later.',
  };

  return (
    <div className={clsx(classes.loadingContainer, className)} {...rest}>
      <div className={clsx({ [classes.isLoading]: isLoading })}>{isLoading ? <LoadingIcon /> : <ErrorIcon />}</div>
      <div className={classes.loadingText}>{isLoading ? message.loading : message.error}</div>
      {!isLoading && errorButtonText ? (
        <button className="mb-5" onClick={errorButtonOnClick}>
          {errorButtonText}
        </button>
      ) : null}
    </div>
  );
});

export default Loading;
