import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import classes from './styles.module.scss';
import { Fragment, memo, useEffect, useState } from 'react';
import { Box, Divider, Stack } from '@mui/material';
import { PlusWhiteIcon } from 'assets';
import { TextField } from '@mui/material';
import ColorFill from 'components/ColorFill';
import styled from 'styled-components';
import { COMPLEXITY_OPTIONS } from 'configs/constant';
import { IComplexityOption } from 'interfaces/common';

const ComplexityInput = styled(TextField)<{ $color?: string }>`
  .MuiInput-underline::before {
    display: none;
  }
  .MuiInput-underline::after {
    border-color: ${(props) => props?.$color ?? 'var(--gray)'};
  }
`;

interface ComplexityPopoverProps {
  anchorElement: HTMLElement;
  onClose: () => void;
  onSelect: (complexity: number) => void;
}

const ComplexityPopover = memo((props: ComplexityPopoverProps) => {
  const { anchorElement, onClose, onSelect } = props;

  const [editing, setEditing] = useState<boolean>(false);

  useEffect(() => {
    if (!anchorElement) {
      setEditing(false);
    }
  }, [anchorElement]);

  // const onEditing = () => {
  //   setEditing(true);
  // };

  const onSave = () => {
    setEditing(false);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorElement?.contains(event?.target as HTMLElement)) {
      return;
    }
    onClose();
  };

  return (
    <Popper
      open={!!anchorElement}
      anchorEl={anchorElement}
      className={classes.popover}
      role={undefined}
      transition
      placement="bottom"
      disablePortal
      modifiers={[
        {
          name: 'preventOverflow',
          enabled: true,
          options: {
            altAxis: true,
            altBoundary: true,
            tether: true,
            rootBoundary: 'html',
          },
        },
      ]}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper className={classes.paper}>
            <ClickAwayListener onClickAway={handleClose}>
              <div className={classes.menu}>
                {!editing ? (
                  <Fragment>
                    {COMPLEXITY_OPTIONS?.map((complexity: IComplexityOption, complexityIndex: number) => (
                      <Box
                        key={`complexity-${complexityIndex}`}
                        className={classes.complexityItem}
                        sx={{ borderColor: complexity?.color ?? 'var(--gray)', mb: complexityIndex === COMPLEXITY_OPTIONS?.length - 1 ? 0 : 2 }} // 0 -> 3 when get back editing mode
                        onClick={() => {
                          onSelect(complexity?.value as number);
                          onClose();
                        }}
                      >
                        {complexity?.label ?? 'N/A'}
                      </Box>
                    ))}

                    {/* <Divider className={classes.divider} />

                    <p className={classes.editLabel} onClick={onEditing}>
                      Edit Labels
                    </p> */}
                  </Fragment>
                ) : (
                  <Fragment>
                    {COMPLEXITY_OPTIONS?.map((complexity: IComplexityOption, complexityIndex: number) => (
                      <Stack key={`complexity-${complexityIndex}`} className={classes.editContainer}>
                        <ColorFill color={complexity?.color} />
                        <ComplexityInput
                          className={classes.complexityInput}
                          $color={complexity?.color}
                          variant="standard"
                          defaultValue={complexity?.label}
                        />
                      </Stack>
                    ))}

                    <Box className={classes.complexityItem} sx={{ display: 'flex', justifyContent: 'center', margin: '0 auto 24px' }}>
                      <PlusWhiteIcon />
                    </Box>

                    <Divider className={classes.divider} />

                    <p className={classes.editLabel} onClick={onSave}>
                      Save
                    </p>
                  </Fragment>
                )}
              </div>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
});

export default ComplexityPopover;
