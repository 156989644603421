import { Fragment, memo, useEffect, useMemo, useState } from 'react';
import classes from './styles.module.scss';
import Input from 'components/Input';
import { Col, Row } from 'reactstrap';
import * as Yup from 'yup';
import Regexes from 'configs/regexes';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ERegisterSteps } from 'configs/enums';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import ToastService from 'services/toast_service';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import SecondaryButton from 'components/Buttons/SecondaryButton';
import Messages from 'configs/messages';
import { setIsLoadingReducer } from 'redux/reducers/Status/actionTypes';
import ApiService from 'services/api_service';
import ApiRoutes from 'configs/apiRoutes';
import { push } from 'connected-react-router';
import CommonService from 'services/common_service';
import { routes } from 'routers/routes';
import { Link } from 'react-router-dom';

interface IInformationFormData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

interface IPasswordFormData {
  password: string;
  confirmPassword: string;
}

interface IRegisterFormData extends IInformationFormData, IPasswordFormData {}

interface RegisterPageProps {}

const RegisterPage: React.FC<RegisterPageProps> = memo((props: RegisterPageProps) => {
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [step, setStep] = useState<ERegisterSteps>(ERegisterSteps.Information);
  const [information, setInformation] = useState<IInformationFormData>(null);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape(
        step === ERegisterSteps.Information
          ? {
              firstName: Yup.string().required('Please enter your first name.'),
              lastName: Yup.string().required('Please enter your last name.'),
              email: Yup.string().required('Please enter your email.').matches(Regexes.email, 'Please enter a valid email.'),
              phone: Yup.lazy((value) => (!value ? Yup.string() : Yup.string().matches(Regexes.phoneNumber, 'Please enter a valid phone number.'))),
            }
          : {
              password: Yup.string()
                .required('Please enter your password.')
                .matches(
                  Regexes.password,
                  'Password must be least 8 characters including an upper case, an lower case, a number, and a special character.'
                ),
              confirmPassword: Yup.string()
                .required('Please confirm your password.')
                .oneOf([Yup.ref('password'), null], 'Confirm password does not match.'),
            }
      ),
    [step]
  );

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<IRegisterFormData>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const watchPassword = watch('password');
  const watchConfirmPassword = watch('confirmPassword');

  useEffect(() => {
    if (information) {
      setStep(ERegisterSteps.Password);
    }
  }, [information]);

  useEffect(() => {
    if (step === ERegisterSteps.Information && information) {
      reset({
        firstName: information?.firstName,
        lastName: information?.lastName,
        email: information?.email,
        phone: information?.phone,
      });
    }
  }, [step, information]);

  useEffect(() => {
    if (watchPassword !== watchConfirmPassword && watchConfirmPassword !== '') {
      setError('confirmPassword', {
        type: 'match',
        message: 'Confirm password does not match',
      });
    } else if (watchPassword === watchConfirmPassword && watchConfirmPassword !== '') {
      clearErrors('confirmPassword');
    }
  }, [watchConfirmPassword, watchPassword]);

  const onBack = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event?.preventDefault();
    setStep(ERegisterSteps.Information);
  };

  const onSubmitInformation = (data: IInformationFormData) => {
    dispatch(setIsLoadingReducer(true));
    ApiService.POST(ApiRoutes.auth.registerCheck, {
      first_name: data?.firstName,
      last_name: data?.lastName,
      email: data?.email,
      mobile: data?.phone,
    })
      .then(() => {
        setInformation(data);
      })
      .catch(() => {
        ToastService.error(Messages.error.existedEmail);
      })
      .finally(() => dispatch(setIsLoadingReducer(false)));
  };

  const onSubmitPassword = async (data: IPasswordFormData) => {
    if (!executeRecaptcha) {
      ToastService.error(Messages.error.recaptchaNotReady);
      return;
    }

    dispatch(setIsLoadingReducer(true));
    await executeRecaptcha(ApiRoutes.auth.register)
      .then(async (token: string) => {
        await ApiService.POST(ApiRoutes.auth.reCaptcha, { response_token: token })
          .then(async () => {
            await CommonService.sha256(data?.password)
              .then(async (hashedPassword) => {
                await ApiService.POST(ApiRoutes.auth.register, {
                  first_name: information?.firstName,
                  last_name: information?.lastName,
                  email: information?.email,
                  mobile: information?.phone,
                  password: hashedPassword,
                })
                  .then(() => {
                    dispatch(setIsLoadingReducer(false));
                    dispatch(
                      push({
                        pathname: routes.public.checkEmail,
                        search: `&email=${encodeURIComponent(information?.email)}`,
                      })
                    );
                  })
                  .catch((error) => {
                    console.log(error);
                    ToastService.error(Messages.error.default);
                    dispatch(setIsLoadingReducer(false));
                  });
              })
              .catch((error) => {
                console.log(error);
                ToastService.error(Messages.error.default);
                dispatch(setIsLoadingReducer(false));
              });
          })
          .catch(() => {
            ToastService.error(Messages.error.recaptchaFailed);
            dispatch(setIsLoadingReducer(false));
          });
      })
      .catch((error) => {
        console.log(error);
        ToastService.error(Messages.error.default);
        dispatch(setIsLoadingReducer(false));
      });
  };

  return (
    <Fragment>
      {step === ERegisterSteps.Information ? (
        <Fragment>
          <p className={classes.title}>Register</p>

          <form onSubmit={handleSubmit(onSubmitInformation)}>
            <Row>
              <Col sm={6}>
                <Input
                  label="First name"
                  inputRef={register('firstName')}
                  type="text"
                  placeholder="Your first name"
                  autoComplete="given-name"
                  errorMessage={errors?.firstName?.message}
                />
              </Col>
              <Col sm={6}>
                <Input
                  label="Last name"
                  inputRef={register('lastName')}
                  type="text"
                  placeholder="Your last name"
                  autoComplete="family-name"
                  errorMessage={errors?.lastName?.message}
                />
              </Col>
            </Row>

            <Input
              label="Email"
              inputRef={register('email')}
              type="text"
              placeholder="Your email"
              autoComplete="email"
              errorMessage={errors?.email?.message}
            />

            <Input
              label="Phone number"
              inputRef={register('phone')}
              type="text"
              placeholder="Your phone number"
              autoComplete="tel"
              isOptional
              errorMessage={errors?.phone?.message}
            />

            <PrimaryButton className="mb-4" type="submit" fullWidth>
              Continue
            </PrimaryButton>

            <div className={classes.loginContainer}>
              <p>Already have an account?</p>
              <Link to={routes.public.login}>Login</Link>
            </div>

            <SecondaryButton className={classes.backButton} type="button" onClick={() => dispatch(push(routes.public.getStarted))}>
              Back
            </SecondaryButton>
          </form>
        </Fragment>
      ) : (
        <Fragment>
          <p className={classes.title}>Set Password</p>
          <form onSubmit={handleSubmit(onSubmitPassword)}>
            <Input
              label="Password"
              inputRef={register('password')}
              type="password"
              placeholder="Your password"
              errorMessage={errors?.password?.message}
            />

            <Input
              className="mb-4"
              label="Confirm password"
              inputRef={register('confirmPassword')}
              type="password"
              placeholder="Conform your password"
              errorMessage={errors?.confirmPassword?.message}
            />

            <PrimaryButton className="mb-4" type="submit" fullWidth>
              Register
            </PrimaryButton>

            <SecondaryButton className={classes.backButton} type="button" onClick={onBack}>
              Back
            </SecondaryButton>
          </form>
        </Fragment>
      )}
    </Fragment>
  );
});

export default RegisterPage;
