import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import classes from './styles.module.scss';
import { Fragment, memo, useEffect, useState } from 'react';
import { Box, Divider, Stack } from '@mui/material';
import { PlusWhiteIcon } from 'assets';
import { TextField } from '@mui/material';
import ColorFill from 'components/ColorFill';
import styled from 'styled-components';
import { IReducer } from 'redux/reducers';
import { useSelector } from 'react-redux';
import { ITaskConfigurationStatus } from 'interfaces/project';

const StatusInput = styled(TextField)<{ $color?: string }>`
  .MuiInput-underline::before {
    display: none;
  }
  .MuiInput-underline::after {
    border-color: ${(props) => props?.$color ?? 'var(--gray)'};
  }
`;

interface StatusPopoverProps {
  anchorElement: HTMLElement;
  onClose: () => void;
  onSelect: (status: string) => void;
}

const StatusPopover = memo((props: StatusPopoverProps) => {
  const { anchorElement, onClose, onSelect } = props;

  const { project, pipeline } = useSelector((state: IReducer) => state.workspace);

  const [editing, setEditing] = useState<boolean>(false);
  const [statusList, setStatusList] = useState<ITaskConfigurationStatus[]>([]);

  useEffect(() => {
    if (!anchorElement) {
      setEditing(false);
    }
  }, [anchorElement]);

  useEffect(() => {
    if (pipeline?.inherit_parent_task_configuration) {
      setStatusList(project?.task_configuration?.statuses);
    } else {
      setStatusList(pipeline?.task_configuration?.statuses);
    }
  }, [project, pipeline]);

  // const onEditing = () => {
  //   setEditing(true);
  // };

  const onSave = () => {
    setEditing(false);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorElement?.contains(event?.target as HTMLElement)) {
      return;
    }
    onClose();
  };

  return (
    <Popper
      open={!!anchorElement}
      anchorEl={anchorElement}
      className={classes.popover}
      role={undefined}
      transition
      placement="bottom"
      disablePortal
      modifiers={[
        {
          name: 'preventOverflow',
          enabled: true,
          options: {
            altAxis: true,
            altBoundary: true,
            tether: true,
            rootBoundary: 'html',
          },
        },
      ]}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper className={classes.paper}>
            <ClickAwayListener onClickAway={handleClose}>
              <div className={classes.menu}>
                {!editing ? (
                  <Fragment>
                    {statusList?.map((status: ITaskConfigurationStatus, statusIndex: number) => (
                      <Box
                        key={`status-${statusIndex}`}
                        className={classes.statusItem}
                        sx={{ borderColor: status?.color ? `#${status?.color}` : 'var(--gray)', mb: statusIndex === statusList?.length - 1 ? 0 : 2 }} // 0 -> 3
                        onClick={() => {
                          onSelect(status?.name);
                          onClose();
                        }}
                      >
                        {status?.name ?? 'N/A'}
                      </Box>
                    ))}

                    {/* <Divider className={classes.divider} />

                    <p className={classes.editLabel} onClick={onEditing}>
                      Edit Labels
                    </p> */}
                  </Fragment>
                ) : (
                  <Fragment>
                    {statusList?.map((status: ITaskConfigurationStatus, statusIndex: number) => (
                      <Stack key={`status-${statusIndex}`} className={classes.editContainer}>
                        <ColorFill color={status?.color ? `#${status?.color}` : null} />
                        <StatusInput
                          className={classes.statusInput}
                          $color={status?.color ? `#${status?.color}` : null}
                          variant="standard"
                          defaultValue={status?.name}
                        />
                      </Stack>
                    ))}

                    <Box className={classes.statusItem} sx={{ display: 'flex', justifyContent: 'center', margin: '0 auto 24px' }}>
                      <PlusWhiteIcon />
                    </Box>

                    <Divider className={classes.divider} />

                    <p className={classes.editLabel} onClick={onSave}>
                      Save
                    </p>
                  </Fragment>
                )}
              </div>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
});

export default StatusPopover;
