import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import SecondaryButton from 'components/Buttons/SecondaryButton';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import Input from 'components/Input';
import classes from './styles.module.scss';
import { CloseIcon } from 'assets';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import MenuSelectUser from './components/MenuSelectUser';
import useDebounce from 'hooks/useDebounce';
import { ISearchUserResult } from 'interfaces/user';
import Select from 'components/Select';
import { ESelectTheme } from 'configs/enums';
import { useSelector } from 'react-redux';
import { IReducer } from 'redux/reducers';
import { ISelectOption } from 'interfaces/common';
import { IWorkspace } from 'interfaces/workspace';

const sampleData = [
  // {
  //   first_name: 'A',
  //   last_name: 'B',
  //   email: 'AB@gmail.com',
  // },
  // {
  //   first_name: 'Khoa',
  //   last_name: 'Le',
  //   email: 'Khoale@gmail.com',
  // },
  // {
  //   first_name: 'Son',
  //   last_name: 'Truong',
  //   email: 'sontruong@pixelcent.com',
  // },
  // {
  //   first_name: 'Khoa',
  //   last_name: 'Le2',
  //   email: 'khoale@pixelcent.com',
  // },
];

interface IInviteFormData {
  workspace: ISelectOption;
  email: string;
}

interface ModalInviteUserProps {
  hasWorkspace?: boolean;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: string[]) => void;
}

const ModalInviteUser = memo((props: ModalInviteUserProps) => {
  const { isOpen, onClose, onSubmit, hasWorkspace } = props;

  const { workspaceList } = useSelector((state: IReducer) => state?.information);

  const [emailList, setEmailList] = useState<string[]>([]);
  const [anchorElement, setAnchorElement] = useState<HTMLElement>(null);
  const [existedUsers, setExistedUsers] = useState<ISearchUserResult[]>([]);
  const [workspaceOptions, setWorkspaceOptions] = useState<ISelectOption[]>([]);

  const stopSuggestion = useRef<boolean>();

  const validationSchema = useMemo(
    () =>
      Yup.object().shape(
        hasWorkspace
          ? {
              workspace: Yup.object().required('This field is required.'),
              email: Yup.string()
                .email('Please enter a valid email.')
                .when('$checkRequired', (checkRequire: boolean, schema: Yup.StringSchema<string>) => {
                  return checkRequire ? schema.required('') : schema;
                }),
            }
          : {
              email: Yup.string()
                .email('Please enter a valid email.')
                .when('$checkRequired', (checkRequire: boolean, schema: Yup.StringSchema<string>) => {
                  return checkRequire ? schema.required('') : schema;
                }),
            }
      ),
    [hasWorkspace]
  );

  const {
    register,
    getValues,
    setValue,
    clearErrors,
    control,
    formState: { errors },
  } = useForm<IInviteFormData>({
    resolver: yupResolver(validationSchema),
    context: {
      checkRequired: emailList?.length === 0,
    },
    mode: 'onChange',
  });

  useEffect(() => {
    if (hasWorkspace && workspaceList?.length) {
      setWorkspaceOptions(
        workspaceList?.map((item: IWorkspace) => {
          return {
            value: item?._id,
            label: item?.name,
          };
        })
      );
    }
  }, [hasWorkspace, workspaceList]);

  useEffect(() => {
    if (!isOpen) {
      setValue('email', '');
      setEmailList([]);
    }
  }, [isOpen]);

  useEffect(() => {
    clearErrors();
    setExistedUsers([]);
  }, [emailList]);

  const onAddName = (name: string) => {
    if (name) {
      if (!emailList?.includes(name)) {
        setEmailList([...emailList, name]);
      } else {
        clearErrors();
      }
      setValue('email', '');
    }
  };

  const onSelectName = (name: string) => {
    if (!emailList?.includes(name)) {
      setEmailList([...emailList, name]);
    }
    setValue('email', '');
    clearErrors();
    setExistedUsers([]);
    setAnchorElement(null);
  };

  const handleSubmit = (event: React.FormEvent) => {
    event?.preventDefault();
    onSubmit && onSubmit(emailList?.length ? emailList : [getValues('email')]);
  };

  const onCloseMenu = (event: Event | React.SyntheticEvent) => {
    if (anchorElement && anchorElement === event?.target) {
      return;
    }
    setAnchorElement(null);
  };

  const onSearchEmail = useDebounce((searchKey: string) => {
    if (searchKey?.length < 1) {
      return setExistedUsers([]);
    }
    const searchedUsers = sampleData
      ?.filter((user) => user?.email?.toLowerCase()?.includes(searchKey?.toLowerCase()))
      ?.filter((user) => !emailList?.includes(user?.email));
    setExistedUsers(
      searchedUsers?.length
        ? searchedUsers
        : !errors?.email?.message && !emailList?.includes(searchKey)
        ? [{ first_name: 'N/A', last_name: '', email: searchKey }]
        : []
    );
  }, 300);

  return (
    <Dialog transitionDuration={0.3} open={isOpen} onClose={onClose} className={classes.container}>
      <DialogTitle>{hasWorkspace ? 'Invite user to Workspace' : 'Invite user'}</DialogTitle>

      <DialogContent>
        <form id="email" onSubmit={handleSubmit}>
          {hasWorkspace ? (
            <Select
              customTheme={ESelectTheme.Form}
              label="Workspace"
              name="workspace"
              control={control}
              errorMessage={errors?.workspace?.message as string}
              options={workspaceOptions}
            />
          ) : null}

          <Input
            className={emailList?.length ? 'mb-0' : null}
            label="Email"
            subLabel="Comma separated for multiple"
            placeholder="Email address"
            autoComplete="off"
            inputRef={register('email', {
              onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                stopSuggestion.current = false;
                setExistedUsers([]);

                if (event?.target?.value?.endsWith(',') && !errors?.email?.message) {
                  stopSuggestion.current = true;
                  onAddName(event?.target?.value?.slice(0, -1));
                } else {
                  onSearchEmail(event?.target?.value);
                }
              },
            })}
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
              if (event?.key === 'Enter' && !errors?.email) {
                event?.preventDefault();
                stopSuggestion.current = true;
                onAddName(getValues('email'));
              }
            }}
            onFocus={(event: React.FocusEvent<HTMLInputElement>) => {
              setAnchorElement(event?.currentTarget);
            }}
            errorMessage={errors?.email?.message}
          />

          {emailList?.length ? (
            <div className={classes.emailListContainer}>
              {emailList?.map((name, nameIndex) => {
                return (
                  <div key={`name-${nameIndex}`}>
                    <p>{name}</p>
                    <CloseIcon onClick={() => setEmailList(emailList?.filter((item) => item !== name))} />
                  </div>
                );
              })}
            </div>
          ) : null}

          {anchorElement && existedUsers?.length && !stopSuggestion?.current ? (
            <MenuSelectUser anchorElement={anchorElement} onClose={onCloseMenu} userList={existedUsers} onSelectName={onSelectName} />
          ) : null}
        </form>
      </DialogContent>

      <DialogActions>
        <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
        <PrimaryButton type="submit" form="email" disabled={(!emailList?.length && !!errors?.email) || (!emailList?.length && !getValues('email'))}>
          Invite
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
});

export default ModalInviteUser;
