import { push } from 'connected-react-router';
import { Fragment, memo, useEffect } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import TokenService from 'services/token_service';
import { GoogleOAuthProvider } from '@react-oauth/google';
import classes from './styles.module.scss';
import GalaxyBackground from 'components/GalaxyBackground';
import { routes } from 'routers/routes';
import useRouteMatch from 'hooks/useRouteMatch';
import { IReducer } from 'redux/reducers';
import { NULL_VALUE } from 'configs/constant';
// import { iframeLink } from 'configs/links';

interface NonAuthorizedLayoutProps {
  children: React.ReactNode;
}

const NonAuthorizedLayout: React.FC<NonAuthorizedLayoutProps> = memo((props: NonAuthorizedLayoutProps) => {
  const { children } = props;

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { isExact: isAcceptInvitationPage } = useRouteMatch(routes.public.callback.acceptInvitation);

  const { workspace } = useSelector((state: IReducer) => state?.workspace);

  const isGetStartedPage = pathname === routes.public.getStarted;
  const isLoginPage = pathname === routes.public.login;
  const isRegisterPage = pathname === routes.public.register;
  const isVerifySuccessPage = pathname === routes.public.verifySuccess;

  // const [isLoadedIframe, setIsLoadedIframe] = useState<boolean>(false);

  useEffect(() => {
    if (TokenService.getToken() && !isAcceptInvitationPage) {
      dispatch(push({ pathname: routes.private.home, search: `&workspaceId=${workspace?._id ?? NULL_VALUE}` }));
    }
  }, []);

  const renderContent = () => (
    <div className={classes.container}>
      <div className={classes.iframeContainer}>
        {/* <iframe
          className={classes.iframe}
          src={iframeLink}
          title="Universal Pipeline"
          onLoad={() => {
            setIsLoadedIframe(true);
          }}
          scrolling="no"
        />
        {!isLoadedIframe ? <Loading className={classes.loading} /> : null} */}
        <GalaxyBackground className={classes.galaxyBackground}>
          <div className={classes.content}>
            <span>UNIPIPE</span>
            <span className={classes.quote}>Elegant | Powerful | Smart | Unified</span>
          </div>
        </GalaxyBackground>
      </div>
      <div className={classes.formContainer}>
        <div className={classes.formWrapper}>
          <div className={classes.formContent}>{children}</div>
        </div>
      </div>
    </div>
  );

  return !TokenService.getToken() || isAcceptInvitationPage ? (
    isGetStartedPage ? (
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>{renderContent()}</GoogleOAuthProvider>
    ) : isLoginPage || isRegisterPage ? (
      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_GOOGLE_SITE_KEY}>{renderContent()}</GoogleReCaptchaProvider>
    ) : isVerifySuccessPage ? (
      <Fragment>{children}</Fragment>
    ) : (
      renderContent()
    )
  ) : null;
});

export default NonAuthorizedLayout;
