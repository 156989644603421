import { Fragment, memo, useEffect } from 'react';
import classes from './styles.module.scss';
import QueryString from 'qs';
import * as Yup from 'yup';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import { routes } from 'routers/routes';

interface IQueryString {
  email?: string;
}

interface CheckEmailPageProps {}

const CheckEmailPage: React.FC<CheckEmailPageProps> = memo((props: CheckEmailPageProps) => {
  const dispatch = useDispatch();

  const { email }: IQueryString = QueryString.parse(window?.location?.search);

  useEffect(() => {
    const isValidQuery = Yup.string().email().required().isValidSync(email);
    if (!isValidQuery) {
      dispatch(push(routes.public.getStarted));
    }
  }, [email]);

  return (
    <Fragment>
      <p className={classes.title}>Verify your email address</p>

      <p className={classes.content}>
        An email has been sent to <b>{email}</b>. Please open the email and follow the instruction to verify your email address.
      </p>

      <Divider className={classes.line} />

      <p>
        Already verified your email address? <Link to={routes.public.login}>Login</Link>
      </p>
    </Fragment>
  );
});

export default CheckEmailPage;
