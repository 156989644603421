import ApiRoutes from 'configs/apiRoutes';
import { EStatusCode } from 'configs/enums';
import Messages from 'configs/messages';
import { put, takeLatest, call } from 'redux-saga/effects';
import { GET_WORKSPACE_LIST_REQUEST, setWorkspaceListReducer } from 'redux/reducers/Information/actionTypes';
import { setIsLoadingReducer } from 'redux/reducers/Status/actionTypes';
import ApiService from 'services/api_service';
import ToastService from 'services/toast_service';

function* requestGetWorkspaceList() {
  try {
    yield put(setIsLoadingReducer(true));
    const response = yield call(ApiService.GET, ApiRoutes.workspace.default);
    yield put(setWorkspaceListReducer(response?.entities ?? []));
  } catch (error: any) {
    if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
      yield ToastService.error(Messages.error.accessDenied);
    } else {
      yield console.log(error);
      yield ToastService.error(Messages.error.default);
    }
    yield put(setIsLoadingReducer(false)); // synchronous with get (archived) project list
  } finally {
    // yield put(setIsLoadingReducer(false)); // synchronous with get (archived) project list
  }
}

function* getWorkspaceList() {
  yield takeLatest(GET_WORKSPACE_LIST_REQUEST, requestGetWorkspaceList);
}

export default getWorkspaceList;
