import { EProjectUserColumns, ESortDirection, EUserColumns } from 'configs/enums';
import { ICollabUser, IProjectCollabUserListState, ISortProjectUserTableState, ISortUserTableState, IUser } from 'interfaces/user';

export const SET_USER_REDUCER = 'SET_USER_REDUCER';

export const GET_PROJECT_COLLAB_USER_LIST_REQUEST = 'GET_PROJECT_COLLAB_USER_LIST_REQUEST';
export const SET_PROJECT_COLLAB_USER_LIST_REDUCER = 'SET_PROJECT_COLLAB_USER_LIST_REDUCER';

export const GET_COLLAB_USER_LIST_REQUEST = 'GET_COLLAB_USER_LIST_REQUEST';
export const SET_COLLAB_USER_LIST_REDUCER = 'SET_COLLAB_USER_LIST_REDUCER';

export const SET_SORT_USER_TABLE_REDUCER = 'SET_SORT_USER_TABLE_REDUCER';

export const SET_SORT_PROJECT_USER_TABLE_REDUCER = 'SET_SORT_PROJECT_USER_TABLE_REDUCER';

export const SET_LOGOUT_USER_REDUCER = 'SET_LOGOUT_USER_REDUCER';

export const setUserReducer = (data: IUser) => {
  return {
    type: SET_USER_REDUCER,
    data,
  };
};

export const getProjectCollabUserListRequest = (projectId: string, sortBy?: EProjectUserColumns, sortDirection?: ESortDirection) => {
  return {
    type: GET_PROJECT_COLLAB_USER_LIST_REQUEST,
    projectId,
    sortBy,
    sortDirection,
  };
};
export const setProjectCollabUserListReducer = (data: IProjectCollabUserListState) => {
  return {
    type: SET_PROJECT_COLLAB_USER_LIST_REDUCER,
    data,
  };
};

export const getCollabUserListRequest = (projectIds: string, sortBy?: EUserColumns, sortDirection?: ESortDirection) => {
  return {
    type: GET_COLLAB_USER_LIST_REQUEST,
    projectIds,
    sortBy,
    sortDirection,
  };
};
export const setCollabUserListReducer = (data: ICollabUser[]) => {
  return {
    type: SET_COLLAB_USER_LIST_REDUCER,
    data,
  };
};

export const setSortUserTableReducer = (data: ISortUserTableState) => {
  return {
    type: SET_SORT_USER_TABLE_REDUCER,
    data,
  };
};

export const setSortProjectUserTableReducer = (data: ISortProjectUserTableState) => {
  return {
    type: SET_SORT_PROJECT_USER_TABLE_REDUCER,
    data,
  };
};

export const setLogoutUserReducer = () => {
  return {
    type: SET_LOGOUT_USER_REDUCER,
  };
};
