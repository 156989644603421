import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import classes from './styles.module.scss';
import { memo } from 'react';
import { EHistoryPopover } from 'configs/enums';

interface HistoryPopoverProps {
  anchorElement: SVGElement;
  onClose: () => void;
  onSelect: (type: EHistoryPopover) => void;
}

const HistoryPopover = memo((props: HistoryPopoverProps) => {
  const { anchorElement, onClose, onSelect } = props;

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorElement?.contains(event?.target as HTMLElement)) {
      return;
    }
    onClose();
  };

  return (
    <Popper open={!!anchorElement} anchorEl={anchorElement} className={classes.popover} role={undefined} transition placement="top" disablePortal>
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper className={classes.paper}>
            <ClickAwayListener onClickAway={handleClose}>
              <div className={classes.menu}>
                <div
                  onClick={() => {
                    onSelect(EHistoryPopover.Revert);
                    onClose();
                  }}
                >
                  Revert
                </div>
                <div
                  onClick={() => {
                    onSelect(EHistoryPopover.Copy);
                    onClose();
                  }}
                >
                  Copy
                </div>
              </div>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
});

export default HistoryPopover;
