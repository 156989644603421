import { Fragment, memo, useEffect, useRef, useState } from 'react';
import classes from './styles.module.scss';
import { EProjectSettings } from 'configs/enums';
import GeneralSettings from './components/GeneralSettings';
import { IEditProjectUrlParams, IProjectSidebar } from 'interfaces/project';
import { useDispatch, useSelector } from 'react-redux';
import { IReducer } from 'redux/reducers';
import { getProjectTemplatesRequest, getPipelineTemplatesRequest, getGlobalAppConfigurationsRequest } from 'redux/reducers/Information/actionTypes';
import PipelineSettings from './components/PipelineSettings';
import useRouteMatch from 'hooks/useRouteMatch';
import { routes } from 'routers/routes';
import { Container } from 'react-bootstrap';
import Header from 'components/Header';
import { setProjectReducer } from 'redux/reducers/Workspace/actionTypes';
import clsx from 'clsx';
import { useLocation, useParams } from 'react-router-dom';
import { ReactFlowProvider } from 'reactflow';
import QueryString from 'qs';
import UsersSettings from './components/UsersSettings';
import { push } from 'connected-react-router';
import ToastService from 'services/toast_service';
import Messages from 'configs/messages';
import { NULL_VALUE } from 'configs/constant';

interface CreateEditProjectPageProps {}

const CreateEditProjectPage: React.FC<CreateEditProjectPageProps> = memo((props: CreateEditProjectPageProps) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { projectId } = useParams<IEditProjectUrlParams>();
  const { isExact: isEdit } = useRouteMatch(routes.private.project.edit);

  const { name, type, activePipelineId: activePipelineIdSearchParam } = QueryString.parse(window?.location?.search);

  const { workspace } = useSelector((state: IReducer) => state?.workspace);
  const { projectTemplates, projectList, pipelineTemplates, globalAppConfigurations } = useSelector((state: IReducer) => state?.information);

  const isDraftCopied = useRef<boolean>();

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isEmpty, setIsEmpty] = useState<boolean>(false);
  const [settingType, setSettingType] = useState<EProjectSettings | null>(null);
  const [previewId, setPreviewId] = useState<string>(null);
  const [activePipelineId, setActivePipelineId] = useState<string>(null);
  const [projectSidebar, setProjectSidebar] = useState<IProjectSidebar>({ isOpen: false, data: null });

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (activePipelineIdSearchParam) {
      setActivePipelineId(activePipelineIdSearchParam as string);
    }
  }, [activePipelineIdSearchParam]);

  useEffect(() => {
    switch (type) {
      case EProjectSettings.General:
        setSettingType(EProjectSettings.General);
        break;
      case EProjectSettings.Pipeline:
        setSettingType(EProjectSettings.Pipeline);
        break;
      case EProjectSettings.Users:
        setSettingType(EProjectSettings.Users);
        break;
      default:
        setSettingType(EProjectSettings.General);
        break;
    }
  }, [type]);

  useEffect(() => {
    if (!projectTemplates) {
      dispatch(getProjectTemplatesRequest());
    }
  }, [projectTemplates]);

  useEffect(() => {
    if (!pipelineTemplates) {
      dispatch(getPipelineTemplatesRequest());
    }
  }, [pipelineTemplates]);

  useEffect(() => {
    if (isEdit && !name) {
      ToastService.error(Messages.error.default);
      dispatch(push({ pathname: routes.private.home, search: `&workspaceId=${workspace?._id ?? NULL_VALUE}` }));
    }
  }, [isEdit, name]);

  useEffect(() => {
    if (isEdit && projectId && projectList?.length) {
      dispatch(setProjectReducer(projectList?.find((project) => project?._id === projectId)));
    }
  }, [isEdit, projectId, projectList]);

  useEffect(() => {
    if (settingType === EProjectSettings.Pipeline) {
      if (!globalAppConfigurations) {
        dispatch(getGlobalAppConfigurationsRequest());
      }
    }
  }, [settingType]);

  const renderContent = () => {
    switch (settingType) {
      case EProjectSettings.General:
        return <GeneralSettings />;
      case EProjectSettings.Pipeline:
        return (
          <ReactFlowProvider
            children={
              <PipelineSettings
                previewId={previewId}
                setPreviewId={setPreviewId}
                activePipelineId={activePipelineId}
                setActivePipelineId={setActivePipelineId}
                isDraftCopied={isDraftCopied}
                projectSidebar={projectSidebar}
                setProjectSidebar={setProjectSidebar}
                isSaving={isSaving}
                setIsSaving={setIsSaving}
                setIsEmpty={setIsEmpty}
              />
            }
          />
        );
      case EProjectSettings.Users:
        return <UsersSettings />;
      default:
        return null;
    }
  };

  return (
    <Fragment>
      <div className={classes.sidebar}>
        <div
          className={clsx({ [classes.active]: settingType === EProjectSettings.General })}
          onClick={() =>
            dispatch(
              push({
                pathname: pathname,
                search: `&name=${encodeURIComponent(name as string)}&type=${EProjectSettings.General}&workspaceId=${workspace?._id ?? NULL_VALUE}`,
              })
            )
          }
        >
          General
        </div>
        <div
          className={clsx(
            { [classes.active]: settingType === EProjectSettings.Pipeline },
            { [classes.disabled]: settingType === EProjectSettings.General && !isEdit }
          )}
          onClick={() =>
            dispatch(
              push({
                pathname: pathname,
                search: `&name=${encodeURIComponent(name as string)}&type=${EProjectSettings.Pipeline}&workspaceId=${workspace?._id ?? NULL_VALUE}`,
              })
            )
          }
        >
          Pipelines
        </div>
        <div
          className={clsx(
            { [classes.active]: settingType === EProjectSettings.Users },
            { [classes.disabled]: settingType === EProjectSettings.General && !isEdit }
          )}
          onClick={() =>
            dispatch(
              push({
                pathname: pathname,
                search: `&name=${encodeURIComponent(name as string)}&type=${EProjectSettings.Users}&workspaceId=${workspace?._id ?? NULL_VALUE}`,
              })
            )
          }
        >
          Users
        </div>
      </div>

      <Container fluid className={classes.container}>
        <Header
          className="mb-1"
          isNewProject={settingType === EProjectSettings.General && !isEdit}
          previewId={previewId}
          activePipelineId={activePipelineId}
          isPipelineStages={settingType === EProjectSettings.Pipeline}
          isUsers={settingType === EProjectSettings.Users}
          isDraftCopied={isDraftCopied}
          projectSidebar={projectSidebar}
          setProjectSidebar={setProjectSidebar}
          isSaving={isSaving}
          isEmpty={isEmpty}
        />
        {renderContent()}
      </Container>
    </Fragment>
  );
});

export default CreateEditProjectPage;
