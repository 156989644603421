import { Fragment, memo, useEffect } from 'react';
import classes from './styles.module.scss';
import { AddProjectIcon, EditIcon } from 'assets';
import { Skeleton, Stack, Switch } from '@mui/material';
import clsx from 'clsx';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { useDispatch, useSelector } from 'react-redux';
import { IReducer } from 'redux/reducers';
import { setArchivedProjectListReducer, setProjectListReducer } from 'redux/reducers/Information/actionTypes';
import { push } from 'connected-react-router';
import { setAllProjectListReducer, setIsArchivedReducer, setProjectReducer } from 'redux/reducers/Workspace/actionTypes';
import ProjectPlaceholderImage from './components/ProjectPlaceholderImage';
import { IProject } from 'interfaces/project';
import { routes } from 'routers/routes';
import { Container } from 'react-bootstrap';
import Header from 'components/Header';
import ProjectService from 'services/project_service';
import ToastService from 'services/toast_service';
import Messages from 'configs/messages';
import { setIsLoadingReducer } from 'redux/reducers/Status/actionTypes';
import ApiService from 'services/api_service';
import ApiRoutes from 'configs/apiRoutes';
import { NULL_VALUE } from 'configs/constant';
import { EStatusCode } from 'configs/enums';

const projectSkeletonCount = 8;

interface HomePageProps {}

const HomePage: React.FC<HomePageProps> = memo((props: HomePageProps) => {
  const dispatch = useDispatch();

  const { workspace, allProjectList, isArchived, pipeline } = useSelector((state: IReducer) => state?.workspace);
  const { projectList, archivedProjectList } = useSelector((state: IReducer) => state?.information);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const handleRecentProject = (project: IProject) => {
    // save recent project id and update project list in redux
    ProjectService.setRecentProjectId(project?._id);
    const list: IProject[] = [...(projectList ?? [])];
    const index = list?.findIndex((item: IProject) => item?._id === project?._id);
    if (index !== -1) {
      const recentProject = list?.[index];
      list?.splice(index, 1);
      list?.unshift(recentProject);
    }
    dispatch(setProjectListReducer(list));
  };

  const handleUnarchiveProject = (project: IProject) => {
    dispatch(setIsLoadingReducer(true));
    ApiService.PUT(ApiRoutes.project.unarchive.replace(':projectId', project?._id))
      .then(() => {
        dispatch(setProjectReducer(project));
        dispatch(setProjectListReducer([project, ...projectList]));
        dispatch(setAllProjectListReducer([project, ...allProjectList]));
        dispatch(setArchivedProjectListReducer(archivedProjectList?.filter((item) => item?._id !== project?._id)));
        ProjectService.setRecentProjectId(project?._id);
        ToastService.success(Messages.success.unarchive);
      })
      .catch((error) => {
        if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
          ToastService.error(Messages.error.accessDenied);
          return;
        }
        console.log(error);
        ToastService.error(Messages.error.default);
      })
      .finally(() => dispatch(setIsLoadingReducer(false)));
  };

  return (
    <Container fluid>
      <Header />
      <div className={classes.container}>
        <div className={clsx(classes.archiveContainer, { [classes.hide]: !archivedProjectList?.length })}>
          <Stack direction="row" alignItems="center" gap={2}>
            <p>Archived Projects</p>
            <Switch
              checked={isArchived}
              onChange={() => {
                dispatch(setIsArchivedReducer(!isArchived));
              }}
            />
          </Stack>
        </div>

        <div className={classes.projectList}>
          {!isArchived && projectList ? (
            <Fragment>
              {projectList?.length ? (
                <Fragment>
                  {projectList?.map((project: IProject, projectIndex: number) => {
                    return (
                      <div
                        key={project?._id}
                        className={classes.project}
                        onClick={() => {
                          handleRecentProject(project);
                          dispatch(setProjectReducer(project));
                          dispatch(
                            push({
                              pathname: routes.private.project.default
                                .replace(':projectId', project?._id)
                                .replace(
                                  ':pipelineId',
                                  project?.pipeline_ids?.includes(pipeline?._id)
                                    ? pipeline?._id
                                    : project?.pipeline_ids?.length
                                    ? project?.pipeline_ids?.[0]
                                    : null
                                ),
                              search: `&workspaceId=${workspace?._id ?? NULL_VALUE}`,
                            })
                          );
                        }}
                      >
                        <p>{project?.name ?? 'N/A'}</p>
                        {projectIndex === 0 ? (
                          <div className={classes.mostRecent}>
                            <div className={classes.dot} />
                            Most Recent
                          </div>
                        ) : null}
                        {project?.title_image_urls?.length ? (
                          <img src={project?.title_image_urls?.[0]} alt={project?.name ?? 'Project'} />
                        ) : (
                          <ProjectPlaceholderImage title={project?.name} />
                        )}
                        <EditIcon
                          className={classes.editIcon}
                          onClick={(event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
                            event?.stopPropagation();
                            handleRecentProject(project);
                            dispatch(
                              push({
                                pathname: routes.private.project.edit.replace(':workspaceId', workspace?._id).replace(':projectId', project?._id),
                                search: `&name=${encodeURIComponent(project?.name)}&workspaceId=${workspace?._id ?? NULL_VALUE}`,
                              })
                            );
                          }}
                        />
                      </div>
                    );
                  })}
                  <AddProjectIcon
                    onClick={() =>
                      dispatch(
                        push({
                          pathname: routes.private.project.create.replace(':workspaceId', workspace?._id),
                          search: `&workspaceId=${workspace?._id ?? NULL_VALUE}`,
                        })
                      )
                    }
                  />
                </Fragment>
              ) : (
                <div className={classes.noProject}>
                  <p>Create your first project</p>
                  <span>
                    Organize your project assets and pipelines and share with your collaborators. Bring your project to life, track progress and launch
                    on-time by collaborating in real time.
                  </span>
                  <PrimaryButton
                    onClick={() =>
                      dispatch(
                        push({
                          pathname: routes.private.project.create.replace(':workspaceId', workspace?._id),
                          search: `&workspaceId=${workspace?._id ?? NULL_VALUE}`,
                        })
                      )
                    }
                  >
                    Create a project
                  </PrimaryButton>
                </div>
              )}
            </Fragment>
          ) : isArchived && archivedProjectList ? (
            <Fragment>
              {archivedProjectList?.map((project: IProject, projectIndex: number) => {
                return (
                  <div
                    key={project?._id}
                    className={classes.project}
                    onClick={() => {
                      ToastService.error(Messages.error.unarchiveProject);
                    }}
                  >
                    <p>{project?.name ?? 'N/A'}</p>
                    {projectIndex === 0 ? (
                      <div className={classes.mostRecent}>
                        <div className={classes.dot} />
                        Most Recent
                      </div>
                    ) : null}
                    {project?.title_image_urls?.length ? (
                      <img src={project?.title_image_urls?.[0]} alt={project?.name ?? 'Project'} />
                    ) : (
                      <ProjectPlaceholderImage title={project?.name} />
                    )}
                    <PrimaryButton
                      className={classes.unarchiveButton}
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        event?.stopPropagation();
                        handleUnarchiveProject(project);
                      }}
                    >
                      Unarchive
                    </PrimaryButton>
                  </div>
                );
              })}
            </Fragment>
          ) : (
            [...Array(projectSkeletonCount).keys()]?.map((index) => {
              return (
                <Skeleton
                  key={`project-skeleton-${index}`}
                  variant="rectangular"
                  width={320}
                  height={320}
                  sx={{ borderRadius: '12px', background: 'var(--backgroundLight)' }}
                />
              );
            })
          )}
        </div>
      </div>
    </Container>
  );
});

export default HomePage;
