import { Fragment, memo } from 'react';
import classes from './styles.module.scss';
import { BaseEdge, ConnectionLineComponentProps, ReactFlowState, getBezierPath, useStore } from 'reactflow';
import { nodeDefaultHeight, nodeDefaultWidth } from '../..';

interface ConnectionLineProps extends ConnectionLineComponentProps {}

const ConnectionLine: React.FC<ConnectionLineProps> = memo((props: ConnectionLineProps) => {
  const { fromX, fromY, fromPosition, toX, toY, toPosition, connectionStatus } = props;

  const { connectionHandleType } = useStore((state: ReactFlowState) => state);

  const [edgePath] = getBezierPath({
    sourceX: fromX,
    sourceY: fromY,
    sourcePosition: fromPosition,
    targetX: toX,
    targetY: toY,
    targetPosition: toPosition,
  });

  return (
    <g>
      <BaseEdge
        path={edgePath}
        style={{
          strokeWidth: 2,
          stroke: 'var(--white)',
        }}
      />

      {connectionHandleType === 'source' ? (
        <Fragment>
          <svg width="16" height="16" x={toX - 13} y={toY - 8} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 14L9 8L3 2" stroke="var(--white)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          {connectionStatus !== 'valid' ? (
            <rect
              className={classes.placeholderNode}
              x={toX}
              y={toY - nodeDefaultHeight / 2}
              width={nodeDefaultWidth}
              height={61}
              rx={12}
              ry={12}
              stroke="var(--primary)"
              strokeWidth={1}
              fill="var(--nodeBackground)"
            />
          ) : null}
        </Fragment>
      ) : (
        <Fragment>
          <svg width="16" height="16" x={fromX - 11} y={fromY - 8} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 14L9 8L3 2" stroke="var(--white)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          {connectionStatus !== 'valid' ? (
            <rect
              className={classes.placeholderNode}
              x={toX - nodeDefaultWidth}
              y={toY - nodeDefaultHeight / 2}
              width={nodeDefaultWidth}
              height={61}
              rx={12}
              ry={12}
              stroke="var(--primary)"
              strokeWidth={1}
              fill="var(--nodeBackground)"
            />
          ) : null}
        </Fragment>
      )}

      <circle cx={toX} cy={toY} fill="var(--primary)" r={3} stroke="var(--primary)" strokeWidth={1.5} />
    </g>
  );
});

export default ConnectionLine;
