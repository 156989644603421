import Loading from 'components/Loading';
import ApiRoutes from 'configs/apiRoutes';
import { EStatusCode } from 'configs/enums';
import Messages from 'configs/messages';
import { push } from 'connected-react-router';
import useQuery from 'hooks/useQuery';
import { memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { routes } from 'routers/routes';
import ApiService from 'services/api_service';
import AuthService from 'services/auth_service';
import LinkedinService from 'services/linkedin_service';
import ToastService from 'services/toast_service';

interface LinkedinSsoPageProps {}

const LinkedinSsoPage: React.FC<LinkedinSsoPageProps> = memo((props: LinkedinSsoPageProps) => {
  const query = useQuery();
  const dispatch = useDispatch();

  useEffect(() => {
    if (query) {
      const error = query.get('error');
      const code = query.get('code');
      const newState = query.get('state');
      const oldState = LinkedinService.getState();

      // when user cancel the login process
      if (error === 'user_cancelled_authorize') {
        dispatch(push(routes.public.getStarted));
      }

      // prevent CSRF attack on the Front-end side
      if (newState !== oldState) {
        ToastService.error(Messages.error.default);
        dispatch(push(routes.public.getStarted));
      }

      // if login successfully
      if (code) {
        ApiService.POST(ApiRoutes.auth.sso.linkedinLogin, { code, state: newState, original_state: oldState })
          .then((response) => {
            LinkedinService.removeState();
            AuthService.login(response);
            dispatch(push(routes.private.home));
          })
          .catch((error) => {
            console.log(error);
            if (error?.response?.status === EStatusCode.BadGateway) {
              ToastService.error(Messages.error.default);
            } else {
              ToastService.error(Messages.error.loginByEmail);
            }
            dispatch(push(routes.public.getStarted));
          });
      }
    }
  }, [query]);

  return <Loading />;
});

export default LinkedinSsoPage;
