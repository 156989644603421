import { memo, useCallback, useEffect, useState } from 'react';
import { ErrorCode, FileRejection, useDropzone } from 'react-dropzone';
import classes from './styles.module.scss';
import ToastService from 'services/toast_service';
import clsx from 'clsx';
import Messages from 'configs/messages';
import { MAX_IMAGE_SIZE } from 'configs/constant';

interface DropzoneProps {
  defaultImage?: string;
  label?: string;
  className?: string;
  dropzoneClassName?: string;
  errorMessage?: string;
  onSubmit: (image: File[]) => void;
}

const Dropzone: React.FC<DropzoneProps> = memo((props: DropzoneProps) => {
  const { defaultImage, label, className, dropzoneClassName, errorMessage, onSubmit } = props;

  const [uploadedImageURL, setUploadedImageURL] = useState<string>('');

  useEffect(() => {
    setUploadedImageURL(defaultImage ?? '');
  }, [defaultImage]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles?.[0];

      if (file) {
        const reader = new FileReader();

        reader.onload = (event) => {
          if (typeof event?.target?.result === 'string') {
            setUploadedImageURL(event?.target?.result);
          }
        };
        reader?.readAsDataURL(file);

        onSubmit && onSubmit(acceptedFiles);
      }
    },
    [onSubmit]
  );

  const onDropRejected = (fileRejections: FileRejection[]) => {
    const code = fileRejections?.[0]?.errors?.[0]?.code;
    switch (code) {
      case ErrorCode.TooManyFiles:
        ToastService.error(Messages.error.oneFileOnly);
        break;
      case ErrorCode.FileTooLarge:
        ToastService.error(Messages.error.imageSize);
        break;
      case ErrorCode.FileInvalidType:
        ToastService.error(Messages.error.imageType);
        break;
      default:
        ToastService.error(Messages.error.default);
        break;
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    accept: {
      'image/png': [],
      'image/jpg': [],
      'image/jpeg': [],
    },
    maxSize: MAX_IMAGE_SIZE,
    multiple: false,
  });

  return (
    <div className={clsx(classes.container, className)}>
      {label ? <label>{label}</label> : null}

      <div
        className={clsx(classes.dropzone, dropzoneClassName, {
          [classes.borderNone]: uploadedImageURL && !isDragActive,
        })}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop your image here</p>
        ) : uploadedImageURL ? (
          <img src={uploadedImageURL} alt="Uploaded" />
        ) : (
          <p>
            <b>Choose an image</b> or drag it here
          </p>
        )}
      </div>

      {errorMessage ? <p>{errorMessage}</p> : null}
    </div>
  );
});

export default Dropzone;
