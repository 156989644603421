import { Fragment, memo } from 'react';
import classes from './styles.module.scss';
import { LikeIcon } from 'assets';
import clsx from 'clsx';

interface BudgetProps {
  budget: number;
  isCompleted: boolean;
}

export const Budget: React.FC<BudgetProps> = memo((props: BudgetProps) => {
  const { budget, isCompleted } = props;

  return (
    <div className={classes.container}>
      {/* use != to detect both null and undefined */}
      {budget != null ? (
        <Fragment>
          <p className={clsx({ [classes.dislike]: !isCompleted && budget > 100, [classes.like]: isCompleted })}>{isCompleted ? 100 : budget} %</p>
          <LikeIcon className={clsx({ [classes.dislike]: !isCompleted && budget > 100, [classes.like]: isCompleted })} />
        </Fragment>
      ) : (
        '-'
      )}
    </div>
  );
});

export default Budget;
