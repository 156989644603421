import { Fragment, MouseEvent, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactFlow, {
  addEdge,
  Background,
  useNodesState,
  useEdgesState,
  BackgroundVariant,
  Connection,
  useReactFlow,
  Node,
  NodeChange,
  NodePositionChange,
  Edge,
  updateEdge,
  NodeDimensionChange,
  NodeSelectionChange,
  NodeRemoveChange,
  NodeAddChange,
  NodeResetChange,
  HandleType,
} from 'reactflow';
import {
  DeleteIcon,
  DragIcon,
  EyeHideIcon,
  EyeShowIcon,
  MoreIcon,
  NodeIcon,
  PlusBlackIcon,
  PlusPurpleIcon,
  PlusWhiteIcon,
  SavingIcon,
  UserPlaceholderImage,
  HelpIcon,
} from 'assets';
import 'reactflow/dist/style.css';
import classes from './styles.module.scss';
import CustomNode from './components/CustomNode';
import { v4 as uuid } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import ModalCreatePipeline from './components/ModalCreatePipeline';
import {
  ICreatePipelineFormData,
  IPipelineSidebarFormData,
  INodeSidebar,
  ICreatePipelineModal,
  IEditDeletePipelineModal,
  IResourceConfiguration,
  IPipeline,
  IHistoryPipelinePayload,
  INodeSidebarFormData,
  IResource,
  IAppConfiguration,
  IGlobalAppConfiguration,
  IPipelineSidebar,
  IConnectingNode,
} from 'interfaces/pipeline';
import ToastService from 'services/toast_service';
import Messages from 'configs/messages';
import CustomEdge from './components/CustomEdge';
import Select from 'components/Select';
import Input from 'components/Input';
import DangerButton from 'components/Buttons/DangerButton';
import { BackIcon, CheckmarkIcon, EditIcon } from 'assets';
import { Sidebar as ReactProSidebar } from 'react-pro-sidebar';
import { EHistoryPopover, EProjectSettings, ESelectTheme, EStatusCode } from 'configs/enums';
import { setIsLoadingReducer } from 'redux/reducers/Status/actionTypes';
import ApiService from 'services/api_service';
import ApiRoutes from 'configs/apiRoutes';
import clsx from 'clsx';
import ModalDeletePipeline from './components/ModalDeletePipeline';
import useDebounce from 'hooks/useDebounce';
import { IReducer } from 'redux/reducers';
import { DATE_FORMAT, NULL_OPTION, NULL_COLOR, NULL_LABEL, NULL_VALUE } from 'configs/constant';
import { Box, Skeleton, Stack, Switch, TextField, Tooltip } from '@mui/material';
import dayjs from 'dayjs';
import { IDeleteFormData } from 'interfaces/common';
import {
  getPublishedPipelineListRequest,
  getResourceSummaryRequest,
  setPipelineListReducer,
  setProjectListReducer,
  setPublishedPipelineListReducer,
  setResourceListReducer,
} from 'redux/reducers/Information/actionTypes';
import CommonService from 'services/common_service';
import QueryString from 'qs';
import { push } from 'connected-react-router';
import { routes } from 'routers/routes';
import { IEditProjectUrlParams, IProject, IProjectSidebar, ITaskConfigurationStatus } from 'interfaces/project';
import { useParams } from 'react-router-dom';
import HistoryPopover from './components/HistoryPopover';
import ModalConfirmRevertPipeline from './components/ModalConfirmRevertPipeline';
import Regexes from 'configs/regexes';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import ColorFill from 'components/ColorFill';
import styled from 'styled-components';
import { ColorResult } from 'react-color';
import { setProjectReducer } from 'redux/reducers/Workspace/actionTypes';
import { DragDropContext, Draggable, DraggableProvided, DropResult, Droppable, DroppableProvided } from 'react-beautiful-dnd';
import ConnectionLine from './components/ConnectionLine';
import useCustomScreenToFlowPosition from 'hooks/useCustomScreenToFlowPosition';
import $ from 'jquery';
import 'jqueryui';

export const nodeDefaultWidth = 148;
export const nodeDefaultHeight = 61;
const minZoomValue = 0.1;
const nodeSpacing = 300;
const snapGridValue = 50;
const initialNodes = [];
const initialEdges = [];
const nodeTypes = { customNode: CustomNode };
const edgeTypes = { customEdge: CustomEdge };

const historySkeletonCount = 3;

const StatusInput = styled(TextField)<{ $color?: string }>`
  .MuiInput-underline::before {
    display: none;
  }
  .MuiInput-underline::after {
    border-color: ${(props) => props?.$color ?? 'var(--gray)'};
  }
`;

interface PipelineSettingsProps {
  previewId: string;
  setPreviewId: React.Dispatch<React.SetStateAction<string>>;
  activePipelineId: string;
  setActivePipelineId: React.Dispatch<React.SetStateAction<string>>;
  isDraftCopied: React.MutableRefObject<boolean>;
  projectSidebar: IProjectSidebar;
  setProjectSidebar: React.Dispatch<React.SetStateAction<IProjectSidebar>>;
  isSaving: boolean;
  setIsSaving: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEmpty: React.Dispatch<React.SetStateAction<boolean>>;
}

const PipelineSettings = memo((props: PipelineSettingsProps) => {
  const {
    previewId,
    setPreviewId,
    activePipelineId,
    setActivePipelineId,
    isDraftCopied,
    projectSidebar,
    setProjectSidebar,
    isSaving,
    setIsSaving,
    setIsEmpty,
  } = props;

  const dispatch = useDispatch();
  const customScreenToFlowPosition = useCustomScreenToFlowPosition();
  const { workspaceId, projectId } = useParams<IEditProjectUrlParams>();

  const { name, type, activePipelineId: activePipelineIdSearchParam } = QueryString.parse(window?.location?.search);

  const { workspace, project } = useSelector((state: IReducer) => state?.workspace);
  const { publishedPipelineList, projectList, pipelineList, resourceList, resourceSummary, globalAppConfigurations } = useSelector(
    (state: IReducer) => state?.information
  );

  const [autoSavePipeline, setAutoSavePipeline] = useState<{ id: string }>({ id: null });
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [changeProjectId, setChangeProjectId] = useState<string>(null);
  const [pipelineSidebar, setPipelineSidebar] = useState<IPipelineSidebar>({ isOpen: false, data: null });
  const [nodeSidebar, setNodeSidebar] = useState<INodeSidebar>({ isOpen: false, node: null });
  const [isEditingStatus, setIsEditingStatus] = useState<boolean>(false);
  const [isEditStageName, setIsEditStageName] = useState<boolean>(false);
  const [isEditPipelineStatus, setIsEditPipelineStatus] = useState<boolean>(false);
  const [isEditPipelineName, setIsEditPipelineName] = useState<boolean>(false);
  const [focusId, setFocusId] = useState<string>(null);
  const [resourcesDeleted, setResourcesDeleted] = useState<string[]>([]);
  const [isShowHistory, setIsShowHistory] = useState<boolean>(true);
  const [historyPopoverAnchorElement, setHistoryPopoverAnchorElement] = useState<SVGElement>(null);
  const [isOpenModalRevertPipeline, setIsOpenModalRevertPipeline] = useState<boolean>(false);
  const [modalCreatePipeline, setModalCreatePipeline] = useState<ICreatePipelineModal>({
    isOpen: false,
  });
  const [modalDeletePipeline, setModalDeletePipeline] = useState<IEditDeletePipelineModal>({
    isOpen: false,
    data: null,
  });
  const [addAppSettings, setAddAppSettings] = useState<{ isAdding: boolean; data: { appName: string; appVersion: string } }>({
    isAdding: false,
    data: null,
  });
  const [addPipelineAppSettings, setAddPipelineAppSettings] = useState<{ isAdding: boolean; data: { appName: string; appVersion: string } }>({
    isAdding: false,
    data: null,
  });
  const [projectTaskStatuses, setProjectTaskStatuses] = useState<ITaskConfigurationStatus[]>([]);
  const [pipelineTaskStatuses, setPipelineTaskStatuses] = useState<ITaskConfigurationStatus[]>([]);
  const [isDraggingNewNode, setIsDraggingNewNode] = useState<boolean>(false);

  const reactFlowRef = useRef<HTMLDivElement>();
  const XPosition = useRef<number>(100);
  const YPosition = useRef<number>(100);
  const nodeSidebarRef = useRef<HTMLDivElement>();
  const projectSidebarRef = useRef<HTMLDivElement>();
  const pipelineSidebarRef = useRef<HTMLDivElement>();
  const allowFetch = useRef<boolean>(true);
  const nodeListRef = useRef<Node[]>();
  const isEdgeUpdated = useRef<boolean>(true);
  const isSaved = useRef<boolean>(true);
  const isChangeProject = useRef<boolean>(false);
  const historyPipelinePayload = useRef<IHistoryPipelinePayload>();
  const connectingNode = useRef<IConnectingNode>(null);
  const blockSaveResourceConfig = useRef<boolean>(false);

  const reactFlow = useReactFlow();
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      stageName: Yup.string()
        .required('This field is required.')
        .matches(Regexes.configurationName, 'Only alphanumeric characters, underscores, and dashes are allowed.'),
      app_name: Yup.object(),
      app_version: Yup.object(),
    });
  }, []);

  const pipelineValidationSchema = useMemo(() => {
    return Yup.object().shape({
      pipelineName: Yup.string()
        .required('This field is required.')
        .matches(Regexes.configurationName, 'Only alphanumeric characters, underscores, and dashes are allowed.'),
    });
  }, []);

  const {
    register,
    getValues,
    setValue,
    control,
    watch,
    formState: { errors },
    setFocus,
  } = useForm<INodeSidebarFormData>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const {
    register: pipelineRegister,
    getValues: pipelineGetValues,
    setValue: pipelineSetValue,
    formState: { errors: pipelineErrors },
  } = useForm<IPipelineSidebarFormData>({
    resolver: yupResolver(pipelineValidationSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    return () => {
      setPreviewId(null);
      setNodeSidebar({ isOpen: false, node: null });
      setProjectSidebar({ isOpen: false, data: null });
      setPipelineSidebar({ isOpen: false, data: null });
    };
  }, []);

  useEffect(() => {
    if (project?.task_configuration?.statuses?.length) {
      setProjectTaskStatuses(project?.task_configuration?.statuses);
    }
  }, [project]);

  useEffect(() => {
    nodeListRef.current = nodes;
    if (!nodes?.length) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
    }
  }, [nodes]);

  useEffect(() => {
    if (isEditStageName) {
      setFocus('stageName');
    }
  }, [isEditStageName]);

  useEffect(() => {
    if (nodeSidebar?.isOpen) {
      nodeSidebarRef?.current?.scrollTo({ top: 0, behavior: 'smooth' });
      setValue('app_name', {
        value: nodeSidebar?.node?.data?.app_name,
        label: nodeSidebar?.node?.data?.app_name === NULL_VALUE ? NULL_LABEL : nodeSidebar?.node?.data?.app_name,
      });
      setValue(
        'app_version',
        nodeSidebar?.node?.data?.app_version === NULL_VALUE
          ? {
              value: NULL_VALUE,
              label: NULL_LABEL,
            }
          : {
              value: nodeSidebar?.node?.data?.app_version,
              label: nodeSidebar?.node?.data?.app_name === NULL_VALUE ? NULL_LABEL : nodeSidebar?.node?.data?.app_version,
            }
      );
    } else {
      setValue('stageName', '');
      setIsEditStageName(false);
    }
  }, [nodeSidebar]);

  useEffect(() => {
    if (pipelineSidebar?.isOpen) {
      pipelineSidebarRef?.current?.scrollTo({ top: 0, behavior: 'smooth' });
      if (pipelineSidebar?.data?.task_configuration?.statuses?.length) {
        setPipelineTaskStatuses(pipelineSidebar?.data?.task_configuration?.statuses || []);
      }
    } else {
      pipelineSetValue('pipelineName', '');
      setIsEditPipelineName(false);
      setAddPipelineAppSettings({ isAdding: false, data: null });
      setPipelineTaskStatuses([]);
    }
  }, [pipelineSidebar]);

  useEffect(() => {
    if (projectSidebar?.isOpen) {
      projectSidebarRef?.current?.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      setIsEditingStatus(false);
      setAddAppSettings({ isAdding: false, data: null });
    }
  }, [projectSidebar]);

  useEffect(() => {
    if (autoSavePipeline?.id) {
      onSaveResourceConfigs(autoSavePipeline?.id);
    }
  }, [autoSavePipeline]);

  useEffect(() => {
    if (isDraftCopied?.current) {
      isDraftCopied.current = false;
      allowFetch.current = true;
      setPreviewId(null);
    }
  }, [pipelineList]);

  useEffect(() => {
    if (nodeSidebar?.isOpen) {
      setProjectSidebar({ isOpen: false, data: null });
      setPipelineSidebar({ isOpen: false, data: null });
    }
  }, [nodeSidebar]);

  useEffect(() => {
    if (pipelineSidebar?.isOpen) {
      setProjectSidebar({ isOpen: false, data: null });
      setNodeSidebar({ isOpen: false, node: null });
    }
  }, [pipelineSidebar]);

  useEffect(() => {
    if (projectSidebar?.isOpen) {
      setNodeSidebar({ isOpen: false, node: null });
      setPipelineSidebar({ isOpen: false, data: null });
    }
  }, [projectSidebar]);

  useEffect(() => {
    if (addAppSettings?.isAdding && addAppSettings?.data?.appName && addAppSettings?.data?.appVersion) {
      dispatch(setIsLoadingReducer(true));
      ApiService.POST(ApiRoutes.project.appConfig.default.replace(':projectId', projectId), {
        app_name: addAppSettings?.data?.appName,
        app_version: addAppSettings?.data?.appVersion,
      })
        .then((response) => {
          const updatedProject = response?.entities?.[0];

          dispatch(
            setProjectListReducer(
              projectList?.map((item: IProject) => {
                if (item?._id === updatedProject?._id) {
                  return updatedProject;
                }
                return item;
              })
            )
          );
          dispatch(setProjectReducer(updatedProject));

          setAddAppSettings({ isAdding: false, data: null });
        })
        .catch((error) => {
          if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
            ToastService.error(Messages.error.accessDenied);
            return;
          }
          console.log(error);
          ToastService.error(Messages.error.default);
        })
        .finally(() => dispatch(setIsLoadingReducer(false)));
    }
  }, [addAppSettings]);

  useEffect(() => {
    if (addPipelineAppSettings?.isAdding && addPipelineAppSettings?.data?.appName && addPipelineAppSettings?.data?.appVersion) {
      dispatch(setIsLoadingReducer(true));
      ApiService.POST(ApiRoutes.pipeline.appConfig.default.replace(':pipelineId', pipelineSidebar?.data?._id), {
        app_name: addPipelineAppSettings?.data?.appName,
        app_version: addPipelineAppSettings?.data?.appVersion,
      })
        .then((response) => {
          const updatedPipeline = response?.entities?.[0];
          dispatch(
            setPipelineListReducer({
              ...pipelineList,
              data: pipelineList?.data?.map((item: IPipeline) => {
                if (item?._id === updatedPipeline?._id) {
                  return updatedPipeline;
                }
                return item;
              }),
            })
          );

          setPipelineSidebar({
            ...pipelineSidebar,
            data: updatedPipeline,
          });
          setAddPipelineAppSettings({ isAdding: false, data: null });
        })
        .catch((error) => {
          if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
            ToastService.error(Messages.error.accessDenied);
            return;
          }
          console.log(error);
          ToastService.error(Messages.error.default);
        })
        .finally(() => dispatch(setIsLoadingReducer(false)));
    }
  }, [addPipelineAppSettings]);

  useEffect(() => {
    isChangeProject.current = true;
    setNodes([]);
    setEdges([]);
    setNodeSidebar({ isOpen: false, node: null });
    // setProjectSidebar({ isOpen: false, data: null }); // shouldn't use here, use in header component will be smoother
    if (project && project?.pipeline_ids?.length && !project?.pipeline_ids?.includes(activePipelineIdSearchParam as string)) {
      setActivePipelineId(null);
    }
  }, [project, activePipelineIdSearchParam]);

  useEffect(() => {
    if (previewId && nodeSidebar?.isOpen) {
      setNodeSidebar({ isOpen: false, node: null });
    }
  }, [previewId, nodeSidebar]);

  useEffect(() => {
    setPipelineSidebar({ isOpen: false, data: null });
  }, [activePipelineId]);

  useEffect(() => {
    if (activePipelineId && activePipelineId !== NULL_VALUE && publishedPipelineList?.pipelineId !== activePipelineId) {
      dispatch(getPublishedPipelineListRequest(activePipelineId));
    }
  }, [publishedPipelineList, activePipelineId]);

  useEffect(() => {
    if (pipelineList?.data?.length) {
      let offsetX = null;
      let offsetY = null;

      // use jQuery to center the dragging element with the mouse cursor
      $('#new-stage-button').draggable({
        helper: 'clone',
        start: () => {
          offsetX = null;
          offsetY = null;
          setIsDraggingNewNode(true);
        },
        drag: (event, ui) => {
          const zoomLevel = reactFlow?.getViewport()?.zoom;

          if (offsetX === null) {
            offsetX = event.clientX - ui.offset.left;
            offsetY = event.clientY - ui.offset.top;
          }

          ui.helper.css({ transform: `scale(${zoomLevel})` });
          ui.position.left += offsetX - Math.floor(ui.helper.outerWidth() / 2);
          ui.position.top += offsetY - Math.floor(ui.helper.outerHeight() / 2);
        },
        stop: (event) => {
          // when debugging this with F12, please use 100% zoom, otherwise, the coordinators will not be correct
          setIsDraggingNewNode(false);

          const { clientX, clientY } = event;
          const rect = reactFlowRef?.current?.getBoundingClientRect();
          const isWithinContainer = clientX >= rect?.left && clientX <= rect?.right && clientY >= rect?.top && clientY <= rect?.bottom;

          if (isWithinContainer) {
            onAddNode(null, { clientX, clientY });
          }
        },
      });

      if (!activePipelineId || !pipelineList?.data?.find((pipeline: IPipeline) => pipeline?._id === activePipelineId)) {
        setActivePipelineId(pipelineList?.data?.[0]?._id);
        dispatch(
          push({
            pathname: routes.private.project.edit.replace(':workspaceId', workspaceId).replace(':projectId', projectId),
            search: `&name=${encodeURIComponent(name as string)}&type=${type}&activePipelineId=${pipelineList?.data?.[0]?._id ?? NULL_VALUE}&workspaceId=${
              workspace?._id ?? NULL_VALUE
            }`,
          })
        );
      }
    }
  }, [pipelineList, activePipelineId, reactFlow]);

  useEffect(() => {
    const subscription = watch((formValue, { name, type }) => {
      if (type === 'change') {
        if (name === 'app_name' || name === 'app_version') {
          const newAppName = formValue?.app_name?.value || NULL_VALUE;
          let newAppVersion = formValue?.app_version?.value || NULL_VALUE;

          if (name === 'app_name') {
            newAppVersion = NULL_VALUE;
          }
          const updatedNode = {
            ...nodeSidebar?.node,
            data: { ...nodeSidebar?.node?.data, app_name: newAppName, app_version: newAppVersion },
          };
          isSaved.current = false;

          setNodeSidebar({
            ...nodeSidebar,
            node: updatedNode,
          });
          setNodes(
            nodes?.map((node) => {
              if (node?.id === nodeSidebar?.node?.id) {
                return updatedNode;
              }
              return node;
            })
          );

          setAutoSavePipeline({ id: activePipelineId });
        }
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [watch, nodes, nodeSidebar]);

  useEffect(() => {
    if (publishedPipelineList?.data?.find((item: IPipeline) => item?._id === previewId)) {
      let maxXPosition = 0;
      const activePipeline = publishedPipelineList?.data?.find((item: IPipeline) => item?._id === previewId);

      const nodeList: Node[] = activePipeline?.resource_configurations?.map((node) => {
        maxXPosition = node?.position_x > maxXPosition ? node?.position_x : maxXPosition;
        return {
          id: node?._id,
          type: 'customNode',
          position: { x: node?.position_x ?? 0, y: node?.position_y ?? 0 },
          data: {
            label: node?.name ?? 'N/A',
            app_name: node?.app_settings?.app_name,
            app_version: node?.app_settings?.app_version,
          },
        };
      });

      const edgeList: Edge[] = [];
      activePipeline?.resource_configurations?.forEach((node: IResourceConfiguration) => {
        node?.output_resource_config_ids?.forEach((resourceNode) => {
          const id = uuid();
          edgeList?.push({
            id,
            source: node?._id,
            target: resourceNode,
            type: 'customEdge',
          });
        });
      });

      isSaved.current = true;
      setNodes(nodeList ?? []);
      setEdges(edgeList ?? []);
      setFocusId(nodeList?.slice(-1)?.[0]?.id);
    }
  }, [previewId, publishedPipelineList]);

  useEffect(() => {
    if (
      isChangeProject?.current &&
      project?.pipeline_ids?.includes(activePipelineId) &&
      pipelineList?.data?.find((pipeline: IPipeline) => pipeline?._id === activePipelineId)
    ) {
      setChangeProjectId(project?._id);
    }
  }, [project, pipelineList, activePipelineId]);

  useEffect(() => {
    if (!previewId) {
      if (allowFetch?.current || changeProjectId) {
        isSaved.current = true;
        const activePipeline = pipelineList?.data?.find((item: IPipeline) => item?._id === activePipelineId);

        if (pipelineList?.data?.length && activePipeline) {
          const nodeList: Node[] = activePipeline?.resource_configurations?.map((node) => {
            return {
              id: node?._id,
              type: 'customNode',
              position: { x: node?.position_x ?? 0, y: node?.position_y ?? 0 },
              data: {
                label: node?.name ?? 'N/A',
                app_name: node?.app_settings?.app_name,
                app_version: node?.app_settings?.app_version,
              },
            };
          });

          const edgeList: Edge[] = [];
          activePipeline?.resource_configurations?.forEach((node: IResourceConfiguration) => {
            node?.output_resource_config_ids?.forEach((resourceNode) => {
              const id = uuid();
              edgeList?.push({
                id,
                source: node?._id,
                target: resourceNode,
                type: 'customEdge',
              });
            });
          });

          if (nodeList?.length) {
            XPosition.current = nodeList?.slice(-1)?.[0]?.position?.x + nodeSpacing;
            YPosition.current = nodeList?.slice(-1)?.[0]?.position?.y;
          } else {
            XPosition.current = nodeSpacing;
          }

          allowFetch.current = false;
          isChangeProject.current = false;
          setNodes(nodeList ?? []);
          setEdges(edgeList ?? []);
          setChangeProjectId(null);
          setFocusId(nodeList?.slice(-1)?.[0]?.id);
        } else {
          setNodes([]);
          setEdges([]);
        }
      }
    }
  }, [pipelineList, activePipelineId, changeProjectId, previewId]);

  useEffect(() => {
    if (isDraggingNewNode) {
      nodeSidebar?.isOpen && setNodeSidebar({ isOpen: false, node: null });
      projectSidebar?.isOpen && setProjectSidebar({ isOpen: false, data: null });
      pipelineSidebar?.isOpen && setPipelineSidebar({ isOpen: false, data: null });
    }
  }, [isDraggingNewNode, nodeSidebar, projectSidebar, pipelineSidebar]);

  const buildGraph = (edges: Edge[]) => {
    const graph = {};
    for (const edge of edges) {
      if (!graph?.[edge?.source]) {
        graph[edge?.source] = [];
      }
      graph?.[edge?.source]?.push(edge?.target);
    }
    return graph;
  };

  const checkCycle = (edges: Edge[]) => {
    const graph: { [key: string]: string } = buildGraph(edges);
    const visited: { [key: string]: boolean } = {};
    const recStack: { [key: string]: boolean } = {};

    const isCyclic = (node: string) => {
      if (!visited?.[node]) {
        visited[node] = true;
        recStack[node] = true;
        const neighbors = graph?.[node] || [];
        for (const neighbor of neighbors) {
          if (!visited?.[neighbor]) {
            if (isCyclic(neighbor)) {
              return true;
            }
          } else if (recStack?.[neighbor]) {
            return true;
          }
        }
      }
      recStack[node] = false;
      return false;
    };

    for (const node in graph) {
      if (!visited?.[node]) {
        if (isCyclic(node)) {
          return true;
        }
      }
    }
    return false;
  };

  const onConnect = useCallback(
    (params: Connection) => {
      setEdges((edges) =>
        addEdge(
          {
            ...params,
            type: 'customEdge',
          },
          edges
        )
      );
      setAutoSavePipeline({ id: activePipelineId });
    },
    [setEdges, activePipelineId]
  );

  const onConnectStart = useCallback(
    (_, { nodeId, handleType }: { nodeId: string; handleType: HandleType }) => {
      connectingNode.current = {
        nodeId: nodeId,
        connectType: handleType,
      };
    },
    [setEdges]
  );

  const onConnectEnd = (event: globalThis.MouseEvent | TouchEvent) => {
    if (!connectingNode?.current || !(event instanceof globalThis.MouseEvent)) {
      return;
    }

    const targetIsReactFlowPane = (event?.target as HTMLDivElement)?.classList?.contains('react-flow__pane');

    if (targetIsReactFlowPane) {
      const connection: IConnectingNode = connectingNode?.current;

      const existingSource = edges?.some((edge) => edge?.source === connection?.nodeId);
      const existingTarget = edges?.some((edge) => edge?.target === connection?.nodeId);

      const screenToFlowPosition = customScreenToFlowPosition(
        {
          x: event?.clientX ?? XPosition?.current,
          y: event?.clientY ?? YPosition?.current,
        },
        { snapToGrid: false }
      );

      if (connection?.connectType === 'source' && !existingSource) {
        XPosition.current = screenToFlowPosition?.x;
        YPosition.current = screenToFlowPosition?.y - nodeDefaultHeight / 2;
        onAddNode(connection);
      } else if (connection?.connectType === 'target' && !existingTarget) {
        XPosition.current = screenToFlowPosition?.x - nodeDefaultWidth;
        YPosition.current = screenToFlowPosition?.y - nodeDefaultHeight / 2;
        onAddNode(connection);
      }
    }

    connectingNode.current = null;
  };

  const onEdgeUpdateStart = useCallback(() => {
    isEdgeUpdated.current = false;
  }, [setEdges]);

  const onEdgeUpdate = useCallback(
    (oldEdge: Edge, newConnection: Connection) => {
      isEdgeUpdated.current = true;
      setEdges((edges) => updateEdge(oldEdge, newConnection, edges));
    },
    [setEdges]
  );

  const onEdgeUpdateEnd = useCallback(
    (_: globalThis.MouseEvent | TouchEvent, edge: Edge) => {
      if (!isEdgeUpdated?.current) {
        setEdges((edges) => {
          return edges?.filter((item) => item?.id !== edge?.id);
        });
      }
      setAutoSavePipeline({ id: activePipelineId });
      isEdgeUpdated.current = true;
    },
    [setEdges, activePipelineId]
  );

  const isValidConnection = (connection: Connection) => {
    const { source, target } = connection;

    // A node can only connect to one node
    const existingSource = edges?.some((edge) => edge?.source === source);
    const existingTarget = edges?.some((edge) => edge?.target === target);

    // Check cycle
    const hasCycle = checkCycle([...edges, { ...connection }] as Edge[]);

    if (existingSource || existingTarget || hasCycle) {
      return false;
    }
    return true;
  };

  const onAddNode = (additionalConnection?: IConnectingNode, dragInfo?: { clientX: number; clientY: number }) => {
    if (blockSaveResourceConfig?.current) {
      return;
    }

    setIsCreating(true);

    if (additionalConnection) {
      blockSaveResourceConfig.current = true;
    }

    let dragX = null;
    let dragY = null;
    if (dragInfo) {
      const screenToFlowPosition = customScreenToFlowPosition(
        {
          x: dragInfo?.clientX ?? XPosition?.current,
          y: dragInfo?.clientY ?? YPosition?.current,
        },
        { snapToGrid: false }
      );
      dragX = screenToFlowPosition?.x - nodeDefaultWidth / 2;
      dragY = screenToFlowPosition?.y - nodeDefaultHeight / 2;
    }

    ApiService.PATCH(ApiRoutes.resourceConfig.bulk.replace(':pipelineId', activePipelineId), {
      create: [
        {
          name: `Stage${nodeListRef?.current?.length + 1}`,
          app_settings: {
            app_name: NULL_LABEL,
            app_version: NULL_VALUE,
          },
          icon: '',
          inherit_parent_app_settings: false,
          inherit_parent_task_statuses: false,
          task_statuses: [],
          output_resource_config_ids: [],
          position_x: dragInfo ? dragX : XPosition?.current,
          position_y: dragInfo ? dragY : YPosition?.current,
        },
      ],
    })
      .then((response) => {
        if (additionalConnection) {
          blockSaveResourceConfig.current = false;
        } else {
          isSaved.current = true;
        }

        if (dragInfo) {
          XPosition.current = dragX + nodeSpacing;
          YPosition.current = dragY;
        } else {
          XPosition.current += nodeSpacing;
        }

        const updatedPipeline: IPipeline = response?.entities?.[0];
        const createdNode: IResourceConfiguration = updatedPipeline?.resource_configurations?.slice(-1)?.[0];
        const newNode = {
          id: createdNode?._id,
          type: 'customNode',
          position: { x: createdNode?.position_x, y: createdNode?.position_y },
          data: {
            label: createdNode?.name,
            app_name: createdNode?.app_settings?.app_name,
            app_version: createdNode?.app_settings?.app_version,
          },
          selected: true,
        };

        if (additionalConnection) {
          if (additionalConnection?.connectType === 'source') {
            setEdges([
              ...edges,
              {
                id: uuid(),
                source: additionalConnection?.nodeId,
                target: createdNode?._id,
                type: 'customEdge',
              },
            ]);
          } else {
            setEdges([
              ...edges,
              {
                id: uuid(),
                source: createdNode?._id,
                target: additionalConnection?.nodeId,
                type: 'customEdge',
              },
            ]);
          }
        }
        setNodes([...nodeListRef.current.map((node) => ({ ...node, selected: false })), newNode]);
        setFocusId(newNode?.id);
        setNodeSidebar({ isOpen: true, node: newNode });
        setValue('stageName', newNode?.data?.label);
        setFocus('stageName');
        setIsEditStageName(true);
        dispatch(
          setPipelineListReducer({
            ...pipelineList,
            data: pipelineList?.data?.map((item: IPipeline) => {
              if (item?._id === updatedPipeline?._id) {
                return updatedPipeline;
              }
              return item;
            }),
          })
        );
      })
      .catch((error) => {
        if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
          ToastService.error(Messages.error.accessDenied);
          return;
        }
        console.log(error);
        ToastService.error(Messages.error.default);
      })
      .finally(() => {
        setIsCreating(false);
      });
  };

  const onDeleteNode = () => {
    reactFlow?.deleteElements({ nodes: [nodeSidebar?.node] });
    if (!nodeSidebar?.node?.data?.newNode) {
      setResourcesDeleted([...resourcesDeleted, nodeSidebar?.node?.id]);
    }
    setNodeSidebar({ isOpen: false, node: null });
  };

  const onNodeClick = (event: MouseEvent, node: Node) => {
    if (event?.detail === 2) {
      setValue('stageName', node?.data?.label);
      setIsEditStageName(true);
    } else {
      setValue('stageName', '');
      setIsEditStageName(false);
    }
    if (nodeListRef?.current?.filter((node: Node) => node?.selected)?.length > 1) {
      setNodeSidebar({ isOpen: false, node: null });
    } else {
      setNodeSidebar({ isOpen: true, node: { ...node } });
    }
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(projectTaskStatuses);
    const [reorderedItem] = items.splice(result?.source?.index, 1);
    items?.splice(result?.destination?.index, 0, reorderedItem);

    setProjectTaskStatuses(items);
  };

  const onDragPipelineTaskEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const items = Array.from(pipelineTaskStatuses ?? []);
    const [reorderedItem] = items.splice(result?.source?.index, 1);
    items?.splice(result?.destination?.index, 0, reorderedItem);

    setPipelineTaskStatuses(items);
  };

  const onCreatePipeline = (data: ICreatePipelineFormData) => {
    dispatch(setIsLoadingReducer(true));
    ApiService.POST(ApiRoutes.pipeline.default, {
      name: data?.pipelineName,
      project_id: project?._id,
      template_pipeline_id: data?.selectedPipelineTemplate?.value === NULL_OPTION?.value ? undefined : data?.selectedPipelineTemplate?.value,
    })
      .then((response) => {
        const createdPipeline: IPipeline = response?.entities?.[0];

        dispatch(
          setProjectListReducer(
            projectList?.map((item: IProject) => {
              if (item?._id === project?._id) {
                return { ...item, pipeline_ids: [...(item?.pipeline_ids ?? []), createdPipeline?._id] };
              }
              return item;
            })
          )
        );
        dispatch(setPipelineListReducer({ ...pipelineList, data: [...pipelineList.data, createdPipeline] }));
        onCloseModals();
        ToastService.success(Messages.success.created);

        allowFetch.current = true;
        previewId && setPreviewId(null);
        setActivePipelineId(createdPipeline?._id);
        nodeSidebar?.isOpen && setNodeSidebar({ isOpen: false, node: null });
        dispatch(
          push({
            pathname: routes.private.project.edit.replace(':workspaceId', workspaceId).replace(':projectId', projectId),
            search: `&name=${encodeURIComponent(name as string)}&type=${type}&activePipelineId=${createdPipeline?._id ?? NULL_VALUE}&workspaceId=${
              workspace?._id ?? NULL_VALUE
            }`,
          })
        );
      })
      .catch((error) => {
        if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
          ToastService.error(Messages.error.accessDenied);
          return;
        }
        console.log(error);
        ToastService.error(Messages.error.default);
      })
      .finally(() => dispatch(setIsLoadingReducer(false)));
  };

  const onSavePipelineName = () => {
    dispatch(setIsLoadingReducer(true));
    const name = pipelineGetValues('pipelineName');
    ApiService.PATCH(ApiRoutes.pipeline.info.replace(':pipelineId', pipelineSidebar?.data?._id), {
      name: name,
    })
      .then((response) => {
        const updatedPipeline: IPipeline = response?.entities?.[0];
        dispatch(
          setPipelineListReducer({
            ...pipelineList,
            data: pipelineList?.data?.map((item: IPipeline) => {
              if (item?._id === updatedPipeline?._id) {
                return updatedPipeline;
              }
              return item;
            }),
          })
        );
        setIsEditPipelineName(false);
        pipelineSetValue('pipelineName', '');
        setPipelineSidebar({
          ...pipelineSidebar,
          data: updatedPipeline,
        });
        ToastService.success(Messages.success.default);
      })
      .catch((error) => {
        if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
          ToastService.error(Messages.error.accessDenied);
          return;
        }
        console.log(error);
        ToastService.error(Messages.error.default);
      })
      .finally(() => dispatch(setIsLoadingReducer(false)));
  };

  const onChangeInheritParentAppConfiguration = () => {
    const inheritStatus = !pipelineSidebar?.data?.inherit_parent_app_configuration;

    setPipelineSidebar({
      ...pipelineSidebar,
      data: { ...pipelineSidebar?.data, inherit_parent_app_configuration: inheritStatus },
    });

    dispatch(setIsLoadingReducer(true));
    ApiService.PATCH(ApiRoutes.pipeline.info.replace(':pipelineId', pipelineSidebar?.data?._id), {
      inherit_parent_app_configuration: inheritStatus,
    })
      .then((response) => {
        const updatedPipeline: IPipeline = response?.entities?.[0];
        dispatch(
          setPipelineListReducer({
            ...pipelineList,
            data: pipelineList?.data?.map((item: IPipeline) => {
              if (item?._id === updatedPipeline?._id) {
                return updatedPipeline;
              }
              return item;
            }),
          })
        );
        setPipelineSidebar({
          ...pipelineSidebar,
          data: updatedPipeline,
        });
      })
      .catch((error) => {
        if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
          ToastService.error(Messages.error.accessDenied);
          return;
        }
        console.log(error);
        ToastService.error(Messages.error.default);

        setPipelineSidebar({
          ...pipelineSidebar,
          data: { ...pipelineSidebar?.data, inherit_parent_app_configuration: !inheritStatus },
        });
      })
      .finally(() => dispatch(setIsLoadingReducer(false)));
  };

  const onChangeInheritParentTaskConfiguration = () => {
    const inheritStatus = !pipelineSidebar?.data?.inherit_parent_task_configuration;

    setPipelineSidebar({
      ...pipelineSidebar,
      data: { ...pipelineSidebar?.data, inherit_parent_task_configuration: inheritStatus },
    });

    dispatch(setIsLoadingReducer(true));
    ApiService.PATCH(ApiRoutes.pipeline.info.replace(':pipelineId', pipelineSidebar?.data?._id), {
      inherit_parent_task_configuration: inheritStatus,
    })
      .then((response) => {
        const updatedPipeline: IPipeline = response?.entities?.[0];
        dispatch(
          setPipelineListReducer({
            ...pipelineList,
            data: pipelineList?.data?.map((item: IPipeline) => {
              if (item?._id === updatedPipeline?._id) {
                return updatedPipeline;
              }
              return item;
            }),
          })
        );
        setPipelineSidebar({
          ...pipelineSidebar,
          data: updatedPipeline,
        });
      })
      .catch((error) => {
        if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
          ToastService.error(Messages.error.accessDenied);
          return;
        }
        console.log(error);
        ToastService.error(Messages.error.default);

        setPipelineSidebar({
          ...pipelineSidebar,
          data: { ...pipelineSidebar?.data, inherit_parent_task_configuration: !inheritStatus },
        });
      })
      .finally(() => dispatch(setIsLoadingReducer(false)));
  };

  const onDeletePipeline = (_: IDeleteFormData) => {
    const deletePipelineId = modalDeletePipeline?.data?._id;
    dispatch(setIsLoadingReducer(true));
    ApiService.DELETE(ApiRoutes.pipeline.info.replace(':pipelineId', deletePipelineId))
      .then(() => {
        dispatch(
          setProjectListReducer(
            projectList?.map((project: IProject) => {
              if (project?._id === projectId) {
                return {
                  ...project,
                  pipeline_ids: project?.pipeline_ids?.filter((item: string) => item !== deletePipelineId),
                };
              }
              return project;
            })
          )
        );
        dispatch(setPipelineListReducer({ ...pipelineList, data: pipelineList?.data?.filter((item: IPipeline) => item?._id !== deletePipelineId) }));

        onCloseModals();
        ToastService.success(Messages.success.deleted);

        if (activePipelineId && activePipelineId !== NULL_VALUE && activePipelineId === deletePipelineId) {
          dispatch(
            push({
              pathname: routes.private.project.edit.replace(':workspaceId', workspace?._id).replace(':projectId', project?._id),
              search: `&name=${encodeURIComponent(project?.name)}&type=${EProjectSettings.Pipeline}&activePipelineId=${NULL_VALUE}&workspaceId=${
                workspace?._id ?? NULL_VALUE
              }`,
            })
          );
        }
      })
      .catch((error) => {
        if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
          ToastService.error(Messages.error.accessDenied);
          return;
        }
        console.log(error);
        ToastService.error(Messages.error.default);
      })
      .finally(() => dispatch(setIsLoadingReducer(false)));
  };

  const onSaveStageName = () => {
    isSaved.current = false;

    const name = getValues('stageName');

    if (
      nodeListRef?.current?.filter((node: Node) => node?.data?.label !== nodeSidebar?.node?.data?.label)?.find((node: Node) => node?.data?.label === name)
    ) {
      return ToastService.error(Messages.error.duplicatedStageName);
    }

    const updatedNode = { ...nodeSidebar?.node, data: { ...nodeSidebar?.node?.data, label: name } };

    setValue('stageName', '');
    setIsEditStageName(false);

    setNodeSidebar({
      ...nodeSidebar,
      node: updatedNode,
    });
    setNodes(
      nodes?.map((node) => {
        if (node?.id === nodeSidebar?.node?.id) {
          return updatedNode;
        }
        return node;
      })
    );
  };

  const onCloseModals = () => {
    setModalCreatePipeline({ isOpen: false });
    setModalDeletePipeline({ isOpen: false, data: null });
    setIsOpenModalRevertPipeline(false);
  };

  const onSaveResourceConfigs = useDebounce((activePipelineId: string) => {
    const updatePayload: IResourceConfiguration[] = nodes
      ?.filter((node) => !node?.data?.newNode)
      ?.map((node) => {
        const outputResourceIds = edges?.filter((edge) => edge?.source === node?.id).map((edge) => edge?.target);
        return {
          _id: node?.id,
          name: node?.data?.label,
          app_settings: {
            app_name: node?.data?.app_name ?? NULL_LABEL,
            app_version: node?.data?.app_version ?? NULL_VALUE,
          },
          icon: '',
          inherit_parent_app_settings: false,
          inherit_parent_task_statuses: false,
          task_statuses: [],
          output_resource_config_ids: outputResourceIds,
          position_x: node?.position?.x || 100,
          position_y: node?.position?.y || 100,
        };
      });

    const deletePayload = resourcesDeleted?.map((item) => {
      return { _id: item };
    });

    setIsSaving(true);
    ApiService.PATCH(ApiRoutes.resourceConfig.bulk.replace(':pipelineId', activePipelineId), {
      update: updatePayload,
      delete: deletePayload,
    })
      .then((response) => {
        const updatedPipeline: IPipeline = response?.entities?.[0];
        setResourcesDeleted([]);
        dispatch(
          setPipelineListReducer({
            ...pipelineList,
            data: pipelineList?.data?.map((item: IPipeline) => {
              if (item?._id === updatedPipeline?._id) {
                return updatedPipeline;
              }
              return item;
            }),
          })
        );
      })
      .catch((error) => {
        if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
          ToastService.error(Messages.error.accessDenied);
          return;
        }
        console.log(error);
        ToastService.error(Messages.error.default);
      })
      .finally(() => {
        setIsSaving(false);
        setAutoSavePipeline({ id: null });
      });
  }, 1000);

  const onRevertPipeline = () => {
    dispatch(setIsLoadingReducer(true));
    ApiService.POST(ApiRoutes.publishedPipeline.default, {
      pipeline_id: historyPipelinePayload?.current?.historyPipelineId,
      project_id: projectId,
    })
      .then((response) => {
        const revertedPipeline: IPipeline = response?.entities?.[0];
        dispatch(setPublishedPipelineListReducer({ ...publishedPipelineList, data: [...publishedPipelineList.data, revertedPipeline] }));
        dispatch(setResourceListReducer({ ...resourceList, resourceConfigurations: revertedPipeline?.resource_configurations, isPublished: true }));
        if (!resourceSummary?.isLoading && resourceSummary?.data?._id && resourceSummary?.pipelineId) {
          dispatch(
            getResourceSummaryRequest({ _id: resourceSummary?.data?._id, pipeline_id: resourceSummary?.pipelineId } as IResource, { noSkeleton: true })
          );
        }
        historyPipelinePayload.current = null;
        onCloseModals();
        ToastService.success(Messages.success.default);
      })
      .catch((error) => {
        if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
          ToastService.error(Messages.error.accessDenied);
          return;
        }
        console.log(error);
        ToastService.error(Messages.error.default);
      })
      .finally(() => dispatch(setIsLoadingReducer(false)));
  };

  const onCopyPipeline = () => {
    dispatch(setIsLoadingReducer(true));
    ApiService.PATCH(ApiRoutes.publishedPipeline.copy.replace(':publishedPipelineId', historyPipelinePayload?.current?.pipelineId), {
      pipeline_id: historyPipelinePayload?.current?.historyPipelineId,
    })
      .then((response) => {
        const updatedPipeline: IPipeline = response?.entities?.[0];
        dispatch(
          setPipelineListReducer({
            ...pipelineList,
            data: pipelineList?.data?.map((pipeline: IPipeline) => {
              if (pipeline?._id === updatedPipeline?._id) {
                return updatedPipeline;
              }
              return pipeline;
            }),
          })
        );
        isDraftCopied.current = true;
        historyPipelinePayload.current = null;
        ToastService.success(Messages.success.copied);
      })
      .catch((error) => {
        if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
          ToastService.error(Messages.error.accessDenied);
          return;
        }
        console.log(error);
        ToastService.error(Messages.error.default);
      })
      .finally(() => dispatch(setIsLoadingReducer(false)));
  };

  const onChangeStatusColor = (name: string, color: ColorResult) => {
    setProjectTaskStatuses(
      projectTaskStatuses?.map((status: ITaskConfigurationStatus) => {
        if (status?.name === name) {
          return { name, color: color?.hex?.slice(1) };
        }
        return status;
      })
    );
  };

  const onChangePipelineTaskStatusColor = (name: string, color: ColorResult) => {
    setPipelineTaskStatuses(
      pipelineTaskStatuses?.map((status: ITaskConfigurationStatus) => {
        if (status?.name === name) {
          return { name, color: color?.hex?.slice(1) };
        }
        return status;
      })
    );
  };

  const onDeleteAppSettings = (appName: string) => {
    dispatch(setIsLoadingReducer(true));
    ApiService.DELETE(ApiRoutes.project.appConfig.info.replace(':projectId', projectId).replace(':appName', appName))
      .then((response) => {
        const updatedProject = response?.entities?.[0];
        dispatch(
          setProjectListReducer(
            projectList?.map((item: IProject) => {
              if (item?._id === updatedProject?._id) {
                return updatedProject;
              }
              return item;
            })
          )
        );
        dispatch(setProjectReducer(updatedProject));
      })
      .catch((error) => {
        if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
          ToastService.error(Messages.error.accessDenied);
          return;
        }
        console.log(error);
        ToastService.error(Messages.error.default);
      })
      .finally(() => dispatch(setIsLoadingReducer(false)));
  };

  const onChangeAppName = (newAppName: string, oldAppName: string) => {
    dispatch(setIsLoadingReducer(true));
    ApiService.DELETE(ApiRoutes.project.appConfig.info.replace(':projectId', projectId).replace(':appName', oldAppName))
      .then(async () => {
        await ApiService.POST(ApiRoutes.project.appConfig.default.replace(':projectId', projectId), {
          app_name: newAppName,
          app_version: NULL_VALUE,
          // app_version: globalAppConfigurations?.find((item: IGlobalAppConfiguration) => item?.app_name === newAppName)?.app_versions?.[0],
        })
          .then((response) => {
            const updatedProject = response?.entities?.[0];
            dispatch(
              setProjectListReducer(
                projectList?.map((item: IProject) => {
                  if (item?._id === updatedProject?._id) {
                    return updatedProject;
                  }
                  return item;
                })
              )
            );
            dispatch(setProjectReducer(updatedProject));
          })
          .catch((error) => {
            if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
              ToastService.error(Messages.error.accessDenied);
              return;
            }
            console.log(error);
            ToastService.error(Messages.error.default);
          });
      })
      .catch((error) => {
        if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
          ToastService.error(Messages.error.accessDenied);
          return;
        }
        console.log(error);
        ToastService.error(Messages.error.default);
      })
      .finally(() => dispatch(setIsLoadingReducer(false)));
  };

  const onUpdateAppVersion = (appName: string, appVersion: string) => {
    dispatch(setIsLoadingReducer(true));
    ApiService.PATCH(ApiRoutes.project.appConfig.info.replace(':projectId', projectId).replace(':appName', appName), {
      app_name: appName,
      app_version: appVersion,
    })
      .then((response) => {
        const updatedProject = response?.entities?.[0];
        dispatch(
          setProjectListReducer(
            projectList?.map((item: IProject) => {
              if (item?._id === updatedProject?._id) {
                return updatedProject;
              }
              return item;
            })
          )
        );
        dispatch(setProjectReducer(updatedProject));
      })
      .catch((error) => {
        if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
          ToastService.error(Messages.error.accessDenied);
          return;
        }
        console.log(error);
        ToastService.error(Messages.error.default);
      })
      .finally(() => dispatch(setIsLoadingReducer(false)));
  };

  const onUpdateTaskStatuses = (completeStatus?: string) => {
    let isDuplicated = false;

    projectTaskStatuses?.forEach((status: ITaskConfigurationStatus) => {
      if (projectTaskStatuses?.filter((item: ITaskConfigurationStatus) => item?.name === status?.name)?.length > 1) {
        console.log(status);
        isDuplicated = true;
      }
    });

    if (isDuplicated) {
      ToastService.error(Messages.error.default);
    } else {
      dispatch(setIsLoadingReducer(true));
      ApiService.PATCH(ApiRoutes.project.taskConfig.default.replace(':projectId', projectId), {
        statuses: projectTaskStatuses,
        complete_status:
          completeStatus ??
          (projectTaskStatuses?.find((item: ITaskConfigurationStatus) => item?.name === project?.task_configuration?.complete_status)
            ? project?.task_configuration?.complete_status
            : projectTaskStatuses?.slice(-1)?.[0]?.name),
      })
        .then((response) => {
          const updatedProject = response?.entities?.[0];

          dispatch(
            setProjectListReducer(
              projectList?.map((item: IProject) => {
                if (item?._id === updatedProject?._id) {
                  return updatedProject;
                }
                return item;
              })
            )
          );
          dispatch(setProjectReducer(updatedProject));

          !completeStatus && setIsEditingStatus(false);
        })
        .catch((error) => {
          if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
            ToastService.error(Messages.error.accessDenied);
            return;
          }
          console.log(error);
          ToastService.error(Messages.error.default);
        })
        .finally(() => dispatch(setIsLoadingReducer(false)));
    }
  };

  const onChangePipelineAppName = (newAppName: string, oldAppName: string) => {
    dispatch(setIsLoadingReducer(true));
    ApiService.DELETE(ApiRoutes.pipeline.appConfig.info.replace(':pipelineId', pipelineSidebar?.data?._id).replace(':appName', oldAppName))
      .then(async () => {
        await ApiService.POST(ApiRoutes.pipeline.appConfig.default.replace(':pipelineId', pipelineSidebar?.data?._id), {
          app_name: newAppName,
          app_version: NULL_VALUE,
        })
          .then((response) => {
            const updatedPipeline = response?.entities?.[0];
            dispatch(
              setPipelineListReducer({
                ...pipelineList,
                data: pipelineList?.data?.map((item: IPipeline) => {
                  if (item?._id === updatedPipeline?._id) {
                    return updatedPipeline;
                  }
                  return item;
                }),
              })
            );
            setPipelineSidebar({
              ...pipelineSidebar,
              data: updatedPipeline,
            });
          })
          .catch((error) => {
            if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
              ToastService.error(Messages.error.accessDenied);
              return;
            }
            console.log(error);
            ToastService.error(Messages.error.default);
          });
      })
      .catch((error) => {
        if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
          ToastService.error(Messages.error.accessDenied);
          return;
        }
        console.log(error);
        ToastService.error(Messages.error.default);
      })
      .finally(() => dispatch(setIsLoadingReducer(false)));
  };

  const onUpdatePipelineAppVersion = (appName: string, appVersion: string) => {
    dispatch(setIsLoadingReducer(true));
    ApiService.PATCH(ApiRoutes.pipeline.appConfig.info.replace(':pipelineId', pipelineSidebar?.data?._id).replace(':appName', appName), {
      app_name: appName,
      app_version: appVersion,
    })
      .then((response) => {
        const updatedPipeline = response?.entities?.[0];
        dispatch(
          setPipelineListReducer({
            ...pipelineList,
            data: pipelineList?.data?.map((item: IPipeline) => {
              if (item?._id === updatedPipeline?._id) {
                return updatedPipeline;
              }
              return item;
            }),
          })
        );
        setPipelineSidebar({
          ...pipelineSidebar,
          data: updatedPipeline,
        });
      })
      .catch((error) => {
        if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
          ToastService.error(Messages.error.accessDenied);
          return;
        }
        console.log(error);
        ToastService.error(Messages.error.default);
      })
      .finally(() => dispatch(setIsLoadingReducer(false)));
  };

  const onDeletePipelineAppSettings = (appName: string) => {
    dispatch(setIsLoadingReducer(true));
    ApiService.DELETE(ApiRoutes.pipeline.appConfig.info.replace(':pipelineId', pipelineSidebar?.data?._id).replace(':appName', appName))
      .then((response) => {
        const updatedPipeline = response?.entities?.[0];
        dispatch(
          setPipelineListReducer({
            ...pipelineList,
            data: pipelineList?.data?.map((item: IPipeline) => {
              if (item?._id === updatedPipeline?._id) {
                return updatedPipeline;
              }
              return item;
            }),
          })
        );
        setPipelineSidebar({
          ...pipelineSidebar,
          data: updatedPipeline,
        });
      })
      .catch((error) => {
        if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
          ToastService.error(Messages.error.accessDenied);
          return;
        }
        console.log(error);
        ToastService.error(Messages.error.default);
      })
      .finally(() => dispatch(setIsLoadingReducer(false)));
  };

  const onUpdatePipelineTaskStatuses = (completeStatus?: string) => {
    let isDuplicated = false;

    pipelineTaskStatuses?.forEach((status: ITaskConfigurationStatus) => {
      if (pipelineTaskStatuses?.filter((item: ITaskConfigurationStatus) => item?.name === status?.name)?.length > 1) {
        isDuplicated = true;
      }
    });

    if (isDuplicated) {
      ToastService.error(Messages.error.default);
    } else {
      dispatch(setIsLoadingReducer(true));
      ApiService.PATCH(ApiRoutes.pipeline.taskConfig.default.replace(':pipelineId', pipelineSidebar?.data?._id), {
        statuses: pipelineTaskStatuses,
        complete_status:
          completeStatus ??
          (pipelineTaskStatuses?.find((item: ITaskConfigurationStatus) => item?.name === pipelineSidebar?.data?.task_configuration?.complete_status)
            ? pipelineSidebar?.data?.task_configuration?.complete_status
            : pipelineTaskStatuses?.slice(-1)?.[0]?.name),
      })
        .then((response) => {
          const updatedPipeline = response?.entities?.[0];

          dispatch(
            setPipelineListReducer({
              ...pipelineList,
              data: pipelineList?.data?.map((item: IPipeline) => {
                if (item?._id === updatedPipeline?._id) {
                  return updatedPipeline;
                }
                return item;
              }),
            })
          );

          setPipelineSidebar({
            ...pipelineSidebar,
            data: updatedPipeline,
          });

          !completeStatus && setIsEditPipelineStatus(false);
        })
        .catch((error) => {
          if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
            ToastService.error(Messages.error.accessDenied);
            return;
          }
          console.log(error);
          ToastService.error(Messages.error.default);
        })
        .finally(() => dispatch(setIsLoadingReducer(false)));
    }
  };

  return (
    <div className={classes.container}>
      <p className={classes.description}>Determine the workflows of each of your pipelines. Add stages, automation and more.</p>

      {pipelineList?.data ? (
        pipelineList?.data?.length ? (
          <Fragment>
            <div className={classes.header}>
              {pipelineList?.data?.map((pipeline: IPipeline) => {
                return (
                  <div
                    key={`pipeline-${pipeline?._id}`}
                    className={clsx(classes.headerItem, { [classes.active]: pipeline?._id === activePipelineId })}
                    onClick={() => {
                      allowFetch.current = true;
                      previewId && setPreviewId(null);
                      setActivePipelineId(pipeline?._id);
                      nodeSidebar?.isOpen && setNodeSidebar({ isOpen: false, node: null });
                      dispatch(
                        push({
                          pathname: routes.private.project.edit.replace(':workspaceId', workspaceId).replace(':projectId', projectId),
                          search: `&name=${encodeURIComponent(name as string)}&type=${type}&activePipelineId=${pipeline?._id ?? NULL_VALUE}&workspaceId=${
                            workspace?._id ?? NULL_VALUE
                          }`,
                        })
                      );
                    }}
                  >
                    <p>{pipeline?.name}</p>
                    <div className={classes.actionButtons}>
                      <EditIcon
                        onClick={() => {
                          setPipelineSidebar({ isOpen: true, data: pipeline });
                        }}
                      />
                    </div>
                  </div>
                );
              })}
              <div
                className={clsx(classes.headerItem, classes.addPipelineButton)}
                onClick={() => {
                  setNodeSidebar({ isOpen: false, node: null });
                  setModalCreatePipeline({ isOpen: true });
                  setPipelineSidebar({ isOpen: false, data: null });
                }}
              >
                <PlusPurpleIcon />
                <p>Add Pipeline</p>
              </div>
            </div>

            <div id="reactflow" className={clsx(classes.reactflow, { [classes.showHistory]: isShowHistory, [classes.isPreview]: previewId })}>
              {/* {isSaving ? (
                <div className={classes.savingContainer}>
                  <SavingIcon />
                  <p>Saving...</p>
                </div>
              ) : null} */}

              {previewId ? <div className={classes.previewContainer}>Preview only</div> : null}

              <div
                className={clsx(classes.historyButton, { [classes.hide]: isDraggingNewNode })}
                onClick={(event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
                  event?.stopPropagation();
                  if (isShowHistory) {
                    allowFetch.current = true;
                    setPreviewId(null);
                  }
                  setIsShowHistory(!isShowHistory);
                }}
              >
                <p>{isShowHistory ? 'Hide history' : 'Show history'}</p>
                {isShowHistory ? <EyeHideIcon /> : <EyeShowIcon />}
              </div>

              <ReactFlow
                ref={reactFlowRef}
                fitView={true}
                minZoom={minZoomValue}
                nodes={nodes}
                edges={edges}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                connectionLineComponent={ConnectionLine}
                onNodesChange={(changes: NodeChange[]) => {
                  let isChanging = false;

                  // call the actual change handler to apply the node changes to your nodes
                  onNodesChange(changes);
                  // loop through the changes and check for a dimensions change that relates to the node we want to focus
                  changes?.forEach(
                    (change: NodeDimensionChange | NodePositionChange | NodeSelectionChange | NodeRemoveChange | NodeAddChange | NodeResetChange) => {
                      if (change?.type === 'dimensions' && focusId === change?.id && change?.dimensions?.height > 0 && change?.dimensions?.width > 0) {
                        reactFlow?.fitView({
                          nodes: [{ id: focusId }],
                          duration: 500,
                          padding: 6,
                        });
                        // reset the focus id so we don't retrigger fit view when the dimensions of this node happen to change
                        setFocusId(null);
                      }
                      if (change?.type !== 'select' && (change as NodePositionChange)?.dragging !== false) {
                        isChanging = true;
                      }
                    }
                  );

                  if (isChanging && !isSaved?.current && !blockSaveResourceConfig.current) {
                    setAutoSavePipeline({ id: activePipelineId });
                  } else {
                    isSaved.current = false;
                  }
                }}
                onNodesDelete={(nodes: Node[]) => {
                  if (!nodeSidebar?.node?.data?.newNode) {
                    setResourcesDeleted([
                      ...resourcesDeleted,
                      ...nodes.map((node) => {
                        return node?.id;
                      }),
                    ]);
                  }
                  setNodeSidebar({ isOpen: false, node: null });
                }}
                onNodeDragStop={(_: MouseEvent, node: Node) => nodeSidebar?.isOpen && setNodeSidebar({ ...nodeSidebar, node })}
                onEdgesChange={onEdgesChange}
                onEdgesDelete={() => {
                  setAutoSavePipeline({ id: activePipelineId });
                }}
                onConnect={onConnect}
                onConnectStart={onConnectStart}
                onConnectEnd={onConnectEnd}
                onEdgeUpdate={onEdgeUpdate}
                onEdgeUpdateStart={onEdgeUpdateStart}
                onEdgeUpdateEnd={onEdgeUpdateEnd}
                isValidConnection={isValidConnection}
                onNodeClick={onNodeClick as any}
                onPaneClick={() => {
                  setNodeSidebar({ isOpen: false, node: null });
                  setProjectSidebar({ isOpen: false, data: null });
                  setPipelineSidebar({ isOpen: false, data: null });
                }}
                multiSelectionKeyCode="Shift"
                edgesUpdatable={!previewId}
                edgesFocusable={!previewId}
                nodesDraggable={!previewId}
                nodesConnectable={!previewId}
                nodesFocusable={!previewId}
                elementsSelectable={!previewId}
                snapToGrid={true}
                snapGrid={[snapGridValue, snapGridValue]}
              >
                <Background variant={BackgroundVariant.Dots} gap={12} size={1} />
                <div className={clsx(classes.toolbar, { [classes.showHistory]: isShowHistory, 'd-none': previewId, [classes.hide]: isDraggingNewNode })}>
                  <div
                    id="new-stage-button"
                    className={clsx(classes.newStageButton, {
                      [classes.disabled]: isSaving || isCreating,
                      [classes.hide]: isDraggingNewNode,
                    })}
                    onClick={() => onAddNode()}
                  >
                    {!isDraggingNewNode ? (
                      <Fragment>
                        {isSaving || isCreating ? <SavingIcon /> : <NodeIcon />}
                        <p>{isCreating ? 'Creating...' : isSaving ? 'Saving...' : 'New Stage'}</p>
                      </Fragment>
                    ) : null}
                  </div>
                  <p className={clsx(classes.tooltip, { [classes.hide]: isDraggingNewNode })}>Click or drag to add new stage</p>
                </div>
              </ReactFlow>
            </div>

            {isShowHistory ? (
              <div className={classes.historyContainer}>
                <div className={classes.historyScroll}>
                  {publishedPipelineList?.data ? (
                    publishedPipelineList?.data?.length ? (
                      publishedPipelineList?.data?.map((pipeline: IPipeline, pipelineIndex: number) => {
                        return (
                          <Fragment key={`pipeline-${pipeline?._id}`}>
                            <div
                              className={clsx(classes.historyItem, {
                                [classes.published]: pipelineIndex === publishedPipelineList?.data?.length - 1,
                                [classes.active]: previewId === pipeline?._id,
                              })}
                              onClick={() => setPreviewId(pipeline?._id)}
                            >
                              <div className={classes.historyInfo}>
                                <p className={classes.historyDate}>{`v${pipeline?.publish_version} - ${dayjs(pipeline?.createdAt)?.format(
                                  DATE_FORMAT
                                )}`}</p>
                                <div className={classes.historyUser}>
                                  <img src={pipeline?.user?.profile_image_url ?? UserPlaceholderImage} alt="Avatar" />
                                  <p>{CommonService.getFullName(pipeline?.user)}</p>
                                </div>
                              </div>
                              <MoreIcon
                                onClick={(event: React.MouseEvent<SVGSVGElement>) => {
                                  event?.stopPropagation();
                                  historyPipelinePayload.current = {
                                    historyPipelineId: pipeline?._id,
                                    pipelineId: activePipelineId,
                                  };
                                  setHistoryPopoverAnchorElement(event?.currentTarget);
                                }}
                              />
                            </div>
                            {pipelineIndex !== publishedPipelineList?.data?.length - 1 ? <div className={classes.line} /> : null}
                          </Fragment>
                        );
                      })
                    ) : (
                      <p className="ms-2">You have not published any version.</p>
                    )
                  ) : (
                    [...Array(historySkeletonCount).keys()]?.map((index) => {
                      return (
                        <Fragment key={`history-skeleton-${index}`}>
                          <Skeleton
                            variant="rectangular"
                            width={170}
                            height={90}
                            sx={{ flexShrink: 0, borderRadius: '20px', background: 'var(--backgroundLight)' }}
                          />
                          {index !== historySkeletonCount - 1 ? <div className={classes.line} style={{ background: 'var(--backgroundLight)' }} /> : null}
                        </Fragment>
                      );
                    })
                  )}
                </div>

                <div
                  className={clsx(classes.historyDraft, { [classes.active]: !previewId })}
                  onClick={() => {
                    allowFetch.current = true;
                    setPreviewId(null);
                  }}
                >
                  Draft
                </div>
              </div>
            ) : null}
          </Fragment>
        ) : (
          <div className={classes.noPipeline}>
            <p>Create your first pipeline</p>
            <span>
              Organize your project assets and pipelines and share with your collaborators. Bring your project to life, track progress and launch on-time
              by collaborating in real time.
            </span>
            <PrimaryButton onClick={() => setModalCreatePipeline({ isOpen: true })}>Create a pipeline</PrimaryButton>
          </div>
        )
      ) : (
        <Skeleton
          variant="rectangular"
          width="100%"
          height="calc(100% - 18px - 14px)"
          sx={{ borderRadius: '12px', background: 'var(--backgroundLight)' }}
        />
      )}

      <ReactProSidebar className={classes.nodeSidebarContainer} collapsed={!nodeSidebar?.isOpen} rtl={true} width="360px">
        <div ref={nodeSidebarRef} className={classes.nodeSidebar}>
          {nodeSidebar?.isOpen ? (
            <Fragment>
              <div className={classes.backContainer}>
                <BackIcon onClick={() => setNodeSidebar({ isOpen: false, node: null })} />
              </div>

              <div className={clsx(classes.titleContainer, { [classes.editing]: isEditStageName })}>
                {isEditStageName ? (
                  <Fragment>
                    <Input
                      inputRef={register('stageName')}
                      className="mb-0 w-100"
                      defaultValue={nodeSidebar?.node?.data?.label}
                      autoComplete="off"
                      onFocus={(event: React.FocusEvent<HTMLInputElement>) => event.target.select()}
                      onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                        if (event?.key === 'Enter' && !errors?.stageName?.message) {
                          onSaveStageName();
                        }
                      }}
                      errorMessage={errors?.stageName?.message}
                    />
                    <CheckmarkIcon onClick={() => !errors?.stageName?.message && onSaveStageName()} />
                  </Fragment>
                ) : (
                  <Fragment>
                    <p
                      onClick={(event: React.MouseEvent<HTMLParagraphElement>) => {
                        if (event?.detail === 2 && !isEditStageName) {
                          setValue('stageName', nodeSidebar?.node?.data?.label, { shouldValidate: true });
                          setIsEditStageName(true);
                        }
                      }}
                    >
                      {nodeSidebar?.node?.data?.label}
                    </p>{' '}
                    <EditIcon
                      onClick={() => {
                        setValue('stageName', nodeSidebar?.node?.data?.label, { shouldValidate: true });
                        setIsEditStageName(true);
                      }}
                    />
                  </Fragment>
                )}
              </div>
              <p className={classes.subtitle}>Stage settings</p>

              <hr />

              <p className={classes.subtitle}>App Settings</p>
              <div className={classes.appSettingsContainer}>
                <div className={classes.appSettings}>
                  <Select
                    name="app_name"
                    control={control}
                    customTheme={ESelectTheme.Form}
                    className="mb-0"
                    label="App"
                    options={[
                      { value: NULL_VALUE, label: NULL_LABEL },
                      ...(globalAppConfigurations
                        ?.filter((item: IGlobalAppConfiguration) => nodeSidebar?.node?.data?.app_name !== item?.app_name)
                        ?.map((item: IGlobalAppConfiguration) => {
                          return { value: item?.app_name, label: item?.app_name };
                        }) ?? []),
                    ]}
                    menuPortalTarget={undefined}
                  />
                  <Select
                    name="app_version"
                    control={control}
                    customTheme={ESelectTheme.Form}
                    className="mb-0"
                    label="Version"
                    options={[
                      { value: NULL_VALUE, label: NULL_LABEL },
                      ...(
                        globalAppConfigurations
                          ?.find((item: IGlobalAppConfiguration) => item?.app_name === nodeSidebar?.node?.data?.app_name)
                          ?.app_versions?.map((version: string) => {
                            return { value: version, label: version };
                          }) ?? []
                      ).reverse(),
                    ]}
                    menuPortalTarget={undefined}
                  />
                </div>
              </div>

              <hr className="my-4" />

              <DangerButton fullWidth onClick={onDeleteNode}>
                Delete
              </DangerButton>
            </Fragment>
          ) : null}
        </div>
      </ReactProSidebar>

      <ReactProSidebar className={classes.projectSidebarContainer} collapsed={!projectSidebar?.isOpen} rtl={true} width="400px">
        <div ref={projectSidebarRef} className={classes.projectSidebar}>
          {/* I used projectSidebar?.isOpen here to reset sidebar data */}
          {projectSidebar?.isOpen ? (
            <Fragment>
              <div className={classes.backContainer}>
                <BackIcon onClick={() => setProjectSidebar({ isOpen: false, data: null })} />
              </div>

              <p className={classes.subtitle}>Project settings</p>

              <hr />

              <p className={classes.title}>App Settings</p>
              <p className={classes.description}>Setup default applications and versions for this project.</p>

              <div className={classes.appSettingsContainer}>
                {Object.values(project?.app_configuration ?? {})
                  ?.map((value: IAppConfiguration) => {
                    return value;
                  })
                  ?.sort((a: IAppConfiguration, b: IAppConfiguration) => {
                    if (a?.app_name < b?.app_name) {
                      return -1;
                    } else if (a?.app_name > b?.app_name) {
                      return 1;
                    } else {
                      return 0;
                    }
                  })
                  ?.map((appConfiguration: IAppConfiguration) => {
                    return (
                      <div className={classes.appSettings} key={`app-settings-${appConfiguration?.app_name}`}>
                        <Select
                          customTheme={ESelectTheme.Form}
                          className="mb-0"
                          label="App"
                          defaultValue={{ value: appConfiguration?.app_name, label: appConfiguration?.app_name }}
                          options={globalAppConfigurations
                            ?.filter((item: IGlobalAppConfiguration) => !Object.keys(project?.app_configuration ?? {})?.includes(item?.app_name))
                            ?.map((item: IGlobalAppConfiguration) => {
                              return { value: item?.app_name, label: item?.app_name };
                            })}
                          onChange={(option: any) => onChangeAppName(option?.value, appConfiguration?.app_name)}
                          menuPortalTarget={undefined}
                        />
                        <Select
                          customTheme={ESelectTheme.Form}
                          className="mb-0"
                          label="Version"
                          defaultValue={
                            appConfiguration?.app_version
                              ? {
                                  value: appConfiguration?.app_version,
                                  label: appConfiguration?.app_version === NULL_VALUE ? NULL_LABEL : appConfiguration?.app_version,
                                }
                              : { value: NULL_VALUE, label: NULL_LABEL }
                          }
                          options={[
                            { value: NULL_VALUE, label: NULL_LABEL },
                            ...(
                              globalAppConfigurations
                                ?.find((item: IGlobalAppConfiguration) => item?.app_name === appConfiguration?.app_name)
                                ?.app_versions?.map((version: string) => {
                                  return { value: version, label: version };
                                }) ?? []
                            ).reverse(),
                          ]}
                          onChange={(option: any) => onUpdateAppVersion(appConfiguration?.app_name, option?.value)}
                          menuPortalTarget={undefined}
                        />
                        <DeleteIcon onClick={() => onDeleteAppSettings(appConfiguration?.app_name)} />
                      </div>
                    );
                  })}

                {addAppSettings?.isAdding ? (
                  <div className={classes.appSettings}>
                    <Select
                      customTheme={ESelectTheme.Form}
                      className="mb-0"
                      label="App"
                      value={addAppSettings?.data?.appName ? { value: addAppSettings?.data?.appName, label: addAppSettings?.data?.appName } : null}
                      options={globalAppConfigurations
                        ?.filter((item: IGlobalAppConfiguration) => !Object.keys(project?.app_configuration ?? {})?.includes(item?.app_name))
                        ?.map((item: IGlobalAppConfiguration) => {
                          return { value: item?.app_name, label: item?.app_name };
                        })}
                      onChange={(option: any) => {
                        setAddAppSettings({ ...addAppSettings, data: { appName: option?.value, appVersion: NULL_VALUE } });
                      }}
                      menuPortalTarget={undefined}
                    />
                    <Select
                      customTheme={ESelectTheme.Form}
                      className="mb-0"
                      label="Version"
                      value={
                        addAppSettings?.data?.appVersion
                          ? {
                              value: addAppSettings?.data?.appVersion,
                              label: addAppSettings?.data?.appVersion === NULL_VALUE ? NULL_LABEL : addAppSettings?.data?.appVersion,
                            }
                          : { value: NULL_VALUE, label: NULL_LABEL }
                      }
                      options={[
                        { value: NULL_VALUE, label: NULL_LABEL },
                        ...(
                          globalAppConfigurations
                            ?.find((item: IGlobalAppConfiguration) => item?.app_name === addAppSettings?.data?.appName)
                            ?.app_versions?.map((version: string) => {
                              return { value: version, label: version };
                            }) ?? []
                        ).reverse(),
                      ]}
                      onChange={(option: any) => {
                        setAddAppSettings({ ...addAppSettings, data: { ...addAppSettings?.data, appVersion: option?.value } });
                      }}
                      menuPortalTarget={undefined}
                    />
                    <DeleteIcon onClick={() => setAddAppSettings({ isAdding: false, data: null })} />
                  </div>
                ) : null}
              </div>

              <button
                className={classes.addAppSettingsButton}
                onClick={() => {
                  if (
                    globalAppConfigurations?.find(
                      (item: IGlobalAppConfiguration) => !Object.keys(project?.app_configuration ?? {})?.includes(item?.app_name)
                    )
                  ) {
                    if (!addAppSettings?.isAdding) {
                      setAddAppSettings({ isAdding: true, data: null });
                    }
                  } else {
                    ToastService.info(Messages.info.fullAppSettings);
                  }
                }}
              >
                <PlusBlackIcon />
              </button>

              <hr />

              <p className={classes.title}>Status</p>
              <p className={classes.description}>Task statuses.</p>
              {!isEditingStatus ? (
                <div className={classes.menu}>
                  {projectTaskStatuses?.map((status: ITaskConfigurationStatus, statusIndex: number) => (
                    <Box
                      key={`status-${statusIndex}`}
                      className={classes.statusItem}
                      sx={{
                        borderColor: status?.color ? `#${status?.color}` : 'var(--gray)',
                        mb: statusIndex === projectTaskStatuses?.length - 1 ? 0 : 2,
                      }}
                    >
                      {status?.name ?? 'N/A'}
                    </Box>
                  ))}

                  <p
                    className={classes.editLabel}
                    onClick={() => {
                      setIsEditingStatus(true);
                    }}
                  >
                    Edit
                  </p>
                </div>
              ) : (
                <Fragment>
                  <DragDropContext onDragEnd={onDragEnd} onDragStart={() => (document?.activeElement as HTMLElement)?.blur()}>
                    <Droppable droppableId="droppable">
                      {(droppableProvided: DroppableProvided) => (
                        <div className={classes.menu} {...droppableProvided?.droppableProps} ref={droppableProvided?.innerRef}>
                          {projectTaskStatuses?.map((status: ITaskConfigurationStatus, statusIndex: number) => (
                            <Draggable key={`status-${statusIndex}`} draggableId={`status-${statusIndex}`} index={statusIndex}>
                              {(draggableProvided: DraggableProvided) => (
                                <Stack className={classes.editContainer} ref={draggableProvided?.innerRef} {...draggableProvided?.draggableProps}>
                                  <ColorFill
                                    name={status?.name}
                                    color={status?.color ? `#${status?.color}` : null}
                                    onChangeStatusColor={onChangeStatusColor}
                                  />

                                  <StatusInput
                                    className={classes.statusInput}
                                    $color={status?.color ? `#${status?.color}` : null}
                                    variant="standard"
                                    value={status?.name}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                      setProjectTaskStatuses((previousState: ITaskConfigurationStatus[]) => {
                                        const result = previousState?.map((item: ITaskConfigurationStatus, index: number) => {
                                          if (index === statusIndex) {
                                            return { ...item, name: event?.target?.value };
                                          } else {
                                            return item;
                                          }
                                        });
                                        return result;
                                      });
                                    }}
                                  />

                                  <DeleteIcon
                                    className={classes.deleteIcon}
                                    onClick={() => {
                                      setProjectTaskStatuses(projectTaskStatuses?.filter((item: ITaskConfigurationStatus) => item?.name !== status?.name));
                                    }}
                                  />

                                  <div {...draggableProvided?.dragHandleProps}>
                                    <DragIcon />
                                  </div>
                                </Stack>
                              )}
                            </Draggable>
                          ))}
                          {droppableProvided?.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>

                  <Box
                    className={classes.statusItem}
                    sx={{ cursor: 'pointer !important', display: 'flex', justifyContent: 'center', marginTop: '24px' }}
                    onClick={() => {
                      setProjectTaskStatuses([...projectTaskStatuses, { name: `Status ${projectTaskStatuses?.length + 1}`, color: NULL_COLOR }]);
                    }}
                  >
                    <PlusWhiteIcon />
                  </Box>

                  <p
                    className={classes.editLabel}
                    onClick={() => {
                      onUpdateTaskStatuses();
                    }}
                  >
                    Save
                  </p>
                </Fragment>
              )}

              <hr />

              <p className={classes.title}>Complete Status</p>
              <p className={classes.description}>The status that represents task completion.</p>
              <Select
                customTheme={ESelectTheme.Form}
                label="Status"
                value={{
                  value: project?.task_configuration?.complete_status ?? 'N/A',
                  label: project?.task_configuration?.complete_status ?? 'N/A',
                }}
                options={project?.task_configuration?.statuses?.map((status: ITaskConfigurationStatus) => {
                  return {
                    value: status?.name,
                    label: status?.name,
                  };
                })}
                onChange={(option: any) => {
                  onUpdateTaskStatuses(option?.value);
                }}
                menuPortalTarget={undefined}
                menuPlacement="auto"
              />
            </Fragment>
          ) : null}
        </div>
      </ReactProSidebar>

      <ReactProSidebar className={classes.pipelineSidebarContainer} collapsed={!pipelineSidebar?.isOpen} rtl={true} width="400px">
        <div ref={pipelineSidebarRef} className={classes.pipelineSidebar}>
          {/* I used pipelineSidebar?.isOpen here to reset sidebar data */}
          {pipelineSidebar?.isOpen ? (
            <Fragment>
              <div className={classes.backContainer}>
                <BackIcon onClick={() => setPipelineSidebar({ isOpen: false, data: null })} />
              </div>
              <div className={clsx(classes.titleContainer, { [classes.editing]: isEditPipelineName })}>
                {isEditPipelineName ? (
                  <Fragment>
                    <Input
                      inputRef={pipelineRegister('pipelineName')}
                      className="mb-0 w-100"
                      defaultValue={pipelineSidebar?.data?.name}
                      autoComplete="off"
                      onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                        if (event?.key === 'Enter' && !pipelineErrors?.pipelineName?.message) {
                          onSavePipelineName();
                        }
                      }}
                      errorMessage={pipelineErrors?.pipelineName?.message}
                    />
                    <CheckmarkIcon onClick={() => !pipelineErrors?.pipelineName?.message && onSavePipelineName()} />
                  </Fragment>
                ) : (
                  <Fragment>
                    <p
                      onClick={(event: React.MouseEvent<HTMLParagraphElement>) => {
                        if (event?.detail === 2 && !isEditPipelineName) {
                          pipelineSetValue('pipelineName', pipelineSidebar?.data?.name, { shouldValidate: true });
                          setIsEditPipelineName(true);
                        }
                      }}
                    >
                      {pipelineSidebar?.data?.name}
                    </p>{' '}
                    <EditIcon
                      onClick={() => {
                        pipelineSetValue('pipelineName', pipelineSidebar?.data?.name, { shouldValidate: true });
                        setIsEditPipelineName(true);
                      }}
                    />
                  </Fragment>
                )}
              </div>
              <p className={classes.subtitle}>Pipeline settings</p>

              <hr />

              <Stack className={classes.settingStackContainer} direction={'row'} justifyContent="space-between" alignItems="center">
                <p className={classes.title}>App Settings</p>
                <div className={classes.useParentContainer}>
                  <Stack direction="row" alignItems="center" gap={2}>
                    <p>
                      Use Project{' '}
                      <Tooltip title="Toggle off to customize this pipeline. Parent project settings will no longer apply. Toggle back on to revert.">
                        <HelpIcon />
                      </Tooltip>
                    </p>
                    <Switch
                      checked={pipelineSidebar?.data?.inherit_parent_app_configuration}
                      onChange={() => {
                        onChangeInheritParentAppConfiguration();
                      }}
                    />
                  </Stack>
                </div>
              </Stack>
              <p className={classes.description}>Setup default applications and versions for this pipeline.</p>
              <div className={classes.appSettingsContainer}>
                {!pipelineSidebar?.data?.inherit_parent_app_configuration ? (
                  <Fragment>
                    {Object.values(pipelineSidebar?.data?.app_configuration ?? {})
                      ?.map((value: IAppConfiguration) => {
                        return value;
                      })
                      ?.sort((a: IAppConfiguration, b: IAppConfiguration) => {
                        if (a?.app_name < b?.app_name) {
                          return -1;
                        } else if (a?.app_name > b?.app_name) {
                          return 1;
                        } else {
                          return 0;
                        }
                      })
                      ?.map((appConfiguration: IAppConfiguration) => {
                        return (
                          <div className={classes.appSettings} key={`app-settings-${appConfiguration?.app_name}`}>
                            <Select
                              customTheme={ESelectTheme.Form}
                              className="mb-0"
                              label="App"
                              defaultValue={{ value: appConfiguration?.app_name, label: appConfiguration?.app_name }}
                              options={globalAppConfigurations
                                ?.filter((item: IGlobalAppConfiguration) => !Object.keys(project?.app_configuration ?? {})?.includes(item?.app_name))
                                ?.map((item: IGlobalAppConfiguration) => {
                                  return { value: item?.app_name, label: item?.app_name };
                                })}
                              onChange={(option: any) => {
                                onChangePipelineAppName(option?.value, appConfiguration?.app_name);
                              }}
                              menuPortalTarget={undefined}
                            />
                            <Select
                              customTheme={ESelectTheme.Form}
                              className="mb-0"
                              label="Version"
                              defaultValue={
                                appConfiguration?.app_version
                                  ? {
                                      value: appConfiguration?.app_version,
                                      label: appConfiguration?.app_version === NULL_VALUE ? NULL_LABEL : appConfiguration?.app_version,
                                    }
                                  : { value: NULL_VALUE, label: NULL_LABEL }
                              }
                              options={[
                                { value: NULL_VALUE, label: NULL_LABEL },
                                ...(
                                  globalAppConfigurations
                                    ?.find((item: IGlobalAppConfiguration) => item?.app_name === appConfiguration?.app_name)
                                    ?.app_versions?.map((version: string) => {
                                      return { value: version, label: version };
                                    }) ?? []
                                ).reverse(),
                              ]}
                              onChange={(option: any) => {
                                onUpdatePipelineAppVersion(appConfiguration?.app_name, option?.value);
                              }}
                              menuPortalTarget={undefined}
                            />
                            <DeleteIcon
                              onClick={() => {
                                onDeletePipelineAppSettings(appConfiguration?.app_name);
                              }}
                            />
                          </div>
                        );
                      })}

                    {addPipelineAppSettings?.isAdding ? (
                      <div className={classes.appSettings}>
                        <Select
                          customTheme={ESelectTheme.Form}
                          className="mb-0"
                          label="App"
                          value={
                            addPipelineAppSettings?.data?.appName
                              ? { value: addPipelineAppSettings?.data?.appName, label: addPipelineAppSettings?.data?.appName }
                              : null
                          }
                          options={globalAppConfigurations
                            ?.filter(
                              (item: IGlobalAppConfiguration) => !Object.keys(pipelineSidebar?.data?.app_configuration ?? {})?.includes(item?.app_name)
                            )
                            ?.map((item: IGlobalAppConfiguration) => {
                              return { value: item?.app_name, label: item?.app_name };
                            })}
                          onChange={(option: any) => {
                            setAddPipelineAppSettings({ ...addPipelineAppSettings, data: { appName: option?.value, appVersion: NULL_VALUE } });
                          }}
                          menuPortalTarget={undefined}
                        />
                        <Select
                          customTheme={ESelectTheme.Form}
                          className="mb-0"
                          label="Version"
                          value={
                            addPipelineAppSettings?.data?.appVersion
                              ? {
                                  value: addPipelineAppSettings?.data?.appVersion,
                                  label: addPipelineAppSettings?.data?.appVersion === NULL_VALUE ? NULL_LABEL : addPipelineAppSettings?.data?.appVersion,
                                }
                              : { value: NULL_VALUE, label: NULL_LABEL }
                          }
                          options={[
                            { value: NULL_VALUE, label: NULL_LABEL },
                            ...(
                              globalAppConfigurations
                                ?.find((item: IGlobalAppConfiguration) => item?.app_name === addPipelineAppSettings?.data?.appName)
                                ?.app_versions?.map((version: string) => {
                                  return { value: version, label: version };
                                }) ?? []
                            ).reverse(),
                          ]}
                          onChange={(option: any) => {
                            setAddPipelineAppSettings({ ...addPipelineAppSettings, data: { ...addPipelineAppSettings?.data, appVersion: option?.value } });
                          }}
                          menuPortalTarget={undefined}
                        />
                        <DeleteIcon onClick={() => setAddPipelineAppSettings({ isAdding: false, data: null })} />
                      </div>
                    ) : null}

                    <button
                      className={classes.addAppSettingsButton}
                      onClick={() => {
                        if (
                          globalAppConfigurations?.find(
                            (item: IGlobalAppConfiguration) => !Object.keys(pipelineSidebar?.data?.app_configuration ?? {})?.includes(item?.app_name)
                          )
                        ) {
                          if (!addAppSettings?.isAdding) {
                            setAddPipelineAppSettings({ isAdding: true, data: null });
                          }
                        } else {
                          ToastService.info(Messages.info.fullAppSettings);
                        }
                      }}
                    >
                      <PlusBlackIcon />
                    </button>
                  </Fragment>
                ) : null}
              </div>

              <hr />

              <Stack className={classes.settingStackContainer} direction={'row'} justifyContent="space-between" alignItems="center">
                <p className={classes.title}>Status</p>
                <div className={classes.useParentContainer}>
                  <Stack direction="row" alignItems="center" gap={2}>
                    <p>
                      Use Project{' '}
                      <Tooltip title="Toggle off to customize this pipeline. Parent project settings will no longer apply. Toggle back on to revert.">
                        <HelpIcon />
                      </Tooltip>
                    </p>
                    <Switch
                      checked={pipelineSidebar?.data?.inherit_parent_task_configuration}
                      onChange={() => {
                        onChangeInheritParentTaskConfiguration();
                      }}
                    />
                  </Stack>
                </div>
              </Stack>
              <p className={classes.description}>Task statuses.</p>
              {!pipelineSidebar?.data?.inherit_parent_task_configuration ? (
                <Fragment>
                  {!isEditPipelineStatus ? (
                    <div className={classes.menu}>
                      {pipelineTaskStatuses?.map((status: ITaskConfigurationStatus, statusIndex: number) => (
                        <Box
                          key={`status-${statusIndex}`}
                          className={classes.statusItem}
                          sx={{
                            borderColor: status?.color ? `#${status?.color}` : 'var(--gray)',
                            mb: statusIndex === pipelineTaskStatuses?.length - 1 ? 0 : 2,
                          }}
                        >
                          {status?.name ?? 'N/A'}
                        </Box>
                      ))}

                      <p
                        className={classes.editLabel}
                        onClick={() => {
                          setIsEditPipelineStatus(true);
                        }}
                      >
                        Edit
                      </p>
                    </div>
                  ) : (
                    <Fragment>
                      <DragDropContext onDragEnd={onDragPipelineTaskEnd} onDragStart={() => (document?.activeElement as HTMLElement)?.blur()}>
                        <Droppable droppableId="droppable">
                          {(droppableProvided: DroppableProvided) => (
                            <div className={classes.menu} {...droppableProvided?.droppableProps} ref={droppableProvided?.innerRef}>
                              {pipelineTaskStatuses?.map((status: ITaskConfigurationStatus, statusIndex: number) => (
                                <Draggable key={`status-${statusIndex}`} draggableId={`status-${statusIndex}`} index={statusIndex}>
                                  {(draggableProvided: DraggableProvided) => (
                                    <Stack className={classes.editContainer} ref={draggableProvided?.innerRef} {...draggableProvided?.draggableProps}>
                                      <ColorFill
                                        name={status?.name}
                                        color={status?.color ? `#${status?.color}` : null}
                                        onChangeStatusColor={onChangePipelineTaskStatusColor}
                                      />

                                      <StatusInput
                                        className={classes.statusInput}
                                        $color={status?.color ? `#${status?.color}` : null}
                                        variant="standard"
                                        value={status?.name}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                          setPipelineTaskStatuses((previousState: ITaskConfigurationStatus[]) => {
                                            const result = previousState?.map((item: ITaskConfigurationStatus, index: number) => {
                                              if (index === statusIndex) {
                                                return { ...item, name: event?.target?.value };
                                              } else {
                                                return item;
                                              }
                                            });
                                            return result;
                                          });
                                        }}
                                      />

                                      <DeleteIcon
                                        className={classes.deleteIcon}
                                        onClick={() => {
                                          setPipelineTaskStatuses(
                                            pipelineTaskStatuses?.filter((item: ITaskConfigurationStatus) => item?.name !== status?.name)
                                          );
                                        }}
                                      />

                                      <div {...draggableProvided?.dragHandleProps}>
                                        <DragIcon />
                                      </div>
                                    </Stack>
                                  )}
                                </Draggable>
                              ))}
                              {droppableProvided?.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>

                      <Box
                        className={classes.statusItem}
                        sx={{ cursor: 'pointer !important', display: 'flex', justifyContent: 'center', marginTop: '24px' }}
                        onClick={() => {
                          setPipelineTaskStatuses([...pipelineTaskStatuses, { name: `Status ${pipelineTaskStatuses?.length + 1}`, color: NULL_COLOR }]);
                        }}
                      >
                        <PlusWhiteIcon />
                      </Box>

                      <p
                        className={classes.editLabel}
                        onClick={() => {
                          onUpdatePipelineTaskStatuses();
                        }}
                      >
                        Save
                      </p>
                    </Fragment>
                  )}
                </Fragment>
              ) : null}

              <hr />

              {!pipelineSidebar?.data?.inherit_parent_task_configuration ? (
                <Fragment>
                  <p className={classes.title}>Complete Status</p>
                  <p className={classes.description}>The status that represents task completion.</p>
                  <Select
                    customTheme={ESelectTheme.Form}
                    label="Status"
                    value={{
                      value: pipelineSidebar?.data?.task_configuration?.complete_status ?? NULL_VALUE,
                      label: pipelineSidebar?.data?.task_configuration?.complete_status ?? NULL_LABEL,
                    }}
                    options={pipelineTaskStatuses.map((status: ITaskConfigurationStatus) => {
                      return {
                        value: status?.name,
                        label: status?.name,
                      };
                    })}
                    onChange={(option: any) => {
                      onUpdatePipelineTaskStatuses(option?.value);
                    }}
                    menuPortalTarget={undefined}
                    menuPlacement="auto"
                  />
                  <hr />
                </Fragment>
              ) : null}

              <DangerButton fullWidth onClick={() => setModalDeletePipeline({ isOpen: true, data: pipelineSidebar?.data })}>
                Delete
              </DangerButton>
            </Fragment>
          ) : null}
        </div>
      </ReactProSidebar>

      <ModalCreatePipeline isOpen={modalCreatePipeline?.isOpen} onClose={onCloseModals} onSubmit={onCreatePipeline} />

      <ModalDeletePipeline
        isOpen={modalDeletePipeline?.isOpen}
        name={modalDeletePipeline?.data?.name}
        onClose={onCloseModals}
        onSubmit={onDeletePipeline}
      />

      <ModalConfirmRevertPipeline isOpen={isOpenModalRevertPipeline} onClose={onCloseModals} onSubmit={onRevertPipeline} />

      <HistoryPopover
        anchorElement={historyPopoverAnchorElement}
        onClose={() => setHistoryPopoverAnchorElement(null)}
        onSelect={(type: EHistoryPopover) => {
          switch (type) {
            case EHistoryPopover.Revert:
              setIsOpenModalRevertPipeline(true);
              break;
            case EHistoryPopover.Copy:
              onCopyPipeline();
              break;
            default:
              break;
          }
        }}
      />
    </div>
  );
});

export default PipelineSettings;
