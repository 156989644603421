import { all } from 'redux-saga/effects';
import getWorkspaceList from './getWorkspaceList';
import getProjectList from './getProjectList';
import getArchivedProjectList from './getArchivedProjectList';
import getPipelineTemplates from './getPipelineTemplates';
import getPipelineList from './getPipelineList';
import getPublishedPipelineList from './getPublishedPipelineList';
import getProjectTemplates from './getProjectTemplates';
import getResourceList from './getResourceList';
import getResourceSummary from './getResourceSummary';
import getGlobalAppConfigurations from './getGlobalAppConfigurations';
import getResourceMedia from './getResourceMedia';

export const informationSagas = function* root() {
  yield all([
    getWorkspaceList(),
    getProjectList(),
    getArchivedProjectList(),
    getProjectTemplates(),
    getPipelineTemplates(),
    getPublishedPipelineList(),
    getPipelineList(),
    getResourceList(),
    getResourceSummary(),
    getResourceMedia(),
    getGlobalAppConfigurations(),
  ]);
};
