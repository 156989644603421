import { memo, useEffect, useMemo, useRef, useState } from 'react';
import classes from './styles.module.scss';
import { EmailIcon, SortDownDisabledIcon, SortUpIcon } from 'assets';
import Select from 'components/Select';
import { EEmailStatus, ESelectTheme, ESortDirection, EStatusCode, EUserColumns } from 'configs/enums';
import useWindowDimensions from 'hooks/useWindowDimensions';
import ModalInviteUser from 'components/ModalInviteUser';
import { GroupBase, OptionProps, ValueContainerProps, components } from 'react-select';
import clsx from 'clsx';
import { Container } from 'react-bootstrap';
import Header from 'components/Header';
import ApiRoutes from 'configs/apiRoutes';
import ApiService from 'services/api_service';
import { useDispatch, useSelector } from 'react-redux';
import { IReducer } from 'redux/reducers';
import ToastService from 'services/toast_service';
import Messages from 'configs/messages';
import { getCollabUserListRequest, setCollabUserListReducer, setSortUserTableReducer } from 'redux/reducers/User/actionTypes';
import { ICollabUser } from 'interfaces/user';
import { Skeleton } from '@mui/material';
import { ISelectOption } from 'interfaces/common';
import { setIsLoadingReducer } from 'redux/reducers/Status/actionTypes';
import { IWorkspace } from 'interfaces/workspace';
import { setAllProjectListReducer } from 'redux/reducers/Workspace/actionTypes';
import { IProject } from 'interfaces/project';
import dayjs from 'dayjs';
import UserService from 'services/user_service';
import xorBy from 'lodash/xorBy'

// const allWorkspacesSample: ISelectOption[] = [
//   { value: 'c1', label: 'Sample C1' },
//   { value: 'c2', label: 'Sample C2' },
//   { value: 'c3', label: 'Sample C3' },
//   { value: 'c4', label: 'Sample C4' },
// ];

const CustomValueContainer = (props: ValueContainerProps<unknown, boolean, GroupBase<unknown>>) => {
  const { children, hasValue, getValue } = props;

  if (!hasValue) {
    return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
  }

  const values = getValue()?.map((item: any) => item?.label);

  return (
    <components.ValueContainer {...props} className={classes.customValueContainer}>
      <p>{`${values?.length === 1 ? values : values?.join(', ')}`}</p>
      {children?.[1]}
    </components.ValueContainer>
  );
};

const CustomOption = (props: OptionProps<unknown, boolean, GroupBase<unknown>>) => {
  const { getStyles, isDisabled, isFocused, isSelected, children, ...rest } = props;

  return (
    <components.Option {...rest} isDisabled={isDisabled} isFocused={isFocused} isSelected={isSelected} getStyles={getStyles}>
      <div className={classes.customOption}>
        <input type="checkbox" checked={isSelected} readOnly />
        <label />
        {children}
      </div>
    </components.Option>
  );
};

interface UsersPageProps {}

const UsersPage: React.FC<UsersPageProps> = memo((props: UsersPageProps) => {
  const user = useMemo(() => UserService.getUser(), []);

  const dispatch = useDispatch();
  const { width } = useWindowDimensions();

  const { collabUserList, sortUserTable } = useSelector((state: IReducer) => state?.user);
  const { allProjectList } = useSelector((state: IReducer) => state?.workspace);
  const { workspaceList } = useSelector((state: IReducer) => state?.information);

  const firstHeadColumnRef = useRef<HTMLDivElement>();

  const [allProjectOptions, setAllProjectOptions] = useState<ISelectOption[]>([]);
  const [maxBodyColumnWidth, setMaxBodyColumnWidth] = useState<number>(null);
  const [isOpenModalInviteUser, setIsOpenModalInviteUser] = useState<boolean>(false);

  useEffect(() => {
    if (width) {
      setMaxBodyColumnWidth(firstHeadColumnRef?.current?.offsetWidth);
    }
  }, [width]);

  useEffect(() => {
    if (allProjectList?.length) {
      setAllProjectOptions(
        allProjectList?.map((project: IProject) => {
          return { value: project?._id, label: project?.name };
        })
      );
    }
  }, [allProjectList]);

  useEffect(() => {
    if (workspaceList?.length && !allProjectList?.length && user?.project_ids?.length) {
      dispatch(setIsLoadingReducer(true));
      ApiService.GET(ApiRoutes.project.default, {
        filter_workspace_id: workspaceList?.map((workspace: IWorkspace) => workspace?._id)?.join(','),
      })
        .then((response) => {
          dispatch(
            setAllProjectListReducer(
              response?.entities?.sort((a: IProject, b: IProject) => {
                const dateA = dayjs(a?.updatedAt);
                const dateB = dayjs(b?.updatedAt);
                return dateB?.diff(dateA);
              })
            )
          );
        })
        .catch((error) => {
          if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
            ToastService.error(Messages.error.accessDenied);
            return;
          }
          console.log(error);
          ToastService.error(Messages.error.default);
        })
        .finally(() => dispatch(setIsLoadingReducer(false)));
    }
  }, [workspaceList, allProjectList]);

  const onCloseModals = () => {
    setIsOpenModalInviteUser(false);
  };

  const onInviteUsers = (data: string[]) => {
    onCloseModals();
  };

  const onChangeProjects = (user: ICollabUser, data: any) => {

  const oldData = allProjectOptions?.filter((item: ISelectOption) => user?.project_ids?.includes(item?.value as string))
  const result: any = xorBy(data, oldData, 'value');

  let method = "PUT";
  if(data?.length < oldData?.length){
    method = "DELETE"
  }
  ApiService[method](ApiRoutes.user.remove.replace(':userId', user?._id).replace(':projectId', result?.[0].value))
    .then(() => {
      dispatch(
        setCollabUserListReducer(
          collabUserList?.map((item: ICollabUser) => {
            if (item?.email === user?.email) {
              return {
                ...item,
                project_ids: data?.map((option: any) => option?.value),
              };
            }
            return item;
          })
        )
      );
    })
    .catch((error) => {
      if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
        ToastService.error(Messages.error.accessDenied);
        return;
      }
      console.log(error);
      ToastService.error(Messages.error.default);
    })
    .finally(() => dispatch(setIsLoadingReducer(false)));
  };

  return (
    <Container fluid style={{ overflowX: 'auto' }}>
      <Header />
      <div className={classes.container}>
        {/* <div className={classes.toolbar}>
          <div className={classes.toolbarLeft}>
            <input className={classes.searchInput} type="text" placeholder="Search" />
            <Select customTheme={ESelectTheme.Filter} placeholder="Priority" />
          </div>

          <div className={classes.toolbarRight}>
            <PrimaryButton className={classes.addUserButton} onClick={() => setIsOpenModalInviteUser(true)}>
              <p>Add User</p>
              <PlusWhiteIcon />
            </PrimaryButton>
          </div>
        </div> */}

        <div id="table" className={classes.table}>
          <div className={classes.head}>
            <div
              ref={firstHeadColumnRef}
              className={clsx(classes.column, classes.sort)}
              onClick={() => {
                const column = EUserColumns.FirstName;
                const direction =
                  sortUserTable?.column === EUserColumns.FirstName
                    ? sortUserTable?.direction === ESortDirection.Ascending
                      ? ESortDirection.Descending
                      : ESortDirection.Ascending
                    : ESortDirection.Ascending;
                dispatch(
                  setSortUserTableReducer({
                    column,
                    direction,
                  })
                );
                dispatch(getCollabUserListRequest(user?.project_ids?.join(','), column, direction));
              }}
            >
              <div>
                <p>First Name</p>
                {sortUserTable?.column === EUserColumns.FirstName ? (
                  <SortUpIcon className={clsx({ [classes.revert]: sortUserTable?.direction === ESortDirection.Ascending })} />
                ) : (
                  <SortDownDisabledIcon />
                )}
              </div>
            </div>
            <div className={classes.column}>Last Name</div>
            <div className={classes.column}>Email</div>
            {/* <div className={classes.column}>Workspaces</div> */}
            <div className={classes.column}>Projects</div>
            {/* <div className={classes.column}>Skills</div> */}
          </div>

          {collabUserList ? (
            <div className={classes.bodyContainer}>
              <div className={clsx(classes.body, { [classes.empty]: !collabUserList?.length })}>
                {collabUserList?.length ? (
                  collabUserList?.map((user: ICollabUser, userIndex: number) => {
                    return (
                      <div className={classes.row} key={`user-${userIndex}`}>
                        <div className={classes.column}>
                          <p>{user?.first_name ?? 'N/A'}</p>
                        </div>

                        <div className={classes.column} style={{ maxWidth: maxBodyColumnWidth }}>
                          {user?.last_name ?? 'N/A'}
                        </div>

                        <div className={clsx(classes.column, classes.email)} style={{ maxWidth: maxBodyColumnWidth }}>
                          <p>{user?.email ?? 'N/A'}</p>
                          {user?.invite_status === EEmailStatus.Pending || user?.invite_status === EEmailStatus.Expired ? <EmailIcon /> : null}
                        </div>

                        {/* <div className={classes.column} style={{ maxWidth: maxBodyColumnWidth }}>
                          <Select
                            isDisabled={true} // disable select
                            menuPortalTarget={document.querySelector('#table')}
                            menuPlacement="auto"
                            customTheme={ESelectTheme.Transparent}
                            isMulti={true}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            value={allWorkspacesSample?.filter((item) => row?.workspaces?.includes(item?.value))}
                            options={allWorkspacesSample}
                            onChange={(selectedOptions: any) => {
                              setSampleData(
                                sampleData?.map((item: { [key: string]: any }) => {
                                  if (item?.id === row?.id) {
                                    return {
                                      ...item,
                                      workspaces: selectedOptions?.map((option: any) => option?.value),
                                    };
                                  }
                                  return item;
                                })
                              );
                            }}
                            components={{
                              IndicatorsContainer: () => null,
                              DropdownIndicator: () => null,
                              ValueContainer: CustomValueContainer,
                              Option: CustomOption,
                            }}
                          />
                        </div> */}

                        <div className={classes.column} style={{ maxWidth: maxBodyColumnWidth }}>
                          <Select
                            // isDisabled={true} // disable select
                            menuPortalTarget={document.querySelector('#table')}
                            menuPlacement="auto"
                            customTheme={ESelectTheme.Transparent}
                            isMulti={true}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            // placeholder="N/A"
                            value={allProjectOptions?.filter((item: ISelectOption) => user?.project_ids?.includes(item?.value as string))}
                            options={allProjectOptions}
                            onChange={(selectedOptions: any) => onChangeProjects(user, selectedOptions)}
                            components={{
                              IndicatorsContainer: () => null,
                              DropdownIndicator: () => null,
                              ValueContainer: CustomValueContainer,
                              Option: CustomOption,
                            }}
                          />
                        </div>

                        {/* <div className={classes.column} style={{ maxWidth: maxBodyColumnWidth }}>
                          <input className={classes.skillInput} type="text" defaultValue={user?.skills?.join(', ') ?? 'N/A'} />
                        </div> */}
                      </div>
                    );
                  })
                ) : (
                  <p>No users found.</p>
                )}
              </div>
            </div>
          ) : (
            <Skeleton
              variant="rectangular"
              width="100%"
              // height="calc(100vh - 24px - 40px - 24px - 38px - 24px - 32px - 40px - 10px)"
              height="calc(100vh - 24px - 40px - 24px - 32px - 40px - 10px)"
              sx={{ minHeight: '500px', borderRadius: '8px', background: 'var(--backgroundLight)' }}
            />
          )}
        </div>

        <ModalInviteUser hasWorkspace={true} isOpen={isOpenModalInviteUser} onClose={onCloseModals} onSubmit={onInviteUsers} />
      </div>
    </Container>
  );
});

export default UsersPage;
