const getState = () => {
  return localStorage.getItem('linkedin_state');
};

const setState = (code: string) => {
  localStorage.setItem('linkedin_state', code);
};

const removeState = () => {
  localStorage.removeItem('linkedin_state');
};

const LinkedinService = {
  getState,
  setState,
  removeState,
};

export default LinkedinService;
