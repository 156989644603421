import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import classes from './styles.module.scss';
import { memo } from 'react';
import { FireDefaultIcon, FireGreenIcon, FireRedIcon, FireYellowIcon } from 'assets';
import { EPriority } from 'configs/enums';

interface PriorityPopoverProps {
  anchorElement: HTMLElement;
  onClose: () => void;
  onSelect: (priority: number) => void;
}

const PriorityPopover = memo((props: PriorityPopoverProps) => {
  const { anchorElement, onClose, onSelect } = props;

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorElement?.contains(event?.target as HTMLElement)) {
      return;
    }
    onClose();
  };

  return (
    <Popper
      open={!!anchorElement}
      anchorEl={anchorElement}
      className={classes.popover}
      role={undefined}
      transition
      placement="bottom"
      disablePortal
      modifiers={[
        {
          name: 'preventOverflow',
          enabled: true,
          options: {
            altAxis: true,
            altBoundary: true,
            tether: true,
            rootBoundary: 'html',
          },
        },
      ]}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper className={classes.paper}>
            <ClickAwayListener onClickAway={handleClose}>
              <div className={classes.menu}>
                <div
                  onClick={() => {
                    onSelect(EPriority.Highest);
                    onClose();
                  }}
                >
                  <FireRedIcon />
                  <p>Highest</p>
                </div>
                <div
                  onClick={() => {
                    onSelect(EPriority.High);
                    onClose();
                  }}
                >
                  <FireYellowIcon />
                  <p>High</p>
                </div>
                <div
                  onClick={() => {
                    onSelect(EPriority.Medium);
                    onClose();
                  }}
                >
                  <FireGreenIcon />
                  <p>Medium</p>
                </div>
                <div
                  onClick={() => {
                    onSelect(EPriority.Low);
                    onClose();
                  }}
                >
                  <FireDefaultIcon />
                  <p>Low</p>
                </div>
              </div>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
});

export default PriorityPopover;
