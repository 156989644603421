import { SnapGrid, Transform, XYPosition, useStoreApi } from 'reactflow';

const snapPosition = (position: XYPosition, snapGrid: SnapGrid = [1, 1]): XYPosition => {
  return {
    x: snapGrid[0] * Math.round(position.x / snapGrid[0]),
    y: snapGrid[1] * Math.round(position.y / snapGrid[1]),
  };
};

const pointToRendererPoint = ({ x, y }: XYPosition, [tx, ty, tScale]: Transform, snapToGrid = false, snapGrid: SnapGrid = [1, 1]): XYPosition => {
  const position: XYPosition = {
    x: (x - tx) / tScale,
    y: (y - ty) / tScale,
  };

  return snapToGrid ? snapPosition(position, snapGrid) : position;
};

const useCustomScreenToFlowPosition = () => {
  const store = useStoreApi();

  return (clientPosition: XYPosition, options: { snapToGrid: boolean } = { snapToGrid: true }) => {
    const { transform, snapGrid, domNode } = store.getState();

    if (!domNode) {
      return clientPosition;
    }

    const { x: domX, y: domY } = domNode.getBoundingClientRect();

    const correctedPosition = {
      x: clientPosition.x - domX,
      y: clientPosition.y - domY,
    };

    return pointToRendererPoint(correctedPosition, transform, options.snapToGrid, snapGrid);
  };
};

export default useCustomScreenToFlowPosition;
