import { Fragment, memo, useMemo } from 'react';
import classes from './styles.module.scss';
import { EmailIcon, GoogleIcon, LinkedInIcon } from 'assets';
import { TokenResponse, useGoogleLogin } from '@react-oauth/google';
import { useDispatch } from 'react-redux';
import { setIsLoadingReducer } from 'redux/reducers/Status/actionTypes';
import ApiRoutes from 'configs/apiRoutes';
import ApiService from 'services/api_service';
import AuthService from 'services/auth_service';
import ToastService from 'services/toast_service';
import Messages from 'configs/messages';
import { push } from 'connected-react-router';
import { routes } from 'routers/routes';
import LinkedinService from 'services/linkedin_service';
import { EStatusCode } from 'configs/enums';
// import FacebookLogin, { RenderProps } from 'react-facebook-login/dist/facebook-login-render-props';
// import { ReactFacebookFailureResponse, ReactFacebookLoginInfo } from 'react-facebook-login';

interface GetStartedPageProps {}

const GetStartedPage: React.FC<GetStartedPageProps> = memo((props: GetStartedPageProps) => {
  const linkedinScopeList = useMemo(() => ['email', 'profile', 'openid'], []);

  const dispatch = useDispatch();

  const generateLinkedinState = () => {
    const array = new Uint32Array(1);
    window.crypto.getRandomValues(array);
    const state = array?.[0]?.toString(16);
    LinkedinService.setState(state);
    return state;
  };

  const loginByLinkedin = () => {
    window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${
      process.env.REACT_APP_LINKEDIN_CLIENT_ID
    }&redirect_uri=${window.location.origin + routes.public.callback.linkedinSso}&scope=${encodeURIComponent(
      linkedinScopeList?.join(' ')
    )}&state=${generateLinkedinState()}`;
  };

  const loginByGoogle = useGoogleLogin({
    onSuccess: (token: Omit<TokenResponse, 'error' | 'error_description' | 'error_uri'>) => {
      dispatch(setIsLoadingReducer(true));
      ApiService.POST(ApiRoutes.auth.sso.googleLogin, token)
        .then((response) => {
          AuthService.login(response);
          dispatch(setIsLoadingReducer(false));
          dispatch(push(routes.private.home));
        })
        .catch((error) => {
          console.log(error);
          dispatch(setIsLoadingReducer(false));
          if (error?.response?.status === EStatusCode.BadGateway) {
            ToastService.error(Messages.error.default);
          } else {
            ToastService.error(Messages.error.loginByEmail);
          }
        });
      // please don't set loading reducer in .finally() here, it can cause asynchronous loading
    },
    onError: (error: Pick<TokenResponse, 'error' | 'error_description' | 'error_uri'>) => {
      console.log(error);
      ToastService.error(Messages.error.default);
    },
  });

  return (
    <Fragment>
      <p className={classes.title}>Get Started</p>

      <div className={classes.container}>
        <div onClick={() => loginByGoogle()}>
          <GoogleIcon />
          <p>Continue with Google</p>
        </div>

        <div onClick={() => loginByLinkedin()}>
          <LinkedInIcon />
          <p>Continue with LinkedIn</p>
        </div>

        {/* <FacebookLogin
          appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID}
          fields="name,email,picture"
          callback={(response: ReactFacebookLoginInfo) => console.log(response)}
          onFailure={(error: ReactFacebookFailureResponse) => console.log(error)}
          render={(renderProps: RenderProps) => (
            <div onClick={renderProps?.onClick}>
              <FacebookIcon />
              <p>Continue with Facebook</p>
            </div>
          )}
        /> */}

        <div onClick={() => dispatch(push(routes.public.login))}>
          <EmailIcon />
          <p>Continue with Email</p>
        </div>
      </div>
    </Fragment>
  );
});

export default GetStartedPage;
