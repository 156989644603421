import { Fragment, memo, useMemo } from 'react';
import classes from './styles.module.scss';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Regexes from 'configs/regexes';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import AuthService from 'services/auth_service';
import Input from 'components/Input';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import ToastService from 'services/toast_service';
import ApiService from 'services/api_service';
import Messages from 'configs/messages';
import { setIsLoadingReducer } from 'redux/reducers/Status/actionTypes';
import ApiRoutes from 'configs/apiRoutes';
import CommonService from 'services/common_service';
import { routes } from 'routers/routes';
import SecondaryButton from 'components/Buttons/SecondaryButton';

interface ILoginFormData {
  email: string;
  password: string;
}

interface LoginPageProps {}

const LoginPage: React.FC<LoginPageProps> = memo((props: LoginPageProps) => {
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string().required('Please enter your email.').matches(Regexes.email, 'Please enter a valid email.'),
        password: Yup.string().required('Please enter your password.'),
      }),
    []
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ILoginFormData>({
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
  });

  const onSubmit = async (data: ILoginFormData) => {
    if (!executeRecaptcha) {
      ToastService.error(Messages.error.recaptchaNotReady);
      return;
    }
    dispatch(setIsLoadingReducer(true));
    await executeRecaptcha(ApiRoutes.auth.login)
      .then(async (token: string) => {
        await ApiService.POST(ApiRoutes.auth.reCaptcha, { response_token: token })
          .then(async () => {
            await CommonService.sha256(data?.password)
              .then(async (hashedPassword) => {
                await ApiService.POST(ApiRoutes.auth.login, {
                  email: data?.email,
                  password: hashedPassword,
                })
                  .then((response) => {
                    dispatch(setIsLoadingReducer(false));
                    AuthService.login(response);
                    dispatch(push(routes.private.home));
                  })
                  .catch(() => {
                    ToastService.error(Messages.error.wrongAccount);
                    dispatch(setIsLoadingReducer(false));
                  });
              })
              .catch((error) => {
                console.log(error);
                ToastService.error(Messages.error.default);
                dispatch(setIsLoadingReducer(false));
              });
          })
          .catch(() => {
            ToastService.error(Messages.error.recaptchaFailed);
            dispatch(setIsLoadingReducer(false));
          });
      })
      .catch((error) => {
        console.log(error);
        ToastService.error(Messages.error.default);
        dispatch(setIsLoadingReducer(false));
      });
  };

  return (
    <Fragment>
      <p className={classes.title}>Login</p>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          label="Email"
          inputRef={register('email')}
          type="text"
          placeholder="Your email"
          autoComplete="email"
          errorMessage={errors?.email?.message}
        />

        <Input
          label="Password"
          inputRef={register('password')}
          type="password"
          placeholder="Your password"
          autoComplete="password"
          errorMessage={errors?.password?.message}
        />

        <Link className={classes.forgotPassword} to={routes.public.forgotPassword}>
          Forgot your password?
        </Link>

        <PrimaryButton className="mb-4" type="submit" fullWidth>
          Login
        </PrimaryButton>

        <div className={classes.registerContainer}>
          <p>Don't have an account?</p>
          <Link to={routes.public.register}>Create Account</Link>
        </div>

        <SecondaryButton className={classes.backButton} type="button" onClick={() => dispatch(push(routes.public.getStarted))}>
          Back
        </SecondaryButton>
      </form>
    </Fragment>
  );
});

export default LoginPage;
