import { memo } from 'react';
import { Route, Redirect } from 'react-router-dom';
import AuthService from 'services/auth_service';
import TokenService from 'services/token_service';
import { routes } from './routes';

interface MiddlewareProps {
  component: React.ElementType;
  layout: React.ElementType;
  isAuthProtected: boolean;
  [key: string]: any;
}

const Middleware: React.FC<MiddlewareProps> = memo((props: MiddlewareProps) => {
  const { component: Component, layout: Layout, isAuthProtected, ...rest } = props;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthProtected && !TokenService.getToken()) {
          AuthService.logout();
          return <Redirect to={{ pathname: routes.public.getStarted, state: { from: props.location } }} />;
        } else {
          return (
            <Layout>
              <Component {...props} />
            </Layout>
          );
        }
      }}
    />
  );
});

export default Middleware;
