import ApiRoutes from 'configs/apiRoutes';
import { EStatusCode } from 'configs/enums';
import Messages from 'configs/messages';
import { put, takeLatest, call } from 'redux-saga/effects';
import { GET_PIPELINE_TEMPLATES_REQUEST, setPipelineTemplatesReducer } from 'redux/reducers/Information/actionTypes';
import { setIsLoadingReducer } from 'redux/reducers/Status/actionTypes';
import {} from 'redux/reducers/Workspace/actionTypes';
import ApiService from 'services/api_service';
import ToastService from 'services/toast_service';

function* requestGetPipelineTemplates() {
  try {
    yield put(setIsLoadingReducer(true));
    const response = yield call(ApiService.GET, ApiRoutes.pipeline.templates);
    yield put(setPipelineTemplatesReducer(response?.entities));
  } catch (error: any) {
    if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
      yield ToastService.error(Messages.error.accessDenied);
    } else {
      yield console.log(error);
      yield ToastService.error(Messages.error.default);
    }
  } finally {
    yield put(setIsLoadingReducer(false));
  }
}

function* getPipelineTemplates() {
  yield takeLatest(GET_PIPELINE_TEMPLATES_REQUEST, requestGetPipelineTemplates);
}

export default getPipelineTemplates;
