import ReactDOM from 'react-dom';
import App from './App';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles/index.scss';
import { history, createConfigureStore } from 'redux/configureStore';
import { Provider } from 'react-redux';
import reportWebVitals from 'reportWebVitals';
import { registerLicense } from '@syncfusion/ej2-base';

registerLicense('ORg4AjUWIQA/Gnt2U1hhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5Vd0xhW3tXcnxTQWlY'); //trial key

const { store } = createConfigureStore();

ReactDOM.render(
  <Provider store={store}>
    <App history={history} dispatch={store.dispatch} />
    <ToastContainer />
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
