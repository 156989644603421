import { memo } from 'react';
import classes from './styles.module.scss';
import Popper from '@mui/material/Popper';
import { Box, Grow, Paper, MenuList, ClickAwayListener, MenuItem, Stack } from '@mui/material';
import { UserPlaceholderImage } from 'assets';
import { ISearchUserResult } from 'interfaces/user';
import CommonService from 'services/common_service';

interface MenuSelectUserProps {
  anchorElement: HTMLElement;
  onClose: (event: Event | React.SyntheticEvent) => void;
  userList: ISearchUserResult[];
  onSelectName: (email: string) => void;
}

const MenuSelectUser = memo((props: MenuSelectUserProps) => {
  const { userList, anchorElement, onClose, onSelectName } = props;

  return (
    <Popper
      open={!!anchorElement}
      anchorEl={anchorElement}
      transition
      placement="bottom-start"
      className={classes.customMenuSelectUser}
      sx={{ width: anchorElement ? anchorElement?.clientWidth : null }}
    >
      {({ TransitionProps }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: 'left top',
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={onClose}>
              <MenuList id="composition-menu" aria-labelledby="composition-button">
                {userList?.map((user: ISearchUserResult, userIndex: number) => (
                  <MenuItem key={`user-${userIndex}`} onClick={() => onSelectName(user?.email)}>
                    <Box display="flex" alignItems={'center'}>
                      <Stack direction={'row'} spacing={2} alignItems={'center'}>
                        <img className={classes.avatar} src={UserPlaceholderImage} alt="Avatar" />
                        <Stack direction={'column'}>
                          <p>{CommonService.getFullName(user)}</p>
                          <p>{user?.email ?? 'N/A'}</p>
                        </Stack>
                      </Stack>
                    </Box>
                  </MenuItem>
                ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
});

export default MenuSelectUser;
