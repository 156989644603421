import { memo, useRef } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import dayjs from 'dayjs';
import { ChangeEventArgs, DateRangePickerComponent, PresetDirective, PresetsDirective } from '@syncfusion/ej2-react-calendars';
import classes from './styles.module.scss';
import clsx from 'clsx';

const dateRangePickerPreset: { label: string; startDate: Date; endDate: Date }[] = [
  {
    label: 'Last week',
    startDate: dayjs().subtract(7, 'day').startOf('week').toDate(),
    endDate: dayjs().subtract(7, 'day').endOf('week').toDate(),
  },
  {
    label: 'Last 7 days',
    startDate: dayjs().subtract(7, 'day').toDate(),
    endDate: dayjs().toDate(),
  },
  {
    label: 'This week',
    startDate: dayjs().startOf('week').toDate(),
    endDate: dayjs().endOf('week').toDate(),
  },
  {
    label: 'This month',
    startDate: dayjs().startOf('month').toDate(),
    endDate: dayjs().endOf('month').toDate(),
  },
  {
    label: 'Next month',
    startDate: dayjs().endOf('month').add(1, 'day').toDate(),
    endDate: dayjs().endOf('month').add(1, 'day').endOf('month').toDate(),
  },
];

interface DateRangePickerProps {
  className?: string;
  onChange: (args: ChangeEventArgs) => void;
  startDate?: any;
  endDate?: any;
  disablePresets?: boolean;
  placeholder?: string;
}

export const DateRangePicker: React.FC<DateRangePickerProps> = memo((props: DateRangePickerProps) => {
  const { className, onChange, startDate, endDate, disablePresets, placeholder } = props;

  const dateRangePickerRef = useRef<DateRangePickerComponent>();

  const onOpenPopup = () => {
    dateRangePickerRef?.current?.show();
  };

  return (
    <div className={clsx(classes.container, className)} onClick={onOpenPopup}>
      <DateRangePickerComponent
        ref={dateRangePickerRef}
        placeholder={placeholder || "Due date"}
        allowEdit={false}
        start={'Month'}
        change={onChange}
        startDate={startDate}
        endDate={endDate}
      >
        {!disablePresets ? (
          <PresetsDirective>
          {dateRangePickerPreset?.map((preset, presetIndex) => (
            <PresetDirective key={presetIndex} label={preset?.label} start={preset?.startDate} end={preset?.endDate}></PresetDirective>
          ))}
          </PresetsDirective>
        ) : null}
       
      </DateRangePickerComponent>
    </div>
  );
});

export default DateRangePicker;
