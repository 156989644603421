import Loading from 'components/Loading';
import ApiRoutes from 'configs/apiRoutes';
import { NULL_VALUE } from 'configs/constant';
import { EStatusCode } from 'configs/enums';
import Messages from 'configs/messages';
import { push } from 'connected-react-router';
import { IAcceptInvitationUrlParams } from 'interfaces/common';
import { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { IReducer } from 'redux/reducers';
import { routes } from 'routers/routes';
import ApiService from 'services/api_service';
import ToastService from 'services/toast_service';

interface AcceptInvitationPageProps {}

const AcceptInvitationPage: React.FC<AcceptInvitationPageProps> = memo((props: AcceptInvitationPageProps) => {
  const dispatch = useDispatch();
  const { token } = useParams<IAcceptInvitationUrlParams>();

  const { workspace } = useSelector((state: IReducer) => state?.workspace);

  useEffect(() => {
    if (token) {
      ApiService.GET(ApiRoutes.invite.acceptInvitation.replace(':token', token))
        .catch((error) => {
          if (error?.response?.status === EStatusCode.MovedPermanently) {
            // success or already accepted
            ToastService.success(Messages.error.invitationAccepted);
          } else if (error?.response?.status === EStatusCode.InternalServerError) {
            // error or expired
            ToastService.error(Messages.error.invitationExpired);
          }
        })
        .finally(() => dispatch(push({ pathname: routes.private.home, search: `&workspaceId=${workspace?._id ?? NULL_VALUE}` })));
    }
  }, [token, workspace]);

  return <Loading />;
});

export default AcceptInvitationPage;
