import ApiRoutes from 'configs/apiRoutes';
import { EStatusCode } from 'configs/enums';
import Messages from 'configs/messages';
import { put, takeLatest, call } from 'redux-saga/effects';
import { GET_RESOURCE_MEDIA_REQUEST, setResourceMediaReducer } from 'redux/reducers/Information/actionTypes';
import { setIsLoadingReducer } from 'redux/reducers/Status/actionTypes';
import {} from 'redux/reducers/Workspace/actionTypes';
import ApiService from 'services/api_service';
import ToastService from 'services/toast_service';

function* requestGetResourceMedia(data: { type: string; resourceId: string; options?: { noSkeleton?: boolean; loading?: boolean } }) {
  try {
    if (!data?.options?.noSkeleton) {
      yield put(
        setResourceMediaReducer({
          isLoading: true,
          resource_id: null,
          data: null,
        })
      );
    }
    if (data?.options?.loading) {
      yield put(setIsLoadingReducer(true));
    }

    const response = yield call(ApiService.GET, ApiRoutes.resource.media.list.replace(':resourceId', data?.resourceId));

    yield put(
      setResourceMediaReducer({
        isLoading: false,
        resource_id: data?.resourceId,
        data: response?.entities,
      })
    );
  } catch (error: any) {
    yield put(
      setResourceMediaReducer({
        isLoading: false,
        resource_id: null,
        data: null,
      })
    );
    if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
      yield ToastService.error(Messages.error.accessDenied);
    } else {
      yield console.log(error);
    }
  } finally {
    if (data?.options?.loading) {
      yield put(setIsLoadingReducer(false));
    }
  }
}

function* getResourceMedia() {
  yield takeLatest(GET_RESOURCE_MEDIA_REQUEST, requestGetResourceMedia);
}

export default getResourceMedia;
